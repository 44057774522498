import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '../core/services/account.service';
import { UsuarioService } from '../core/services/usuario.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from './dialog/dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JuzgadoService } from '../core/services/juzgado.service';
import { AccionesService } from '../core/services/acciones.service';
import { Router } from '@angular/router';
import { UsuarioJuzgado } from '../core/model/usuario-juzgado.mode';
import { InfoPerfilUsuario } from '../core/model/solicitud-perfil.model';
import { Acciones } from '../core/model/cuenta.model';
import { PerfilesComponent } from '../juzgado/perfiles/perfiles.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export interface UsuariosRoot {
  idUsuario: number;
  nombre: string;
  apellido: string;
  dni: string;
  perfil: string;
  circunscripciones: string;
  estado: string;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private juzgadoService: JuzgadoService,
    private matSnackBar: MatSnackBar,
    private accionService: AccionesService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  registros: UsuarioJuzgado[];
  dataSource = new MatTableDataSource(this.registros);
  displayedColumns: string[] = [
    'dni',
    'nombre',
    'email',
    'rol',
    'estado',
    'actions',
  ];

  ngOnInit(): void {
    this.buscarUsuariosRoot();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  buscarUsuariosRoot() {
    this.juzgadoService
      .ListarUsuarioAdmin()
      .subscribe((data: UsuarioJuzgado[]) => {
        this.registros = data;
        this.dataSource = new MatTableDataSource(this.registros);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  ejecutar(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      if (accion.url === 'juzgado/perfiles') {
        const model = JSON.parse(accion.body) as InfoPerfilUsuario;
        const dialogRef = this.dialog
          .open(PerfilesComponent, {
            width: '450px',
            data: model,
          })
          .afterClosed()
          .subscribe((data) => {
            this.buscarUsuariosRoot();
          });
      } else {
        this.router.navigate([accion.url]);
      }
    } else {
      this.accionService.customAccion(accion);
    }
  }
}
