<div class="row">
  <div class="col-lg-10">
    <form [formGroup]="form" (ngSubmit)="saveRegister($event)">
      <mat-card>
        <mat-card-header>
          <mat-card-title> Editar Organismo </mat-card-title>
          <br />
          <mat-error *ngIf="this.registroError != ''">
            {{ this.registroError }}
          </mat-error>
        </mat-card-header>
        <mat-card-content style="text-align: center">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Denominacion</mat-label>
            <input matInput type="text" formControlName="organismo" />
          </mat-form-field>
          <br />
          <mat-divider></mat-divider>
          <br />
          <div class="row">
            <div class="col-6">
              <div>
                <mat-form-field>
                  <mat-label>Filtrar</mat-label>
                  <input
                    matInput
                    class="input-width"
                    (keyup)="applyFilter($event)"
                    placeholder="Buscar"
                  />
                </mat-form-field>
              </div>
              <table
                mat-table
                [dataSource]="datasource"
                class="full-width-table"
                aria-label="Elements"
              >
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>Seleccion</th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      (change)="chechedJuz($event, element)"
                      [checked]="this.selection.isSelected(element)"
                      [aria-label]="checkboxLabel(element)"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>
                <!-- juzgado Column -->
                <ng-container matColumnDef="juzgado">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    style="text-align: center"
                  >
                    Juzgado
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    style="text-align: left"
                  >
                    <b>{{ element.id }}</b> {{ " - " + element.descripcion }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let element; columns: displayedColumns"
                ></tr>
              </table>
              <mat-paginator
                [length]="datasource.data.length"
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]"
              ></mat-paginator>
            </div>
            <div class="col-6 items-asignados">
              <h3>Juzgado Asignados</h3>
              <mat-chip-list #chipList>
                <mat-chip
                  *ngFor="let item of juzgados"
                  [selectable]="selectable"
                  color="primary"
                  [removable]="removable"
                  (removed)="remove(item)"
                >
                  {{ item.descripcion }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions style="text-align: right">
          <button
            mat-stroked-button
            color="warn"
            type="button"
            [routerLink]="['/organismo/gestion']"
          >
            Cancelar
          </button>
          <button
            [disabled]="form.invalid"
            mat-raised-button
            color="primary"
            type="submit"
          >
            Enviar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</div>
