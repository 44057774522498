<div id="sidebar">
  <br />
  <div class="user-panel">
    <div>
      <p>
        {{ this.userInfo.nombre }} <strong>{{ this.userInfo.apellido }}</strong>
      </p>
      <p></p>
      <div *ngIf="this.userInfo.roles.length === 1">
        Perfil:
        <strong *ngFor="let rol of this.userInfo.roles" class="titulo">{{
          rol.descripcion
        }}</strong>
      </div>
      <div *ngIf="this.userInfo.roles.length > 1">
        Perfil/es:
        <strong *ngFor="let rol of this.userInfo.roles" class="titulo"
          >{{ rol.descripcion }} /</strong
        >
      </div>
      <div *ngIf="this.userInfo.organismo != null">
        Organismo:
        <strong class="titulo">{{
          this.userInfo.organismo.descripcion
        }}</strong>
      </div>
      <div *ngIf="this.userInfo.circunscripcion != null">
        Circunscripcion:
        <strong class="titulo">{{
          this.userInfo.circunscripcion.descripcion
        }}</strong>
      </div>
      Ultimo Ingreso: <strong>{{ this.userInfo.ultimoAcceso }}</strong>
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-accordion color="primary" *ngFor="let menu of menuUser">
    <mat-expansion-panel [expanded]="evaluarUri(menu)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon class="sidenav-icon" color="primary">{{
            menu.icono
          }}</mat-icon>
          <span class="menu-item">{{ menu.descMenu }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <span mat-list-item>
          <div *ngFor="let item of menu.submenu">
            <div *ngIf="item.badge > 0">
              <a
                mat-button
                color="primary"
                class="menu-Subitem"
                [matBadge]="item.badge"
                matBadgeColor="warn"
                routerLinkActive="active"
                [routerLink]="[item.url]"
              >
                {{ item.descSubmenu }}
              </a>
            </div>
            <div *ngIf="!item.badge">
              <a
                mat-button
                color="primary"
                class="menu-Subitem"
                routerLinkActive="active"
                [routerLink]="[item.url]"
              >
                <div *ngIf="item.idSubmenu === 18">
                  <div *ngIf="this.userInfo.organismo != null">
                    {{ item.descSubmenu }} (Colegio)
                  </div>
                  <div *ngIf="this.userInfo.circunscripcion != null">
                    {{ item.descSubmenu }} (TSJ)
                  </div>
                </div>
                <div *ngIf="item.idSubmenu !== 18">
                  {{ item.descSubmenu }}
                </div>
              </a>
            </div>
          </div>
        </span>
      </mat-nav-list>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<!-- <a mat-list-item *ngFor="let menu of menuUser" [routerLinkActive]="'active'" [routerLink]="['/dashboard']">
    <mat-icon class="sidenav-icon">{{ menu.icono }}</mat-icon> {{ menu.menu }}
</a> -->
