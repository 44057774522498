import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { GetionOpj, EstadoResp } from 'src/app/core/model/gestion-opj.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { TokenModel } from 'src/app/core/model/token.model';
import { OpjService } from 'src/app/core/services/opj.service';
import { AccionesService } from 'src/app/core/services/acciones.service';
import { ReportesService } from 'src/app/core/services/reportes.service';
import { TokenService } from 'src/app/core/services/token.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  merge,
  of as observableOf,
  Observable,
  timer,
  Subscription,
} from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import {
  Coordenadas,
  estadoOpj,
  RespOpjs,
  ModelOpjs,
} from 'src/app/core/model/opjs.model';
import { MatTooltip } from '@angular/material/tooltip';
import {
  Acciones,
  FilterCuentas,
  ModelCuentasJudicial,
} from 'src/app/core/model/cuenta.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-autorizar-opj',
  templateUrl: './autorizar-opj.component.html',
  styleUrls: ['./autorizar-opj.component.css'],
})
export class AutorizarOpjComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('inputB') coordB: ElementRef;
  // @ViewChild('tooltip') tooltip: MatTooltip;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<ModelOpjs>;

  resultsLength = 0;
  autorizarOpj = false;
  coordenadas: Coordenadas = new Coordenadas();
  formAutorizar: FormGroup;
  formRechazar: FormGroup;
  registroError = '';
  isLoadingResults = true;
  isRateLimitReached = false;
  isInvalidCoordenada: string;
  isAutorizando = false;
  isPanelActivo = false;
  isBtnVisible = true;
  maxOpj: number;
  isItemsVisible = true;
  isRechazarOpj = false;
  selection = new SelectionModel<GetionOpj>(true, []);
  timerRefreshGrid: Observable<number> = timer(0, 80000);
  subscription: Subscription;

  registros: GetionOpj;
  userInfo: TokenModel;
  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'select',
    'idOpj',
    'caratula',
    'expediente',
    'cuitBeneficiario',
    'cbuDestino',
    'operador',
    'montoOPJStr',
    'tipo',
    'fechaAlta',
    'actions',
  ];
  juzActual: string;
  diccionario = {
    caratula: 'Caratula',
    expediente: 'Expdte',
    cuitBeneficiario: 'NroDoc',
    cbuDestino: 'CbuDestino',
    operador: 'Operador',
    montoOPJStr: 'montoOPJ',
    estado: 'Estado',
  };
  mostrarComprobante = false;
  formFiltro: FormGroup;

  constructor(
    private opjService: OpjService,
    private formBuilder: FormBuilder,
    private accionService: AccionesService,
    private reporteSvr: ReportesService,
    private tokenService: TokenService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.buildForm();
  }

  ngAfterViewInit(): void {
    if (!this.sort) {
      return;
    }

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    if (this.subscription) {
      this.subscription.unsubscribe();
      this.selection.clear();
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          let valueSort = this.diccionario[this.sort.active];
          if (valueSort === undefined) {
            valueSort = 'IdOpj';
            this.sort.direction = 'asc';
          }
          return this.opjService.getOpjsAutorizar(
            this.juzgadoDefault,
            this.paginator.pageIndex,
            valueSort,
            this.sort.direction
          );
        }),
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.maxOpj = data.maxAutorizarPermitido;
          this.resultsLength = data.totalRegistros;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((opjs: ModelOpjs) => {
        this.dataSource = new MatTableDataSource(opjs.opjs);
      });
  }

  ngOnInit(): void {
    this.userInfo = this.tokenService.getToken();
    this.juzActual = this.juzgadoDefault;
    // this.buscarCuentas();
  }

  get juzgadoDefault(): string {
    const juz = localStorage.getItem(`juzgado${this.userInfo.nroDocumento}`);

    if (this.juzActual !== juz) {
      this.juzActual = juz;
      this.ngAfterViewInit();
    }

    if (juz) {
      return juz;
    } else {
      return this.userInfo.juzgadoDefault.id;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  limpiarBusqueda() {
    const filter = {
      tipoAsociacion: this.formFiltro.value.tipoAsociacion,
      cuenta: '',
      subcuenta: '',
      cbu: '',
      opje: '',
      juzgado: '',
      expediente: '',
      caratula: '',
    };
    this.formFiltro.patchValue(filter);
    this.ngAfterViewInit();
  }

  checkboxLabel(row?: GetionOpj): string {
    if (row.process) {
      if (!this.selection.isSelected(row)) {
        row.process.error = null;
        this.isBtnVisible = true;
        row.process.icon = null;
      }
    }

    if (this.selection.selected.length > this.maxOpj) {
      this.matSnackBar.open(
        'No se pueden seleccionar mas de ' + this.maxOpj + ' items',
        'Ok',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );

      this.selection.deselect(row);
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.idOpj + 1
    }`;
  }

  get getImporteTotal(): number {
    return this.selection.selected.reduce(
      (sum: number, b: GetionOpj) => sum + +b.montoOPJ,
      0
    );
  }

  autorizarOpjs() {
    // Validamos si BT esta online

    this.opjService.getBTOnline().subscribe((data) => {
      if (!data) {
        this.autorizarOpj = true;
        this.isPanelActivo = true;
        this.isItemsVisible = false;
        this.opjService.getEstadoCoordenada().subscribe(
          (data) => {
            if (data.idEstado === 1) {
              this.isInvalidCoordenada = null;
              this.opjService.getCoordenada().subscribe((coord) => {
                this.coordenadas = coord;
                //  Limpiamos si ya hay valores
                const nenwValues = {
                  coordA: '',
                  coordB: '',
                };

                this.formAutorizar.patchValue(nenwValues);
              });
            } else {
              this.isInvalidCoordenada = data.descripcionEstado;
            }
          },
          (err) => {
            if (err.status === 404) {
              this.isInvalidCoordenada =
                'No posee tarjeta de coordenadas asociada';
            } else {
              this.isInvalidCoordenada = err;
            }
          }
        );
      } else {
        this.matSnackBar.open(
          'En estos momentos no es posible realizar la acción solicitada. Por favor reintente en unos minutos',
          'Ok',
          {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );
      }
    });
  }

  nextItemCoord(item) {
    if (item.length === 2) {
      this.coordB.nativeElement.focus();
    }
  }

  buildData() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          const filter = new FilterCuentas();

          if (this.formFiltro.value.tipoAsociacion === 'caratula') {
            filter.caratula = {
              juzgado: this.juzgadoDefault,
              carat: this.formFiltro.value.caratula,
            };
          }

          if (this.formFiltro.value.tipoAsociacion === 'opje') {
            filter.OPJE = this.formFiltro.value.opje;
          }

          if (this.formFiltro.value.tipoAsociacion === 'expediente') {
            filter.expediente = {
              juzgado: this.juzgadoDefault,
              exp: this.formFiltro.value.expediente,
            };
          }

          if (this.formFiltro.value.tipoAsociacion === 'cbu') {
            if (!this.formFiltro.value.cbu) {
              this.matSnackBar.open('Ingrese un cbu valido', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              return;
            }
            filter.CBU = this.formFiltro.value.cbu;
          }

          if (this.formFiltro.value.tipoAsociacion === 'cuenta') {
            if (!this.formFiltro.value.cuenta) {
              this.matSnackBar.open('Ingrese una cuenta valida ', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              return;
            }
            filter.juzgado = this.juzgadoDefault;
            filter.subCta = this.formFiltro.value.subcuenta;
            filter.cta = this.formFiltro.value.cuenta;
          }

          return this.opjService.getOpjsAutorizarFilter(
            filter,
            this.paginator.pageIndex
          );
        }),
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.maxOpj = data.maxAutorizarPermitido;
          this.resultsLength = data.totalRegistros;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe(
        (opjs: ModelOpjs) => {
          this.dataSource = new MatTableDataSource(opjs.opjs);
        },
        (errr) => {
          this.matSnackBar.open('No se encontraron registros', 'Ok', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      );
  }

  validarCoordenadas(num) {
    if (num.length === 2) {
      this.coordenadas.valorCoord1 = +this.formAutorizar.value.coordA;
      this.coordenadas.valorCoord2 = +this.formAutorizar.value.coordB;
      this.opjService.isValidCoordenada(this.coordenadas).subscribe(
        (resp) => {
          this.isInvalidCoordenada = null;
        },
        (error) => {
          this.formAutorizar.get('coordA').setErrors({
            incorrect: true,
          });
          this.formAutorizar.get('coordB').setErrors({
            incorrect: true,
          });

          this.isInvalidCoordenada = 'Coordenadas invalidas';
        }
      );
    }
  }

  ejecutar(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      this.router.navigate([accion.url]);
    } else {
      this.accionService.customAccion(accion);
    }
  }

  AutorizarItems(event) {
    // Seteamos el valor de las coordenadas
    this.isBtnVisible = false;
    this.autorizarOpj = false;
    this.isItemsVisible = true;
    this.isAutorizando = true;
    this.coordenadas.valorCoord1 = +this.formAutorizar.value.coordA;
    this.coordenadas.valorCoord2 = +this.formAutorizar.value.coordB;
    const arrray = [];

    this.selection.selected.forEach((item) => {
      arrray.push(item.idOpj);
    });

    const autorizar = {
      opjs: arrray,
      estado: estadoOpj.Autorizar,
      coordenadas: this.coordenadas,
      motivo: '',
    };

    this.opjService.AutorizarOpj(autorizar).subscribe(
      (data: RespOpjs[]) => {
        this.mostrarComprobante = true;
        data.forEach((item) => {
          this.isAutorizando = false;

          if (item.estado.id === 0) {
            this.selection.selected.find(
              (f) => f.idOpj === item.idOpj
            ).process = { icon: 'done', error: null, accion: item.acciones[0] };
          } else {
            this.selection.selected.find(
              (f) => f.idOpj === item.idOpj
            ).process = {
              icon: 'error_outline',
              error: item.detalle,
              accion: item.acciones[0],
            };
          }
        });
      },
      (err) => {
        this.isAutorizando = false;
        this.mostrarComprobante = true;
        this.registroError = err.error.detalle;
        this.autorizarOpj = false;
        this.isBtnVisible = true;
        console.log(err);
      }
    );
  }

  imprimirReport(accion: Acciones) {
    this.accionService.customAccion(accion);
  }

  ocultarPanelItems() {
    this.ngAfterViewInit();
    this.selection.clear();
    this.isPanelActivo = false;
  }

  RechazarItems(event) {
    // Seteamos el valor de las coordenadas
    this.isItemsVisible = true;
    this.isRechazarOpj = false;
    this.isAutorizando = true;
    this.isBtnVisible = false;
    this.isPanelActivo = true;
    const arrray = [];

    this.selection.selected.forEach((item) => {
      arrray.push(item.idOpj);
    });

    const autorizar = {
      opjs: arrray,
      estado: estadoOpj.Rechazar,
      coordenadas: null,
      motivo: this.formRechazar.value.motivo,
    };

    this.opjService.AutorizarOpj(autorizar).subscribe(
      (data: RespOpjs[]) => {
        this.isAutorizando = false;
        this.mostrarComprobante = false;
        data.forEach((item) => {
          if (item.estado.id === 0) {
            this.selection.selected.find(
              (f) => f.idOpj === item.idOpj
            ).process = { icon: 'done', error: null, accion: item.acciones[0] };
          } else {
            this.selection.selected.find(
              (f) => f.idOpj === item.idOpj
            ).process = {
              icon: 'error_outline',
              error: item.detalle,
              accion: null,
            };
          }
        });
      },
      (err) => {
        this.selection.selected[0].process = err;
        this.mostrarComprobante = false;
        this.isAutorizando = false;
        console.log(err);
      }
    );
  }

  private buildForm() {
    this.formAutorizar = this.formBuilder.group({
      coordA: ['', [Validators.required]],
      coordB: ['', [Validators.required]],
    });

    this.formRechazar = this.formBuilder.group({
      motivo: ['', [Validators.required]],
    });

    this.formFiltro = this.formBuilder.group({
      tipoAsociacion: ['expediente', [Validators.required]],
      cuenta: [''],
      subcuenta: [''],
      cbu: [''],
      juzgado: [''],
      opje: [''],
      expediente: [''],
      caratula: [''],
    });
  }
}
