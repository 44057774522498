import { Organismo } from './organismo.model';
import { Novedades } from './menu.model';

export class Role {
  idRol: number;
  descripcion: string;
}

export interface Circunscripcion {
  id: number;
  descripcion: string;
}

export interface JuzgadoDefault {
  id: string;
  descripcion: string;
}

export interface Documento {
  id: number;
  descripcion: string;
}

export interface RolDefault {
  idRol: number;
  descripcion: string;
}

export interface Estado {
  id: number;
  descripcion: string;
}

export interface AccessToken {
  token: string;
  expire_in: number;
}

export interface RefreshToken {
  token: string;
  expire_in: number;
}

export interface Tokens {
  accessToken: AccessToken;
  refreshToken: RefreshToken;
}

export class TokenModel {
  circunscripcion: Circunscripcion;
  juzgadoDefault: JuzgadoDefault;
  roles: Role[];
  tokens: Tokens;
  organismo: Organismo;
  novedades: number;
  matricula: string;
  novedadesCriticas: Novedades[];
  ultimoAcceso: string;
  idUsuario: number;
  documento: Documento;
  cambioClave: boolean;
  cuit: string;
  nroDocumento: string;
  nombre: string;
  apellido: string;
  home: string;
  email: string;
  fechaAlta: Date;
  rolDefault: RolDefault;
  estado: Estado;
}
