<h1 mat-dialog-title>Sesion inactiva</h1>
<div mat-dialog-content style="max-width: 260px">
  <h3>
    <b>Se cerrará la sesión en {{ this.count }}</b>
  </h3>
  <p>
    Hace varios minutos que no se detectan cambios sobre la página, por
    cuestiones de seguridad se deberá volver a iniciar sesión
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cerrarSession()" color="warn">
    Cerrar Sesion
  </button>
  <button
    mat-stroked-button
    color="primary"
    (click)="renovarToken()"
    cdkFocusInitial
  >
    Seguir Conectado
  </button>
</div>
