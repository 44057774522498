<div class="row">
  <div class="col-lg-6">
    <form [formGroup]="form" (ngSubmit)="saveRegister($event)">
      <mat-card>
        <mat-card-header>
          <mat-card-title> Solicitud Cambio de organismo </mat-card-title>
          <br />
          <mat-error *ngIf="this.registroError != ''">
            {{ this.registroError }}
          </mat-error>
        </mat-card-header>
        <mat-card-content style="text-align: center">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Nro Documento</mat-label>
            <input matInput readonly type="text" formControlName="documento" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Organismo Actual</mat-label>
            <input matInput readonly formControlName="organismo" />
          </mat-form-field>
          <br />
          <mat-form-field
            *ngIf="this.form.value.juzgado"
            class="input-width"
            appearance="fill"
          >
            <mat-label>Juzgado Actual</mat-label>
            <input matInput readonly formControlName="juzgado" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Solicitar Organismo</mat-label>
            <mat-select
              formControlName="idOrganismo"
              (selectionChange)="selectJuzgado($event.value)"
            >
              <mat-option
                *ngFor="let item of this.organismo"
                [value]="item.id"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <br />
          <!-- Debera seleccionar Juzgado -->
          <mat-form-field
            *ngIf="this.form.value.juzgado"
            class="input-width"
            appearance="fill"
          >
            <mat-label>Nuevo Juzgado Titular</mat-label>
            <mat-select formControlName="idJuzgado">
              <mat-option *ngFor="let item of this.juzgado" [value]="item.id">{{
                item.descripcion
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Observacion</mat-label>
            <textarea
              matInput
              placeholder="ingrese alguna observacion"
              formControlName="observacion"
            ></textarea>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions style="text-align: right">
          <button
            mat-stroked-button
            color="warn"
            type="button"
            [routerLink]="['/']"
          >
            Cancelar
          </button>
          <button
            [disabled]="form.invalid"
            mat-raised-button
            color="primary"
            type="submit"
          >
            Enviar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</div>
