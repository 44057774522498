import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Juzgado, Organismo } from 'src/app/core/model/organismo.model';
import { SelectionModel, DataSource } from '@angular/cdk/collections';
import { AccountService } from 'src/app/core/services/account.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { OrganizmoService } from 'src/app/core/services/organizmo.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AlertaCambiosComponent } from '../alerta-cambios/alerta-cambios.component';

@Component({
  selector: 'app-gestion-juzgado',
  templateUrl: './gestion-juzgado.component.html',
  styleUrls: ['./gestion-juzgado.component.css'],
})
export class GestionJuzgadoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  registroError: string;
  form: FormGroup;
  juzgados: Juzgado[] = [];
  juzDisp: Juzgado[] = [];
  datasource = new MatTableDataSource([]);
  selectable = true;
  removable = true;
  selection = new SelectionModel<Juzgado>(true, []);
  displayedColumns: string[] = ['select', 'juzgado'];

  constructor(
    private formBuilder: FormBuilder,
    private accountSer: AccountService,
    private router: Router,
    private dialogConfirm: MatDialog,
    private matSnackBar: MatSnackBar,
    private orgService: OrganizmoService
  ) {
    this.buildForm();
    this.accountSer.getJuzgados().subscribe((juz) => {
      this.juzDisp = juz;
      this.datasource = new MatTableDataSource(this.juzDisp);
      this.datasource.paginator = this.paginator;
    });
  }

  ngOnInit(): void {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  remove(juz: Juzgado): void {
    const index = this.juzgados.indexOf(juz);

    if (index >= 0) {
      this.juzgados.splice(index, 1);
      const juzFilter = this.juzgados.map((item) => item.id);
      const disponible = this.juzDisp.filter((j) => !juzFilter.includes(j.id));
      this.datasource = new MatTableDataSource(disponible);
      this.datasource.paginator = this.paginator;
    }
  }

  checkboxLabel(row?: Juzgado): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  chechedJuz(check, item) {
    if (check.checked) {
      this.juzgados.push(item);
      const juzFilter = this.juzgados.map((o) => o.id);
      const disponible = this.juzDisp.filter((j) => !juzFilter.includes(j.id));
      this.datasource = new MatTableDataSource(disponible);
      this.datasource.paginator = this.paginator;
    }
  }

  saveRegister(event) {
    const nuevoOrg = {
      descripcion: this.form.value.organismo,
      juzgados: this.juzgados,
    };

    // Validamos que la modificacion no afecte ningun registro
    this.orgService
      .validarCambioOrganismo(null, nuevoOrg)
      .subscribe((valid) => {
        if (valid.length > 0) {
          this.dialogConfirm
            .open(AlertaCambiosComponent, {
              data: valid,
            })
            .afterClosed()
            .subscribe((confirmado: boolean) => {
              if (confirmado) {
                this.orgService.nuevoOrganizmo(nuevoOrg).subscribe(
                  (newRegistro) => {
                    console.log(newRegistro);
                    this.matSnackBar
                      .open('Organismo creado correctamente', 'Ok', {
                        duration: 3000,
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom',
                      })
                      .afterDismissed()
                      .subscribe(() => {
                        this.router.navigate(['./organismo/gestion']);
                      });
                  },
                  (err) => {
                    console.log(err);
                    this.registroError = err.error.detalle;
                  }
                );
              }
            });
        } else {
          this.orgService.nuevoOrganizmo(nuevoOrg).subscribe(
            (newRegistro) => {
              console.log(newRegistro);
              this.matSnackBar
                .open('Organismo creado correctamente', 'Ok', {
                  duration: 3000,
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                })
                .afterDismissed()
                .subscribe(() => {
                  this.router.navigate(['./organismo/gestion']);
                });
            },
            (err) => {
              console.log(err);
              this.registroError = err.error.detalle;
            }
          );
        }
      });
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      organismo: ['', Validators.required],
    });
  }
}
