import { Component, OnInit } from '@angular/core';
import { TokenModel } from '../core/model/token.model';
import { TokenService } from '../core/services/token.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  userInfo: TokenModel;

  constructor(private tokenService: TokenService, private router: Router) {}

  ngOnInit(): void {
    this.userInfo = this.tokenService.getToken();
    this.router.navigate([this.userInfo.home]);
  }
}
