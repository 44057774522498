import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ModelCuentasJudicial, FilterCuentas } from '../model/cuenta.model';
import {
  CuentaAsociadas,
  SolicitudCuentaAbogado,
  InformeIngresoAbogado,
} from '../model/colegio.model';
import { AbogadosCircunscripcion } from '../model/Circunscripcion.model';

@Injectable({
  providedIn: 'root',
})
export class AbogadoService {
  constructor(private http: HttpClient) {}

  getAbogadosCircunscripcion() {
    return this.http.get<AbogadosCircunscripcion[]>(
      `${environment.apiServiceBaseUri}/AdminColegios/abogados`
    );
  }

  getMisCuentas(
    page: number,
    orderColumn: string,
    orderByColumn: string
  ): Observable<CuentaAsociadas> {
    const requestUrl = `${
      environment.apiServiceBaseUri
    }/CuentasPorUsuarios/MisCuentas?NroPag=${
      page + 1
    }&RegXPag=${15}&orderColumn=${orderColumn}&orderByColumn=${orderByColumn}`;
    return this.http.get<CuentaAsociadas>(requestUrl);
  }

  getMisCuentasFiltar(
    filter: FilterCuentas,
    page: number
  ): Observable<CuentaAsociadas> {
    let param = '';
    if (filter.caratula) {
      param += `juzgado=${filter.caratula.juzgado}&caratula=${filter.caratula.carat}`;
    }

    if (filter.expediente) {
      if (filter.expediente.juzgado) {
        param += `juzgado=${filter.expediente.juzgado}&expediente=${filter.expediente.exp}`;
      } else {
        param += `expediente=${filter.expediente.exp}`;
      }
    }

    if (filter.CBU) {
      param += `cbu=${filter.CBU}`;
    }

    if (filter.cta) {
      //param += `juzgado=${filter.juzgado}&cta=${filter.cta}&subcta=${filter.subCta}`;
      param += `cta=${filter.cta}&subcta=${filter.subCta}`;
    }

    const requestUrl = `${
      environment.apiServiceBaseUri
    }/CuentasPorUsuarios/MisCuentas?${param}&NroPag=${page + 1}&RegXPag=${30}`;
    return this.http.get<CuentaAsociadas>(requestUrl);
  }

  getMisSolicitudes(
    page: number,
    orderColumn: string,
    orderByColumn: string
  ): Observable<CuentaAsociadas> {
    const requestUrl = `${
      environment.apiServiceBaseUri
    }/CuentasPorUsuarios/CuentasXUsuarioAbogado?NroPag=${
      page + 1
    }&RegXPag=${30}&orderColumn=${orderColumn}&orderByColumn=${orderByColumn}`;
    return this.http.get<CuentaAsociadas>(requestUrl);
  }

  getInformeIngreso(
    mes: number,
    anio: number
  ): Observable<InformeIngresoAbogado> {
    const requestUrl = `${environment.apiServiceBaseUri}/AdminColegios/InformeIngresos/${mes}/${anio}`;
    return this.http.get<InformeIngresoAbogado>(requestUrl);
  }

  getReporteIngreso(mes: number, anio: number) {
    const requestUrl = `${environment.apiServiceBaseUri}/AdminColegios/comprobanteIngresos/${mes}/${anio}`;
    return this.http.get<any>(requestUrl, { responseType: 'blob' as 'json' });
  }

  getMisSolicitudesFilter(
    filter: FilterCuentas,
    page: number
  ): Observable<CuentaAsociadas> {
    let param = '';
    if (filter.caratula) {
      param += `juzgado=${filter.caratula.juzgado}&caratula=${filter.caratula.carat}`;
    }

    if (filter.expediente) {
      if (filter.expediente.juzgado) {
        param += `juzgado=${filter.expediente.juzgado}&expediente=${filter.expediente.exp}`;
      } else {
        param += `expediente=${filter.expediente.exp}`;
      }
    }

    if (filter.CBU) {
      param += `cbu=${filter.CBU}`;
    }

    if (filter.cta) {
      param += `juzgado=${filter.juzgado}&cta=${filter.cta}&subcta=${filter.subCta}`;
    }

    const requestUrl = `${
      environment.apiServiceBaseUri
    }/CuentasPorUsuarios/CuentasXUsuarioAbogado?${param}&NroPag=${
      page + 1
    }&RegXPag=${30}`;
    return this.http.get<CuentaAsociadas>(requestUrl);
  }

  Inhabilitar(usuario: AbogadosCircunscripcion) {
    return this.http.delete(
      `${environment.apiServiceBaseUri}/Usuarios/${usuario.idUsuario}`
    );
  }

  getSolicitudCuentaAbogado(page: number): Observable<SolicitudCuentaAbogado> {
    const requestUrl = `${
      environment.apiServiceBaseUri
    }/CuentasPorUsuarios/CuentasXUsuarioColegio?NroPag=${
      page + 1
    }&RegXPag=${30}`;
    return this.http.get<SolicitudCuentaAbogado>(requestUrl);
  }
}
