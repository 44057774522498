import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders,
  HttpClient,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { TokenService } from './core/services/token.service';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TokenModel, Tokens } from './core/model/token.model';
import { AuthService } from './core/services/auth.service';

@Injectable()
export class AuthInterceptor {
  private isRefreshing = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private http: HttpClient,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.addToken(request);
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          if (
            request.url.includes('/Menues/xRol') ||
            request.url.includes('/Usuarios/authenticate')
          ) {
            return throwError(err);
          } else {
            return this.handle401Error(request, next);
          }
        } else {
          return throwError(err);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((refresh: Tokens) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(refresh.accessToken);
          return next.handle(this.addToken(request));
        }),
        catchError((err) => {
          if (err.status === 401) {
            this.router.navigate(['/auth/login']);
          }
          return throwError(err);
        })
      );
    } else {
      return next.handle(this.addRefreshToken(request)).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.isRefreshing = false;
            this.router.navigate(['/auth/login']);
          }
          return throwError(err);
        })
      );
    }
  }

  private addToken(request: HttpRequest<any>) {
    const token: TokenModel = this.tokenService.getToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token.tokens.accessToken.token,
        },
      });
      return request;
    } else {
      return request;
    }
  }

  private addRefreshToken(request: HttpRequest<any>) {
    const token: TokenModel = this.tokenService.getToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token.tokens.refreshToken.token,
        },
      });
      return request;
    } else {
      return request;
    }
  }
}
