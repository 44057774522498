<h1 class="mat-h1">Listado de Cuentas</h1>

<mat-accordion class="filter-headers-align">
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> Filtro de Busqueda </mat-panel-title>
      <mat-panel-description>
        Buscar cuentas judiciales
        <mat-icon>filter_alt</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <form [formGroup]="formFiltro">
        <mat-radio-group
          formControlName="tipoAsociacion"
          class="radio-group"
          aria-label="Ingrese Cbu o Alias"
        >
          <mat-radio-button value="cuenta" checked>Cuenta</mat-radio-button>
          <mat-radio-button class="item-radio" value="cbu"
            >CBU</mat-radio-button
          >
          <mat-radio-button class="item-radio" value="expediente"
            >Expendiente</mat-radio-button
          >
          <mat-radio-button class="item-radio" value="caratula"
            >Caratula</mat-radio-button
          >
        </mat-radio-group>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'cuenta'">
          <br />
          <div class="row">
            <div class="col-sm-2">
              <mat-form-field class="input-width-cuenta" appearance="fill">
                <mat-label>Cuenta</mat-label>
                <input matInput autofocus formControlName="cuenta" />
              </mat-form-field>
            </div>
            <div>
              <h3><b>/</b></h3>
            </div>
            <div class="col-sm-1">
              <mat-form-field class="input-width-cuenta" appearance="fill">
                <mat-label>Sub Cuenta</mat-label>
                <input matInput autofocus formControlName="subcuenta" />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'cbu'">
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>CBU</mat-label>
            <input matInput autofocus formControlName="cbu" />
          </mat-form-field>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'expediente'">
          <mat-form-field class="input-autocomplete" appearance="fill">
            <mat-label>Juzgado</mat-label>
            <input
              type="text"
              placeholder="Seleccione Juzgado"
              matInput
              formControlName="juzgado"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                class="option-autocomplete"
                *ngFor="let option of filteredOptions | async"
                [value]="option.id"
              >
                <span class="option-autocomplete-cod">{{ option.id }}</span>
                {{ " - " + option.descripcion }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Expediente</mat-label>
            <input matInput formControlName="expediente" />
          </mat-form-field>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'caratula'">
          <br />
          <mat-form-field class="input-autocomplete" appearance="fill">
            <mat-label>Juzgado</mat-label>
            <input
              type="text"
              placeholder="Seleccione Juzgado"
              matInput
              formControlName="juzgado"
              [matAutocomplete]="auto2"
            />
            <mat-autocomplete #auto2="matAutocomplete">
              <mat-option
                class="option-autocomplete"
                *ngFor="let option of filteredOptions | async"
                [value]="option.id"
              >
                <span class="option-autocomplete-cod">{{ option.id }}</span>
                {{ " - " + option.descripcion }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Caratula</mat-label>
            <input matInput autofocus formControlName="caratula" />
          </mat-form-field>
        </div>
        <mat-action-row>
          <button mat-raised-button color="primary" (click)="limpiarBusqueda()">
            Limpiar <mat-icon>cleaning_services</mat-icon>
          </button>
          <button mat-raised-button color="primary" (click)="buildData()">
            Buscar <mat-icon>search</mat-icon>
          </button>
        </mat-action-row>
      </form>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Filtrar Registros </mat-panel-title>
      <mat-panel-description>
        Filtra los datos de la grilla
        <mat-icon>list</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
    </mat-form-field>
  </mat-expansion-panel>
</mat-accordion>

<table
  mat-table
  [dataSource]="dataSource"
  class="full-width-table"
  matSort
  aria-label="Elements"
>
  <!-- cuenta Column -->
  <ng-container matColumnDef="cuenta">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nro Cuenta</th>
    <td mat-cell *matCellDef="let element">
      <b>{{ element.cuenta + "/" + element.subcuenta }}</b>
    </td>
  </ng-container>

  <!-- cbu Column -->
  <ng-container matColumnDef="cbu">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>CBU</th>
    <td mat-cell *matCellDef="let element">{{ element.cbu }}</td>
  </ng-container>

  <!-- caratula Column -->
  <ng-container matColumnDef="caratula">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Caratula</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.caratula"
      matTooltipPosition="above"
    >
      {{ element.caratula.substring(0, 10) + "..." }}
    </td>
  </ng-container>

  <!-- expediente Column -->
  <ng-container matColumnDef="expediente">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>expediente</th>
    <td mat-cell *matCellDef="let element">{{ element.expediente }}</td>
  </ng-container>

  <!-- saldoStr Column -->
  <ng-container matColumnDef="saldoStr">
    <th mat-header-cell *matHeaderCellDef>Saldo</th>
    <td mat-cell *matCellDef="let element">
      <b color="warn"> {{ element.saldo | currency }} </b>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Acciones</th>
    <td mat-cell *matCellDef="let row">
      <button
        mat-icon-button
        color="primary"
        [matMenuTriggerFor]="menu"
        aria-label="Acciones"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          *ngFor="let item of row.acciones"
          mat-menu-item
          (click)="ejecutar(item)"
        >
          <mat-icon [ngClass]="item.cssColor">{{ item.icono }}</mat-icon>
          <span>{{ item.titulo }}</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
</table>

<mat-paginator [length]="resultsLength" [pageSize]="15"></mat-paginator>
