import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './admin.guard';
import { LayoutComponent } from './layout/layout.component';
import { SolicitudesComponent } from './solicitudes/solicitudes.component';
import { AdminComponent } from './admin/admin.component';
import { RegistrarComponent } from './admin/registrar/registrar.component';
import { RegistroEditComponent } from './admin/registro-edit/registro-edit.component';
import { CircunscripcionComponent } from './abogados/circunscripcion/circunscripcion.component';
import { AccountComponent } from './account/account.component';
import { OperadoresComponent } from './juzgado/operadores/operadores.component';
import { AdministradoresComponent } from './juzgado/administradores/administradores.component';
import { CuentasComponent } from './cuentas/cuentas.component';
import { UsuariosComponent } from './juzgado/usuarios/usuarios.component';
import { ClaveVencidaComponent } from './auth/clave-vencida/clave-vencida.component';
import { SolicitudCambioComponent } from './perfil/solicitud-cambio/solicitud-cambio.component';
import { SolicitudRealizadasComponent } from './perfil/solicitud-realizadas/solicitud-realizadas.component';
import { EditarSolicitudComponent } from './perfil/editar-solicitud/editar-solicitud.component';
import { SolicitudPendientesComponent } from './perfil/solicitud-pendientes/solicitud-pendientes.component';
import { MisSolicitudesCambioCuentaComponent } from './account/mis-solicitudes-cambio-cuenta/mis-solicitudes-cambio-cuenta.component';
import { SolicitudCambioOrganismoComponent } from './organismo/solicitud-cambio-organismo/solicitud-cambio-organismo.component';
import { MisSolicitudesCambioOrganismoComponent } from './organismo/mis-solicitudes-cambio-organismo/mis-solicitudes-cambio-organismo.component';
import { EditarSolicitudOrganismoComponent } from './organismo/editar-solicitud-organismo/editar-solicitud-organismo.component';
import { PendienteCambioOrganismoComponent } from './organismo/pendiente-cambio-organismo/pendiente-cambio-organismo.component';
import { SolicitudCambioCuentaComponent } from './account/solicitud-cambio-cuenta/solicitud-cambio-cuenta.component';
import { EditarSolicitudCuentaComponent } from './account/editar-solicitud-cuenta/editar-solicitud-cuenta.component';
import { PendienteCambioCuentaComponent } from './account/pendiente-cambio-cuenta/pendiente-cambio-cuenta.component';
import { CambioClaveComponent } from './account/cambio-clave/cambio-clave.component';
import { AltaCuentaComponent } from './cuentas/alta-cuenta/alta-cuenta.component';
import { AltaBeneficiarioComponent } from './cuentas/alta-beneficiario/alta-beneficiario.component';
import { MovimientosComponent } from './cuentas/movimientos/movimientos.component';
import { OpjComponent } from './cuentas/opj/opj.component';
import { GestionOpjComponent } from './cuentas/gestion-opj/gestion-opj.component';
import { AutorizarOpjComponent } from './cuentas/autorizar-opj/autorizar-opj.component';
import { SolicitarCuentaComponent } from './colegio/solicitar-cuenta/solicitar-cuenta.component';
import { RegistroSolicitudCuentaComponent } from './colegio/registro-solicitud-cuenta/registro-solicitud-cuenta.component';
import { AutorizarCuentaComponent } from './colegio/autorizar-cuenta/autorizar-cuenta.component';
import { MisCuentasComponent } from './abogados/mis-cuentas/mis-cuentas.component';
import { TarjetaCoordenadasComponent } from './cuentas/tarjeta-coordenadas/tarjeta-coordenadas.component';
import { HomeComponent } from './home/home.component';
import { PerfilesComponent } from './juzgado/perfiles/perfiles.component';
import { MisSolicitudesCircuscripcionComponent } from './circuscripcion/mis-solicitudes-circuscripcion/mis-solicitudes-circuscripcion.component';
import { PendienteCambioCircuscripcionComponent } from './circuscripcion/pendiente-cambio-circuscripcion/pendiente-cambio-circuscripcion.component';
import { EditarSolicitudCircuscripcionComponent } from './circuscripcion/editar-solicitud-circuscripcion/editar-solicitud-circuscripcion.component';
import { SolicitarCambioCircuscripcionComponent } from './circuscripcion/solicitar-cambio-circuscripcion/solicitar-cambio-circuscripcion.component';
import { GestionJuzgadoComponent } from './organismo/gestion-juzgado/gestion-juzgado.component';
import { ListOrganismoComponent } from './organismo/list-organismo/list-organismo.component';
import { EditOrganismoComponent } from './organismo/edit-organismo/edit-organismo.component';
import { OpjsComponent } from './admin/opjs/opjs.component';
import { OpjEditComponent } from './cuentas/opj-edit/opj-edit.component';
import { ReporteIngresoComponent } from './colegio/reporte-ingreso/reporte-ingreso.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: 'solicitudes',
        canActivate: [AdminGuard],
        component: SolicitudesComponent,
      },
      {
        path: 'home',
        canActivate: [AdminGuard],
        component: HomeComponent,
      },
      {
        path: 'cuentas/cuentas-judiciales',
        canActivate: [AdminGuard],
        component: CuentasComponent,
      },
      {
        path: 'account',
        canActivate: [AdminGuard],
        component: AccountComponent,
      },
      {
        path: 'admin',
        canActivate: [AdminGuard],
        component: AdminComponent,
      },
      {
        path: 'admin/opje',
        canActivate: [AdminGuard],
        component: OpjsComponent,
      },
      {
        path: 'admin/registro/:id',
        canActivate: [AdminGuard],
        component: RegistrarComponent,
      },
      {
        path: 'admin/editRegistro/:id',
        canActivate: [AdminGuard],
        component: RegistroEditComponent,
      },
      {
        path: 'colegio/circunscripcion',
        canActivate: [AdminGuard],
        component: CircunscripcionComponent,
      },
      {
        path: 'juzgado/operadores',
        canActivate: [AdminGuard],
        component: OperadoresComponent,
      },
      {
        path: 'juzgado/administradores',
        canActivate: [AdminGuard],
        component: AdministradoresComponent,
      },
      {
        path: 'juzgado/usuarios',
        canActivate: [AdminGuard],
        component: UsuariosComponent,
      },
      {
        path: 'perfil/solicitud-cambio',
        canActivate: [AdminGuard],
        component: SolicitudCambioComponent,
      },
      {
        path: 'perfil/solicitudes',
        canActivate: [AdminGuard],
        component: SolicitudRealizadasComponent,
      },
      {
        path: 'perfil/editar-solicitud/:id',
        canActivate: [AdminGuard],
        component: EditarSolicitudComponent,
      },
      {
        path: 'perfil/pendientes',
        canActivate: [AdminGuard],
        component: SolicitudPendientesComponent,
      },
      {
        path: 'organismo/mis-solicitudes',
        canActivate: [AdminGuard],
        component: MisSolicitudesCambioOrganismoComponent,
      },
      {
        path: 'organismo/solicitud-cambio',
        canActivate: [AdminGuard],
        component: SolicitudCambioOrganismoComponent,
      },
      {
        path: 'organismo/editar-solicitud/:id',
        canActivate: [AdminGuard],
        component: EditarSolicitudOrganismoComponent,
      },
      {
        path: 'organismo/pendientes',
        canActivate: [AdminGuard],
        component: PendienteCambioOrganismoComponent,
      },
      {
        path: 'cuenta/reset-clave',
        canActivate: [AdminGuard],
        component: CambioClaveComponent,
      },
      {
        path: 'cuenta/mis-solicitudes',
        canActivate: [AdminGuard],
        component: MisSolicitudesCambioCuentaComponent,
      },
      {
        path: 'cuenta/solicitud-cambio',
        canActivate: [AdminGuard],
        component: SolicitudCambioCuentaComponent,
      },
      {
        path: 'cuenta/editar-solicitud/:id',
        canActivate: [AdminGuard],
        component: EditarSolicitudCuentaComponent,
      },
      {
        path: 'cuenta/pendientes',
        canActivate: [AdminGuard],
        component: PendienteCambioCuentaComponent,
      },
      {
        path: 'cuentas/generar-opj/:cbu',
        canActivate: [AdminGuard],
        component: OpjComponent,
      },
      {
        path: 'cuentas/opj-edit/:id',
        canActivate: [AdminGuard],
        component: OpjEditComponent,
      },
      {
        path: 'cuentas/alta-cuenta',
        canActivate: [AdminGuard],
        component: AltaCuentaComponent,
      },
      {
        path: 'cuentas/alta-beneficiario',
        canActivate: [AdminGuard],
        component: AltaBeneficiarioComponent,
      },
      {
        path: 'cuentas/gestion-opj',
        canActivate: [AdminGuard],
        component: GestionOpjComponent,
      },
      {
        path: 'cuentas/movimiento-cuentas/:cta/:subcta/:fechaDesde/:fechaHasta',
        canActivate: [AdminGuard],
        component: MovimientosComponent,
      },
      {
        path: 'cuentas/autorizar-opj',
        canActivate: [AdminGuard],
        component: AutorizarOpjComponent,
      },
      {
        path: 'colegio/solicitar-cuenta',
        canActivate: [AdminGuard],
        component: SolicitarCuentaComponent,
      },
      {
        path: 'colegio/registro',
        canActivate: [AdminGuard],
        component: RegistroSolicitudCuentaComponent,
      },
      {
        path: 'colegio/cuentas-judiciales',
        canActivate: [AdminGuard],
        component: MisCuentasComponent,
      },
      {
        path: 'colegio/reporte-ingreso',
        canActivate: [AdminGuard],
        component: ReporteIngresoComponent,
      },
      {
        path: 'colegio/autorizar-cuenta',
        canActivate: [AdminGuard],
        component: AutorizarCuentaComponent,
      },
      {
        path: 'cuentas/tarjeta-coordenadas',
        canActivate: [AdminGuard],
        component: TarjetaCoordenadasComponent,
      },
      {
        path: 'juzgado/perfiles',
        canActivate: [AdminGuard],
        component: PerfilesComponent,
      },
      {
        path: 'circunscripcion/mis-solicitudes',
        canActivate: [AdminGuard],
        component: MisSolicitudesCircuscripcionComponent,
      },
      {
        path: 'circunscripcion/mis-pendientes',
        canActivate: [AdminGuard],
        component: PendienteCambioCircuscripcionComponent,
      },
      {
        path: 'circunscripcion/editar-solicitud/:id',
        canActivate: [AdminGuard],
        component: EditarSolicitudCircuscripcionComponent,
      },
      {
        path: 'circunscripcion/solicitud-cambio',
        canActivate: [AdminGuard],
        component: SolicitarCambioCircuscripcionComponent,
      },
      {
        path: 'organismo/nuevo-organismo',
        canActivate: [AdminGuard],
        component: GestionJuzgadoComponent,
      },
      {
        path: 'organismo/editar-organismo/:id',
        canActivate: [AdminGuard],
        component: EditOrganismoComponent,
      },
      {
        path: 'organismo/gestion',
        canActivate: [AdminGuard],
        component: ListOrganismoComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
