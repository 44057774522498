import { Coelsa } from './coelsa.model';

export class Agenda {
  id: number;
  tipoDoc: number;
  nroDoc: string;
  aliasCBU: Coelsa;
  mail: string;
  aliasAgenda: string;
}
