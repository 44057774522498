import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Organismo, Juzgado } from 'src/app/core/model/organismo.model';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/core/services/account.service';
import { CambioOrganismoService } from 'src/app/core/services/cambio-organismo.service';
import { TokenService } from 'src/app/core/services/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenModel } from 'src/app/core/model/token.model';
import { Circunscripcion } from 'src/app/core/model/Circunscripcion.model';
import { CircuscripcionService } from 'src/app/core/services/circuscripcion.service';

@Component({
  selector: 'app-solicitar-cambio-circuscripcion',
  templateUrl: './solicitar-cambio-circuscripcion.component.html',
  styleUrls: ['./solicitar-cambio-circuscripcion.component.css'],
})
export class SolicitarCambioCircuscripcionComponent implements OnInit {
  form: FormGroup;
  registroError: string;
  hide = true;
  circunscripcion: Circunscripcion[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private accountService: AccountService,
    private circService: CircuscripcionService,
    private tokenService: TokenService,
    private matSnackBar: MatSnackBar
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    const modelUser: TokenModel = this.tokenService.getToken();
    const user = {
      documento: modelUser.documento.descripcion + ' ' + modelUser.nroDocumento,
      circunscripcion: modelUser.circunscripcion.descripcion,
      matricula: modelUser.matricula,
    };

    this.form.patchValue(user);

    // buscamos los perfiles
    this.accountService
      .getCircuscripcion()
      .subscribe((data: Circunscripcion[]) => {
        this.circunscripcion = data;
      });
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      /*Validamos que completo los datos de abogado o juzgado*/
      const solicitud = {
        circunscripcion: this.form.value.idCircunscripcion,
        matriculaNro: this.form.value.matriculaNro,
        detalle: this.form.value.observacion,
      };

      this.circService.solicitarCambio(solicitud).subscribe(
        (newRegistro) => {
          console.log(newRegistro);
          this.matSnackBar
            .open('Solicitud enviada correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            })
            .afterDismissed()
            .subscribe(() => {
              this.router.navigate(['./circunscripcion/mis-solicitudes']);
            });
        },
        (err) => {
          console.log(err);
          this.registroError = err.error.detalle;
        }
      );
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      documento: [''],
      circunscripcion: [''],
      matricula: [''],
      observacion: ['', [Validators.required]],
      idCircunscripcion: [null, [Validators.required]],
      matriculaNro: [null],
    });
  }
}
