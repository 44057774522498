import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ModelOpjs } from 'src/app/core/model/opjs.model';
import { SelectionModel } from '@angular/cdk/collections';
import { OpjSegmento } from 'src/app/core/model/opj-admin.model';
import { TokenModel } from 'src/app/core/model/token.model';
import { OpjService } from 'src/app/core/services/opj.service';
import { AccionesService } from 'src/app/core/services/acciones.service';
import { MatDialog } from '@angular/material/dialog';
import { TokenService } from 'src/app/core/services/token.service';
import { merge, of as observableOf } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { Acciones } from 'src/app/core/model/cuenta.model';
import { OpjAnularComponent } from 'src/app/cuentas/opj-anular/opj-anular.component';
import { GetionOpj } from 'src/app/core/model/gestion-opj.model';

@Component({
  selector: 'app-opjs',
  templateUrl: './opjs.component.html',
  styleUrls: ['./opjs.component.css'],
})
export class OpjsComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<ModelOpjs>;

  resultsLength = 0;
  autorizarOpj = false;
  isLoadingResults = true;
  isRateLimitReached = false;
  selection = new SelectionModel<GetionOpj>(true, []);
  registros: GetionOpj;
  userInfo: TokenModel;
  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'idOpj',
    'caratula',
    'expediente',
    'cuitBeneficiario',
    'cbuDestino',
    'operador',
    'montoOPJStr',
    'tipo',
    'estado',
    'actions',
  ];

  constructor(
    private opjService: OpjService,
    private accionService: AccionesService,
    private dialogConfirm: MatDialog,
    private tokenService: TokenService,
    public dialog: MatDialog
  ) {}

  ngAfterViewInit(): void {
    if (!this.sort) {
      return;
    }

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.opjService.getOpjSegmento(this.paginator.pageIndex);
        }),
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.totalRegistros;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((opjs: OpjSegmento) => {
        this.dataSource = new MatTableDataSource(opjs.opjs);
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    this.userInfo = this.tokenService.getToken();
  }

  ejecutar(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      this.dialogConfirm
        .open(OpjAnularComponent, {
          data: accion.body,
        })
        .afterClosed()
        .subscribe((data) => {
          this.ngAfterViewInit();
        });
    } else {
      this.accionService.customAccion(accion);
    }
  }
}
