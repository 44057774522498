import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Agenda } from '../model/agenda.model';

@Injectable({
  providedIn: 'root',
})
export class AgendaService {
  constructor(private http: HttpClient) {}

  guardarAgenda(agenda) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/Agendas/AgendarCoelsa/`,
      agenda
    );
  }

  getListaAgenda(soloBPN) {
    return this.http.get<Agenda[]>(
      `${environment.apiServiceBaseUri}/Agendas?soloBPN=${soloBPN}`
    );
  }

  getAgenda(id) {
    return this.http.get<Agenda[]>(
      `${environment.apiServiceBaseUri}/Agendas/${id}`
    );
  }
}
