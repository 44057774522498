<div class="row">
  <div class="col-lg-10">
    <mat-horizontal-stepper #stepper class="formulario">
      <mat-step [stepControl]="formAltaCuenta">
        <form [formGroup]="formAltaCuenta">
          <ng-template matStepLabel>Cuenta Judicial</ng-template>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Tipo de Expediente</mat-label>
            <mat-select formControlName="idTipoExpediente">
              <mat-option
                *ngFor="let item of this.tipoExpediente"
                [value]="item.id"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Juzgado</mat-label>
            <mat-select
              formControlName="idJuzgado"
              (selectionChange)="selectJuzgado($event.value)"
            >
              <mat-option
                *ngFor="let item of this.listJuzgado"
                [value]="item.id"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Nro. Expediente</mat-label>
            <input matInput formControlName="expediente" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Año</mat-label>
            <input
              matInput
              formControlName="anio"
              type="number"
              placeholder=""
            />
            <mat-icon matSuffix>today</mat-icon>
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Caratula</mat-label>
            <input matInput formControlName="caratula" />
          </mat-form-field>
          <div>
            <br />
            <mat-form-field class="input-width" appearance="fill">
              <mat-label
                >*Sucursal donde debera retirar la tarjeta de débito.</mat-label
              >
              <mat-select
                formControlName="sucursal"
                (selectionChange)="selectSucursal($event.value)"
              >
                <mat-option
                  *ngFor="let item of this.listSucursal"
                  [value]="item.id"
                  >{{ item.descripcion }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <p style="color: dimgray">{{ this.domicilio }}</p>
          </div>
          <br />
          <mat-form-field class="input-width" appearance="fill" *ngIf="!this.requiereBeneficiario">
            <mat-label>Sector del demandado</mat-label>
            <mat-select
              formControlName="sector"
              (selectionChange)="selectSector($event.value)"
            >
              <mat-option
                *ngFor="let item of this.listSectores"
                [value]="item.id"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <p style="color: dimgray; font-size: 1.2rem">
            {{ this.detalleSector }}
          </p>
          <br />
          <div>
            <button
              [disabled]="formAltaCuenta.invalid"
              matStepperNext
              mat-raised-button
              color="primary"
              type="submit"
            >
              Continuar
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step
        [stepControl]="formBeneficiario"
        *ngIf="this.requiereBeneficiario"
        optional
      >
        <form [formGroup]="formBeneficiario">
          <ng-template matStepLabel>Vinculacion Beneficiario</ng-template>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Tipo Documento</mat-label>
            <mat-select formControlName="idTipoDocumento">
              <mat-option
                *ngFor="let item of this.listTipodocumento"
                [value]="item.id"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Nro Documento</mat-label>
            <input
              matInput
              (ngModelChange)="buscarBeneficiario($event)"
              mask="00-00000000-00"
              formControlName="beneficiario"
            />
            <mat-icon *ngIf="this.cuitValid" class="correcto" matSuffix
              >done_all</mat-icon
            >
            <mat-icon *ngIf="this.createBenef" color="warn" matSuffix
              >person_add_disabled</mat-icon
            >
          </mat-form-field>
          <br />
          <div *ngIf="beneficiario">
            <h2>
              <b>{{
                beneficiario.persona.apellido.trim() +
                  ", " +
                  beneficiario.persona.nombre
              }}</b>
            </h2>
            <!-- <h3>Fecha Nacimiento: {{ beneficiario.persona.fecNacimiento.toString() }}</h3> -->
            <h3>Genero: {{ beneficiario.persona.sexo }}</h3>
            <br />
          </div>

          <div *ngIf="this.createBenef">
            <h2><b>El CUIT ingresado no existe</b></h2>
            <a mat-stroked-button (click)="altaBeneficiario()"
              >Crear beneficiario</a
            >
            <br />
          </div>

          <br />
          <div>
            <button mat-raised-button matStepperPrevious>Anterior</button>
            <button
              mat-raised-button
              color="primary"
              class="btnNext"
              matStepperNext
            >
              Siguiente
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Finalizacion</ng-template>
        <mat-error *ngIf="this.registroError != ''">
          <br />
          {{ this.registroError }}
        </mat-error>
        <br />
        <h2 *ngIf="!this.registroError">
          Para confirmar la operacion, haga clic en el botón Guardar
        </h2>
        <div *ngIf="this.requiereBeneficiario && !this.beneficiario">
          <p style="color: dimgray">
            *No se ingreso beneficiario, luego debera vincularlo a traves de
            gestion de cuentas
          </p>
        </div>
        <br />
        <div>
          <button mat-raised-button matStepperPrevious>Anterior</button>
          <button
            *ngIf="!this.registroError"
            mat-raised-button
            class="btnNext"
            color="primary"
            [disabled]="this.bloquear"
            (click)="saveRegister($event)"
          >
            Guardar
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
