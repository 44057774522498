<mat-toolbar color="primary" class="fix-nav">
  <button
    type="button"
    mat-icon-button
    class="visible-md"
    (click)="toggleSidebar()"
  >
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <div class="nav-brand">
    <img width="100px" src="/assets/images/bpnlogo.png" />
  </div>

  <span class="nav-spacer"></span>
  <button
    *ngIf="this.juzgadoDefault !== ''"
    style="margin-right: 2rem"
    mat-icon-button
    [matMenuTriggerFor]="juzgado"
  >
    {{ this.juzgadoDefault }}
    <mat-icon>business</mat-icon>
  </button>
  <mat-menu #juzgado="matMenu">
    <div style="text-align: center; color: grey"><h3>Cambiar Juzgado</h3></div>
    <mat-form-field
      style="font-size: 0.8rem"
      class="input-width"
      appearance="fill"
    >
      <mat-label>Juzgado</mat-label>
      <mat-select (selectionChange)="saveJuzgado($event.value)">
        <mat-option *ngFor="let item of this.listJuzgado" [value]="item.id">{{
          item.descripcion
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-menu>
  <button
    class="hidden-sm icon-margen"
    mat-icon-button
    [matBadge]="cantMessage"
    matBadgeColor="warn"
    (click)="mostrarNovedad()"
  >
    <mat-icon>notifications</mat-icon>
  </button>

  <br />
  <button mat-icon-button class="icon-margen">
    <mat-icon>help</mat-icon>
  </button>
  <br />
  <button mat-icon-button (click)="this.cerrarSesion()">
    <mat-icon>exit_to_app</mat-icon>
  </button>
</mat-toolbar>
