import { Component, OnInit, Inject } from '@angular/core';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import {
  Perfil,
  InfoPerfilUsuario,
} from 'src/app/core/model/solicitud-perfil.model';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/admin/dialog/dialog.component';
import { DialogData } from 'src/app/custom-dialog/custom-dialog.component';

@Component({
  selector: 'app-perfiles',
  templateUrl: './perfiles.component.html',
  styleUrls: ['./perfiles.component.css'],
})
export class PerfilesComponent implements OnInit {
  registroError: string;
  apellido: string;
  perfiles: Perfil[];

  constructor(
    private userService: UsuarioService,
    private dialogConfirm: MatDialog,
    public dialogRef: MatDialogRef<PerfilesComponent>,
    @Inject(MAT_DIALOG_DATA) public userInfo: InfoPerfilUsuario
  ) {}

  ngOnInit(): void {
    this.userService
      .getPerfilesUsuario(this.userInfo.tipoDoc, this.userInfo.nroDoc)
      .subscribe((perfil) => {
        this.perfiles = perfil;
      });
  }

  quitarPerfil(idPerfil, descPerfil) {
    this.dialogConfirm
      .open(DialogComponent, {
        data: `¿Esta seguro de eliminar el perfil ${descPerfil}  ?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          // eliminamos el perfil tildado
          this.userService
            .quitarPerfilUsuario(
              this.userInfo.tipoDoc,
              this.userInfo.nroDoc,
              idPerfil
            )
            .subscribe(
              (result) => {
                // traemos de la api nuevamente los datos del usuario
                this.userService
                  .getPerfilesUsuario(
                    this.userInfo.tipoDoc,
                    this.userInfo.nroDoc
                  )
                  .subscribe((perfil) => {
                    this.perfiles = perfil;
                  });
              },
              (err) => {
                this.registroError = err.error.detalle;
              }
            );
        }
      });
  }
}
