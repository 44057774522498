import { Organismo } from './organismo.model';

export class SolicitudRegistro {
  tipoDocumento: number;
  nroDocumento: string;
  nombre: string;
  apellido: string;
  fechaAlta: string;
  email: string;
  rol: Rol;
  estado: Estado;
  organismo: Organismo;
}

export interface Rol {
  idRol: number;
  descripcion: string;
}

export interface Estado {
  id: number;
  descripcion: string;
}

export enum EstadoUsuario {
  NoConfirmaEmail = 0,
  EsperaAdmin = 1,
  Habilitado = 2,
  Rechazado = 3,
  Inhabilitado = 4,
  Bloqueado = 5,
}
