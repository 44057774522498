import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SolicitudRegistro } from '../../core/model/solicitudes.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SolicitudesService {
  private solicitudes = new BehaviorSubject<SolicitudRegistro[]>([]);
  solicitudes$ = this.solicitudes.asObservable();

  constructor(private http: HttpClient) {}

  ListSolicitud() {
    return this.http.get<SolicitudRegistro[]>(
      `${environment.apiServiceBaseUri}/Solicitudes`
    );
  }

  putSolicitud(item: SolicitudRegistro) {
    const items: SolicitudRegistro[] = [];
    items.push(item);
    return this.http.put(`${environment.apiServiceBaseUri}/Solicitudes`, items);
  }

  badgeSolicitudes(sol: SolicitudRegistro[]) {
    this.solicitudes.next(sol);
  }
}
