<mat-horizontal-stepper #stepper class="formulario" linear>
  <mat-step [stepControl]="formAltaOpj">
    <form [formGroup]="formAltaOpj">
      <ng-template matStepLabel>Cuenta Origen</ng-template>
      <br />
      <div *ngIf="this.cuentaOrigen" fxLayoutAlign="center center">
        <div class="main-box">
          <div class="box-type">
            <p class="saldo">Saldo</p>
            <h3>
              {{ this.cuentaOrigen.saldoStr }}
            </h3>
          </div>
          <p>Expediente: {{ this.cuentaOrigen.expediente }}</p>
          <h3>Cuenta Origen: {{ this.cuentaOrigen.cuenta }}</h3>
        </div>
      </div>

      <mat-form-field class="input-width" appearance="fill">
        <mat-label>Tipo de OPJ</mat-label>
        <mat-select
          formControlName="tipoOPJ"
          (selectionChange)="selectTipoOpj($event.value)"
        >
          <mat-option
            *ngFor="let item of this.tipoOpj"
            [value]="item.idTipoOpj"
            >{{ item.descripcion }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <br />
      <mat-form-field class="input-width" appearance="fill">
        <mat-label>Tipo Transferencias</mat-label>
        <mat-select
          formControlName="tipoTransferncia"
          (selectionChange)="selectTipoTransferencia($event.value)"
        >
          <mat-option
            *ngFor="let item of this.tipoTransferencia"
            [value]="item.id"
            >{{ item.descripcion }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <br />
      <div>
        <button
          [disabled]="formAltaOpj.invalid"
          matStepperNext
          mat-raised-button
          color="primary"
          type="submit"
        >
          Continuar
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="formCuentaDestino" *ngIf="this.isDestinarioLoad">
    <form [formGroup]="formCuentaDestino">
      <ng-template matStepLabel>Cuenta Destino</ng-template>
      <br />

      <div class="row">
        <div class="col-6">
          <h2 style="text-align: left">
            <mat-icon color="warn">queue</mat-icon> Nuevo Destinatario
          </h2>
          <mat-radio-group
            formControlName="tipoDest"
            class="radio-group"
            aria-label="Ingrese Cbu o Alias"
          >
            <mat-radio-button value="true" checked>CBU</mat-radio-button>
            <mat-radio-button class="item-radio" value="false"
              >Alias</mat-radio-button
            >
          </mat-radio-group>
          <br />

          <!-- Si selecciona cbu se muestra el texto de busqueda    -->
          <div *ngIf="formCuentaDestino.value.tipoDest === 'true'">
            <mat-form-field style="width: 450px" appearance="fill">
              <mat-label>CBU</mat-label>
              <input
                matInput
                (ngModelChange)="buscarCbu($event)"
                class="input-importe"
                mask="00000000-00000000000000"
                formControlName="cbuDestino"
              />
              <mat-icon
                *ngIf="this.cuitValid && !this.erorrCBU"
                class="correcto"
                matSuffix
                >done_all</mat-icon
              >
              <mat-icon
                *ngIf="this.erorrCBU"
                color="warn"
                [matTooltip]="this.erorrCBU"
                matSuffix
                >person_remove</mat-icon
              >
            </mat-form-field>
          </div>

          <!-- Si selecciona alias se muestra el texto de busqueda    -->
          <div *ngIf="formCuentaDestino.value.tipoDest === 'false'">
            <mat-form-field class="input-width" appearance="fill">
              <mat-label>Alias</mat-label>
              <input
                matInput
                #box
                (blur)="buscarAlias(box.value)"
                formControlName="aliasDestino"
              />
              <mat-icon
                *ngIf="this.cuitValid && !this.erorrCBU"
                class="correcto"
                matSuffix
                >done_all</mat-icon
              >
              <mat-icon
                *ngIf="this.erorrCBU"
                color="warn"
                [matTooltip]="this.erorrCBU"
                matSuffix
                >person_remove</mat-icon
              >
            </mat-form-field>
          </div>

          <br />
          <div *ngIf="destinatario">
            <div *ngIf="destinatario.titulares.length > 1">
              <mat-form-field class="input-width" appearance="fill">
                <mat-label>Titular Cuenta</mat-label>
                <mat-select
                  formControlName="titular"
                  (selectionChange)="selectTitutalCbu($event.value)"
                >
                  <mat-option
                    *ngFor="let item of this.destinatario.titulares"
                    [value]="item.cuit"
                    >{{ item.cuit + " - " + item.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="destinatario.titulares.length === 1">
              <h3>
                <b>{{
                  destinatario.titulares[0].descDoc +
                    " : " +
                    destinatario.titulares[0].cuit
                }}</b>
              </h3>
              <h2>
                <b>{{ destinatario.titulares[0].nombre }}</b>
              </h2>
              <h3>{{ destinatario.cuenta.bancoDesc }}</h3>
            </div>

            <br />
            <mat-checkbox
              *ngIf="!this.itemAgenda"
              formControlName="guardaAgenda"
              ><h2>Guardar en la agenda?</h2></mat-checkbox
            >
            <div *ngIf="formCuentaDestino.value.guardaAgenda">
              <mat-form-field class="input-width" appearance="fill">
                <mat-label>Denominacion</mat-label>
                <input matInput formControlName="denominacion" autofocus />
              </mat-form-field>
              <br />
              <mat-form-field class="input-width" appearance="fill">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-6" style="max-height: 400px; overflow-y: auto">
          <h2 style="text-align: left">
            <mat-icon color="primary">library_books</mat-icon> Agenda
          </h2>
          <mat-form-field class="margenBoton" style="width: 90%">
            <mat-label>Filtrar</mat-label>
            <input
              matInput
              (keyup)="applyFilter($event)"
              placeholder="Buscar"
            />
          </mat-form-field>
          <div
            class="margenBoton"
            *ngFor="let item of this.dataSource.filteredData"
            (click)="selectAgenda(item)"
          >
            <div
              class="boxAgenda"
              [ngClass]="this.itemAgenda === item.id ? 'itemSelected' : ''"
            >
              <div class="margenBoton">
                <h3 style="padding-top: 10px">
                  <b>{{ item.aliasAgenda }}</b>
                </h3>
                <p style="color: rgb(139, 139, 139); margin-top: -10px">
                  {{ item.aliasCBU.titular.nombre }}
                </p>
                <span>{{ item.aliasCBU.cuenta.bancoDesc }}</span>
              </div>
            </div>
          </div>
          <br />
        </div>

        <br />
      </div>

      <div>
        <button mat-raised-button matStepperPrevious>Anterior</button>
        <button
          mat-raised-button
          color="primary"
          class="margenBoton"
          [disabled]="!this.destinatario"
          matStepperNext
        >
          Siguiente
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step
    [stepControl]="formBeneficiario"
    *ngIf="this.isBeneficiario"
    optional
  >
    <form [formGroup]="formBeneficiario">
      <ng-template matStepLabel>Beneficiario</ng-template>
      <br />
      <mat-form-field class="input-width" appearance="fill">
        <mat-label>Tipo Documento</mat-label>
        <input matInput value="CUIT/CUIL" readonly />
      </mat-form-field>
      <br />
      <mat-form-field class="input-width" appearance="fill">
        <mat-label>Nro Documento</mat-label>
        <input
          matInput
          (ngModelChange)="buscarBeneficiario($event)"
          mask="00-00000000-00"
          formControlName="nroDocumento"
        />
        <mat-icon *ngIf="this.cuitValid" class="correcto" matSuffix
          >done_all</mat-icon
        >
        <mat-icon *ngIf="this.failBenef" color="warn" matSuffix
          >person_add_disabled</mat-icon
        >
      </mat-form-field>
      <br />
      <div *ngIf="beneficiario">
        <h2>
          <b>{{
            beneficiario.apellido +
              (beneficiario.nombre ? "," + beneficiario.nombre : "")
          }}</b>
        </h2>
        <br />
      </div>

      <div *ngIf="this.failBenef">
        <h2><b>El CUIT ingresado no existe</b></h2>
        <br />
      </div>

      <div *ngIf="this.isFacultado">
        <mat-checkbox formControlName="facultado"
          >Especificar distinto facultado para el cobro ?</mat-checkbox
        >

        <div *ngIf="formBeneficiario.value.facultado">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>CUIT/CUIL</mat-label>
            <input
              matInput
              (ngModelChange)="buscarFacultado($event)"
              formControlName="documentofacultado"
            />
            <mat-icon *ngIf="this.cuitFacultadoValid" class="correcto" matSuffix
              >done_all</mat-icon
            >
            <mat-icon
              *ngIf="!this.cuitFacultadoValid && facultado"
              color="warn"
              matSuffix
              >person_remove</mat-icon
            >
          </mat-form-field>

          <div *ngIf="this.cuitFacultadoValid">
            <p>Facultado seleccionado para el cobro</p>
            <h2>
              <b>{{ facultado.apellido + "," + facultado.nombre }}</b>
            </h2>
            <br />
          </div>
        </div>
        <br />
      </div>

      <div *ngIf="this.isOtroTitular">
        <mat-checkbox formControlName="otroTitular"
          >Especificar titular CBU destino?</mat-checkbox
        >
        <br />

        <div *ngIf="formBeneficiario.value.otroTitular">
          <div *ngIf="this.titularCBU">
            <p>Titular de la Cuenta</p>
            <h2>
              <b>CUIT/CUIL {{ titularCBU.nroDocumento }}</b>
            </h2>
            <h2>
              <b>{{ titularCBU.nombre }}</b>
            </h2>
            <br />
          </div>
        </div>
      </div>
      <br />
      <div>
        <button mat-raised-button matStepperPrevious>Anterior</button>
        <button
          mat-raised-button
          color="primary"
          class="margenBoton"
          matStepperNext
        >
          Siguiente
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="formConcepto">
    <form [formGroup]="formConcepto">
      <ng-template matStepLabel>Concepto</ng-template>
      <br />
      <div fxLayoutAlign="center center">
        <mat-card style="width: 650px">
          <mat-card-header>
            <mat-card-title>Conceptos Agregados</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <br />
            <div class="row">
              <div class="col-md-8">
                <mat-form-field style="width: 100%" appearance="fill">
                  <mat-label>Concepto</mat-label>
                  <mat-select formControlName="idConcepto">
                    <mat-option
                      *ngFor="let item of this.listConceptos"
                      [value]="item.codigoConcepto"
                      >{{ item.descripcion }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <br />
            </div>
            <div class="row">
              <div class="col-md-8">
                <mat-form-field style="width: 100%" appearance="fill">
                  <mat-label>Importe</mat-label>
                  <span class="input-importe" matPrefix>$&nbsp;</span>
                  <input
                    matInput
                    formControlName="importe"
                    [allowNegativeNumbers]="false"
                    mask="separator.2"
                    class="input-importe"
                    placeholder="0,00"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <button
                  mat-button
                  color="primary"
                  style="float: left; margin-top: 1rem"
                  aria-label="Agregar concepto"
                  (click)="agregarConcepto()"
                >
                  Agregar
                </button>
              </div>
            </div>

            <br />
            <!-- Cajas de Conceptos -->

            <div class="row">
              <div class="col-sm-6" *ngFor="let item of this.itemConceptoOpj">
                <div class="main-box-concepto">
                  <div class="box-type-concepto">
                    <p class="saldo">Importe</p>
                    <h3>
                      {{ item.importe | currency }}
                    </h3>
                  </div>

                  <!-- Icono para cerrar -->
                  <button
                    mat-icon-button
                    color="warn"
                    style="float: left; margin-top: -1rem"
                    (click)="eliminarItem(item.idConcepto)"
                  >
                    <mat-icon>close</mat-icon>
                  </button>

                  <h3>
                    <b>{{ item.despcipcion }}</b>
                  </h3>
                </div>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <h2>
              Importe Total: <b>{{ getImporteTotal | currency }}</b>
            </h2>
          </mat-card-actions>
        </mat-card>
      </div>

      <br />

      <mat-form-field style="width: 650px" appearance="fill">
        <mat-label>Comentario</mat-label>
        <textarea
          matInput
          formControlName="comentario"
          placeholder="Ingrese un comentario de la operacion..."
        ></textarea>
      </mat-form-field>

      <br />

      <div>
        <button mat-raised-button matStepperPrevious>Anterior</button>
        <button
          mat-raised-button
          color="primary"
          class="margenBoton"
          matStepperNext
        >
          Siguiente
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Resumen</ng-template>
    <mat-error *ngIf="this.registroError != ''">
      <br />
      <h1>{{ this.registroError }}</h1>
    </mat-error>
    <br />
    <h2>Confirma la siguiente Operacion?</h2>
    <br />
    <div class="row">
      <div class="col-md-6">
        <h2 class="labelResumen">Cta Origen:</h2>
      </div>
      <div class="col-md-6">
        <h1 class="labelValueResumen">{{ this.resumenOpj.CtaOrigen }}</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h2 class="labelResumen">Tipo Opj:</h2>
      </div>
      <div class="col-md-6">
        <h1 class="labelValueResumen">{{ this.resumenOpj.TipoOPJ }}</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h2 class="labelResumen">Tipo Transferencia:</h2>
      </div>
      <div class="col-md-6">
        <h1 class="labelValueResumen">{{ this.resumenOpj.TipoTransf }}</h1>
      </div>
    </div>

    <div class="row" *ngIf="this.resumenOpj.cbu">
      <div class="col-md-6">
        <h2 class="labelResumen">CBU Destino:</h2>
      </div>
      <div class="col-md-6">
        <h1 class="labelValueResumen">{{ this.resumenOpj.cbu }}</h1>
      </div>
    </div>

    <div class="row" *ngIf="this.resumenOpj.titular">
      <div class="col-md-6">
        <h2 class="labelResumen">Titular Destino:</h2>
      </div>
      <div class="col-md-6">
        <h1 class="labelValueResumen">{{ this.resumenOpj.titular }}</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h2 class="labelResumen">Importe Total:</h2>
      </div>
      <div class="col-md-6">
        <h1 class="labelValueResumen">
          {{ this.resumenOpj.importe | currency }}
        </h1>
      </div>
    </div>
    <br />
    <div>
      <button
        mat-raised-button
        matStepperPrevious
        (click)="this.registroError = ''"
      >
        Anterior
      </button>
      <button
        mat-raised-button
        class="margenBoton"
        color="primary"
        (click)="saveRegister($event)"
      >
        Guardar
      </button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
