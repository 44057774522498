import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import {
  CuentaJudicial,
  ModelCuentasJudicial,
  FilterCuentas,
} from '../core/model/cuenta.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { UsuarioJuzgado } from '../core/model/usuario-juzgado.mode';
import { JuzgadoService } from '../core/services/juzgado.service';
import { MatDialog } from '@angular/material/dialog';
import { CuentaJudicialService } from '../core/services/cuentaJudicial.service';
import { AccionesService } from '../core/services/acciones.service';
import { merge, of as observableOf, Observable } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { TokenModel } from '../core/model/token.model';
import { TokenService } from '../core/services/token.service';
import { Acciones } from 'src/app/core/model/cuenta.model';
import { ReportesService } from '../core/services/reportes.service';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Juzgado } from '../core/model/organismo.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from '../admin/dialog/dialog.component';

@Component({
  selector: 'app-cuentas',
  templateUrl: './cuentas.component.html',
  styleUrls: ['./cuentas.component.css'],
})
export class CuentasComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<ModelCuentasJudicial>;

  resultsLength = 0;
  isLoadingResults = true;
  listJuzgado: Juzgado[];
  isVisibleCbo = false;
  juzActual: string;
  isRateLimitReached = false;
  userInfo: TokenModel;
  registros: ModelCuentasJudicial;
  filteredOptions: Observable<Juzgado[]>;
  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'cuenta',
    'cbu',
    'caratula',
    'expediente',
    'saldoStr',
    'estadoDesc',
    'actions',
  ];
  diccionario = {
    cuenta: 'CuentaNro',
    cbu: 'cbu',
    caratula: 'caratula',
    expediente: 'expediente',
    saldoStr: 'saldo',
  };

  formFiltro: FormGroup;

  constructor(
    private cuentasService: CuentaJudicialService,
    private formBuilder: FormBuilder,
    private accionService: AccionesService,
    private reporteSvr: ReportesService,
    private juzgadoServ: JuzgadoService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private tokenService: TokenService,
    public dialog: MatDialog
  ) {
    this.buildForm();
  }

  ngAfterViewInit(): void {
    if (!this.sort) {
      return;
    }

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          const filter = new FilterCuentas();

          if (this.formFiltro.value.tipoAsociacion === 'caratula') {
            filter.caratula = {
              juzgado: this.juzgadoDefault,
              carat: this.formFiltro.value.caratula,
            };
          }

          if (this.formFiltro.value.tipoAsociacion === 'expediente') {
            filter.expediente = {
              juzgado: this.juzgadoDefault,
              exp: this.formFiltro.value.expediente,
            };
          }

          if (this.formFiltro.value.tipoAsociacion === 'cbu') {
            if (!this.formFiltro.value.cbu) {
              this.matSnackBar.open('Ingrese un cbu valido', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              return;
            }
            filter.CBU = this.formFiltro.value.cbu;
          }

          if (this.formFiltro.value.tipoAsociacion === 'cuenta') {
            if (!this.formFiltro.value.cuenta) {
              this.matSnackBar.open('Ingrese una cuenta valida ', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              return;
            }
            filter.juzgado = this.juzgadoDefault;
            filter.subCta = this.formFiltro.value.subcuenta;
            filter.cta = this.formFiltro.value.cuenta;
          }

          let valueSort = this.diccionario[this.sort.active];
          if (valueSort === undefined) {
            valueSort = 'CuentaNro';
            this.sort.direction = 'asc';
          }
          return this.cuentasService.getCuentasSubFilter(
            this.juzgadoDefault,
            filter,
            this.paginator.pageIndex,
            valueSort,
            this.sort.direction
          );
        }),
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.cantidadDePaginas * data.registrosPorPagina;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((cuentas: ModelCuentasJudicial) => {
        this.dataSource = new MatTableDataSource(cuentas.cuentas);
      });
  }

  ngOnInit(): void {
    this.userInfo = this.tokenService.getToken();
    this.juzActual = this.juzgadoDefault;
    this.juzgadoServ.getMisJuzgados().subscribe((data) => {
      this.listJuzgado = data;
    });

    this.filteredOptions = this.formFiltro.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value.juzgado))
    );
  }

  get juzgadoDefault(): string {
    const juz = localStorage.getItem(`juzgado${this.userInfo.nroDocumento}`);

    if (this.juzActual !== juz) {
      this.juzActual = juz;
      this.ngAfterViewInit();
    }

    if (juz) {
      return juz;
    } else {
      return this.userInfo.juzgadoDefault.id;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  imprimirItems(evento) {
    this.dialog
      .open(DialogComponent, {
        data: `¿Esta seguro de imprimir la pagina actual  ?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          const filter = new FilterCuentas();
          filter.juzgado = this.juzgadoDefault;

          if (this.formFiltro.value.tipoAsociacion === 'caratula') {
            filter.caratula = {
              juzgado: this.juzgadoDefault,
              carat: this.formFiltro.value.caratula,
            };
          }

          if (this.formFiltro.value.tipoAsociacion === 'expediente') {
            filter.expediente = {
              juzgado: this.juzgadoDefault,
              exp: this.formFiltro.value.expediente,
            };
          }

          if (this.formFiltro.value.tipoAsociacion === 'cbu') {
            filter.CBU = this.formFiltro.value.cbu;
          }

          if (this.formFiltro.value.tipoAsociacion === 'cuenta') {
            filter.juzgado = this.juzgadoDefault;
            filter.subCta = this.formFiltro.value.subcuenta;
            filter.cta = this.formFiltro.value.cuenta;
          }

          this.cuentasService
            .getReportePDFCuentas(filter, this.paginator.pageIndex)
            .subscribe((data: any) => {
              console.log(data);
              const blob: any = new Blob([data], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            });
        }
      });
  }

  private _filter(value: string): Juzgado[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.listJuzgado.filter(
        (option) =>
          option.descripcion.toLowerCase().includes(filterValue) ||
          option.id.toLowerCase().includes(filterValue)
      );
    } else {
      return this.listJuzgado;
    }
  }

  limpiarBusqueda() {
    const filter = {
      tipoAsociacion: 'cbu',
      cuenta: '',
      subcuenta: '',
      cbu: '',
      juzgado: '',
      expediente: '',
      caratula: '',
    };
    this.formFiltro.patchValue(filter);
    this.ngAfterViewInit();
  }

  download() {
    this.reporteSvr.downloadFile('09700222-53001309020025').subscribe(
      (response) => {
        console.log(response);
        const blob: any = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ejecutar1(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      this.router.navigate([accion.url]);
    } else {
      this.accionService.customAccion(accion);
    }
  }

  ejecutar2(accion: Acciones) {
    if (accion.titulo === 'Ultimos movimientos' && accion.schemaJson === 'custom') {
      const url = this.router.createUrlTree([accion.url]).toString();
      window.open(url, '_blank'); // Abre en una nueva pestaña
    } else {
      this.router.navigate([accion.url]);
    }
  }

  ejecutar(accion: Acciones) {
    if (accion.titulo === 'Ultimos movimientos' && accion.schemaJson === 'custom') {
      const url = this.router.createUrlTree([accion.url]).toString();
      window.open(url, '_blank'); // Abre en una nueva pestaña
    } else if (accion.schemaJson === 'custom') {
      this.router.navigate([accion.url]);
    } else {
      this.accionService.customAccion(accion);
    }
  }

  buildData() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          const filter = new FilterCuentas();

          if (this.formFiltro.value.tipoAsociacion === 'caratula') {
            filter.caratula = {
              juzgado: this.juzgadoDefault,
              carat: this.formFiltro.value.caratula,
            };
          }

          if (this.formFiltro.value.tipoAsociacion === 'expediente') {
            filter.expediente = {
              juzgado: this.juzgadoDefault,
              exp: this.formFiltro.value.expediente,
            };
          }

          if (this.formFiltro.value.tipoAsociacion === 'cbu') {
            if (!this.formFiltro.value.cbu) {
              this.matSnackBar.open('Ingrese un cbu valido', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              return;
            }
            filter.CBU = this.formFiltro.value.cbu;
          }

          if (this.formFiltro.value.tipoAsociacion === 'cuenta') {
            if (!this.formFiltro.value.cuenta) {
              this.matSnackBar.open('Ingrese una cuenta valida ', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              return;
            }
            filter.juzgado = this.juzgadoDefault;
            filter.subCta = this.formFiltro.value.subcuenta;
            filter.cta = this.formFiltro.value.cuenta;
          }

          return this.cuentasService.getCuentasFilter(
            filter,
            this.paginator.pageIndex
          );
        }),
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.cantidadDePaginas * data.registrosPorPagina;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe(
        (cuentas: ModelCuentasJudicial) => {
          this.dataSource = new MatTableDataSource(cuentas.cuentas);
        },
        (errr) => {
          this.matSnackBar.open('No se encontraron registros', 'Ok', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      );
  }

  private buildForm() {
    this.formFiltro = this.formBuilder.group({
      tipoAsociacion: ['expediente', [Validators.required]],
      cuenta: [''],
      subcuenta: [''],
      cbu: [''],
      juzgado: [''],
      expediente: [''],
      caratula: [''],
    });
  }
}
