<h2 mat-dialog-title>Notificaciones</h2>
<mat-dialog-content>
  <div class="margenBoton" *ngFor="let item of this.listNovedades">
    <mat-divider></mat-divider>
    <div class="boxAgenda">
      <div class="margenBoton">
        <h3 *ngIf="item.vistaPorUsuario" style="padding-top: 10px">
          <mat-icon class="notify-read">email</mat-icon>
          {{ item.titulo }}
        </h3>
        <h3 *ngIf="!item.vistaPorUsuario" style="padding-top: 10px">
          <mat-icon class="notify-unread">mark_email_unread</mat-icon>
          <b>{{ item.titulo }}</b>
        </h3>
        <p style="color: rgb(139, 139, 139); margin-top: -10px">
          {{ item.descripcion }}
        </p>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
