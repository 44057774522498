<div class="row">
  <div class="col-xs-offset-3 col-xs-6">
    <form [formGroup]="form" (ngSubmit)="saveRegister($event)">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Formulario de Edicion {{ this.perfil }}
          </mat-card-title>
          <mat-error *ngIf="this.registroError != ''">
            <br />
            {{ this.registroError }}
          </mat-error>
        </mat-card-header>
        <mat-card-content style="text-align: center">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="nombre" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Apellido</mat-label>
            <input matInput formControlName="apellido" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>DNI</mat-label>
            <input matInput type="number" formControlName="dni" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Perfil</mat-label>
            <input matInput disabled [value]="this.perfil" />
          </mat-form-field>
          <br />

          <div *ngIf="this.panelAbogado">
            <!-- Debera seleccionar circuscripcion -->
            <mat-form-field class="input-width" appearance="fill">
              <mat-label>Circunscripcion</mat-label>
              <mat-select formControlName="idCircunscripcion">
                <mat-option
                  *ngFor="let item of this.listCircuns"
                  [value]="item.idCircunscripcion"
                  >{{ item.descripcion }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <br />
          </div>
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>email</mat-label>
            <input matInput formControlName="email" />
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions style="text-align: right">
          <button
            mat-stroked-button
            color="warn"
            type="button"
            (click)="cancelarRegistro()"
          >
            Cancelar
          </button>
          <button
            [disabled]="form.invalid"
            mat-raised-button
            color="primary"
            type="submit"
          >
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</div>
