<h1 class="mat-h1">Habilitar Solicitud (Cuentas Judiciales)</h1>

<div>
  <mat-form-field>
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
  </mat-form-field>
</div>

<table
  mat-table
  [dataSource]="dataSource"
  class="full-width-table"
  matSort
  aria-label="Elements"
>
  <!-- cuenta Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Matricula</th>
    <td mat-cell *matCellDef="let element">
      <b>{{ element.abogado.matricula }}</b>
    </td>
  </ng-container>

  <ng-container matColumnDef="nombre">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Abogado/a</th>
    <td mat-cell *matCellDef="let element">
      {{ element.abogado.nombre + ", " + element.abogado.apellido }}
    </td>
  </ng-container>
  <!-- cbu Column -->
  <ng-container matColumnDef="juzgado">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Juzgado</th>
    <td mat-cell *matCellDef="let element">
      {{ element.juzgado.descripcion }}
    </td>
  </ng-container>

  <!-- cbu Column -->
  <ng-container matColumnDef="cuenta">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cuenta</th>
    <td mat-cell *matCellDef="let element">
      <b> {{ element.cuenta + "/" + element.subcuenta }} </b>
    </td>
  </ng-container>

  <!-- caratula Column -->
  <ng-container matColumnDef="caratula">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Caratula</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.caratula"
      matTooltipPosition="above"
    >
      {{ element.caratula.substring(0, 10) + "..." }}
    </td>
  </ng-container>

  <!-- expediente Column -->
  <ng-container matColumnDef="expediente">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>expediente</th>
    <td mat-cell *matCellDef="let element">{{ element.expediente }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Acciones</th>
    <td mat-cell *matCellDef="let row">
      <button
        mat-icon-button
        color="primary"
        [matMenuTriggerFor]="menu"
        aria-label="Acciones"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          *ngFor="let item of row.acciones"
          mat-menu-item
          (click)="ejecutar(item)"
        >
          <mat-icon [ngClass]="item.cssColor">{{ item.icono }}</mat-icon>
          <span>{{ item.titulo }}</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
</table>

<mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
