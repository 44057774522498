import { Injectable } from '@angular/core';
import { TokenModel, Tokens } from '../model/token.model';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  constructor() {}

  saveCache(key: string, model: any) {
    localStorage.setItem(key, JSON.stringify(model));
  }

  getCache(key: string) {
    const token = localStorage.getItem(key);
    return JSON.parse(token);
  }

  borrarCache(key: string) {
    localStorage.removeItem(key);
  }

  borrarAllCache() {
    localStorage.clear();
  }
}
