import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SolicitudCambioPerfil } from 'src/app/core/model/solicitud-perfil.model';
import { AccountService } from 'src/app/core/services/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/admin/dialog/dialog.component';

@Component({
  selector: 'app-solicitud-realizadas',
  templateUrl: './solicitud-realizadas.component.html',
  styleUrls: ['./solicitud-realizadas.component.css'],
})
export class SolicitudRealizadasComponent implements OnInit {
  registros: SolicitudCambioPerfil[];
  registroError: string;
  dataSource = new MatTableDataSource(this.registros);
  displayedColumns = [
    'id',
    'rolDefault',
    'perfil',
    'fechaSolicitud',
    'estado',
    'actions',
  ];
  regNuevo = true;

  constructor(
    private account: AccountService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.buidGrid();
  }

  cancelar(id) {
    this.dialog
      .open(DialogComponent, {
        data: `¿Esta seguro de cancelar la solicitud #${id} ?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.account.borrarSolicitud(id).subscribe(
            (reg) => {
              this.matSnackBar
                .open('Solicitud cancelada correctamente', 'Ok', {
                  duration: 3000,
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                })
                .afterDismissed()
                .subscribe(() => {
                  this.buidGrid();
                });
            },
            (err) => {
              console.log(err);
              this.registroError = err.error.ExceptionMessage;
            }
          );
        }
      });
  }

  buidGrid() {
    this.account.MisSolicitudes().subscribe((reg) => {
      this.registros = reg;
      if (reg.find((f) => f.estado.descripcion === 'Iniciada')) {
        this.regNuevo = false;
      }
      this.dataSource = new MatTableDataSource(this.registros);
    });
  }
}
