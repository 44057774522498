<div class="row col-xs-offset-9 col-xs-5">
  <div class="col">
    <button
      [disabled]="!this.regNuevo"
      mat-raised-button
      color="primary"
      [routerLink]="['/organismo/solicitud-cambio']"
    >
      <span class="material-icons">note_add</span> Nueva Solicitud
    </button>
  </div>
</div>

<div class="grid-container">
  <h1 class="mat-h1">Solicitudes de cambio organismo</h1>
  <mat-error *ngIf="this.registroError != ''">
    <br />
    {{ this.registroError }}
  </mat-error>
  <div class="mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSource"
      class="full-width-table"
      aria-label="Elements"
    >
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>#Nro Solicitud</th>
        <td mat-cell *matCellDef="let row">{{ row.idSolicitud }}</td>
      </ng-container>

      <!-- nombre Column -->
      <ng-container matColumnDef="organismo">
        <th mat-header-cell *matHeaderCellDef>Organismo Solicitado</th>
        <td mat-cell *matCellDef="let row">{{ row.organismo.descripcion }}</td>
      </ng-container>

      <!-- nombre Column -->
      <ng-container matColumnDef="juzgado">
        <th mat-header-cell *matHeaderCellDef>Juzgado Titular</th>
        <td mat-cell *matCellDef="let row">{{ row.juzgadoTitular }}</td>
      </ng-container>

      <!-- DOC Column -->
      <ng-container matColumnDef="fechaSolicitud">
        <th mat-header-cell *matHeaderCellDef>Fec. Solicitud</th>
        <td mat-cell *matCellDef="let row">
          {{ row.fechaSolicitud | date: "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef>Estado</th>
        <td mat-cell *matCellDef="let row">
          <b>{{ row.estado.descripcion }} </b>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Acciones</th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            color="primary"
            *ngIf="row.estado.puedeEditar"
            [matMenuTriggerFor]="menu"
            aria-label="Acciones"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              [routerLink]="['/organismo/editar-solicitud', row.idSolicitud]"
            >
              <mat-icon color="accent">create</mat-icon>
              <span>Editar</span>
            </button>
            <button mat-menu-item (click)="cancelar(row.idSolicitud)">
              <mat-icon color="warn">clear</mat-icon>
              <span>Cancelar</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
