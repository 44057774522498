import { Injectable } from '@angular/core';
import { Acciones } from 'src/app/core/model/cuenta.model';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TipoSchema, BlobModel } from '../model/schema-model';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialogComponent } from 'src/app/custom-dialog/custom-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatInput } from '@angular/material/input';
import { DialogComponent } from 'src/app/admin/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AccionesService {
  private resultado: Observable<any>;
  private badgeResultado = new BehaviorSubject<any>([]);
  badgeResultado$ = this.badgeResultado.asObservable();

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private dialogConfirm: MatDialog,
    private matSnackBar: MatSnackBar
  ) {}

  customAccion(accion: Acciones) {
    const model = JSON.parse(accion.body);
    // Evaluamos si tdemo enemos que levantar un formulario
    if (accion.schemaJson) {
      this.http
        .get<any>(
          `${environment.apiServiceBaseUri}/schemas/` + accion.schemaJson
        )
        .subscribe((schemajson) => {
          console.log(schemajson);
          const dialogRef = this.dialog
            .open(CustomDialogComponent, {
              width: '250px',
              data: { jsonModel: model, JsonSchema: schemajson, accion },
            })
            .afterClosed()
            .subscribe((data) => {
              this.badgeResultado.next(data);
            });
        });
    } else {
      if (accion.requiereConfirmacion) {
        this.dialogConfirm
          .open(DialogComponent, {
            data: accion.descripcion, // `¿Esta seguro de ${accion.titulo} el registro seleccionado  ?`
          })
          .afterClosed()
          .subscribe((confirmado: boolean) => {
            if (confirmado) {
              switch (accion.verbo) {
                case 'POST':
                  this.resultado = this.http.post(
                    `${environment.apiServiceBaseUri}/` + accion.url,
                    model
                  );
                  break;
                case 'PUT':
                  this.resultado = this.http.put(
                    `${environment.apiServiceBaseUri}/` + accion.url,
                    model
                  );
                  break;
                case 'DELETE':
                  this.resultado = this.http.delete(
                    `${environment.apiServiceBaseUri}/` + accion.url
                  );
                  break;
                default:
                  console.log(model);
                  this.resultado = this.http.get(
                    `${environment.apiServiceBaseUri}/` + accion.url,
                    model
                  );
                  break;
              }

              this.resultado.subscribe(
                (data) => {
                  if (model) {
                    if (model.responseType) {
                      const blob: any = new Blob([data], {
                        type: 'application/pdf',
                      });
                      const url = window.URL.createObjectURL(blob);
                      window.open(url);
                    }
                  }

                  this.matSnackBar
                    .open('Solicitud enviada correctamente', 'Ok', {
                      duration: 3000,
                      horizontalPosition: 'center',
                      verticalPosition: 'bottom',
                    })
                    .afterDismissed()
                    .subscribe(() => {
                      this.badgeResultado.next(data);
                    });
                },
                (err) => {
                  this.matSnackBar
                    .open(err.error.detalle, 'Ok', {
                      duration: 3000,
                      horizontalPosition: 'center',
                      verticalPosition: 'top',
                    })
                    .afterDismissed()
                    .subscribe(() => {
                      this.badgeResultado.next(err);
                    });
                }
              );
            }
          });
      } else {
        switch (accion.verbo) {
          case 'POST':
            this.resultado = this.http.post(
              `${environment.apiServiceBaseUri}/` + accion.url,
              model
            );
            break;
          case 'PUT':
            this.resultado = this.http.put(
              `${environment.apiServiceBaseUri}/` + accion.url,
              model
            );
            break;
          case 'DELETE':
            this.resultado = this.http.delete(
              `${environment.apiServiceBaseUri}/` + accion.url
            );
            break;
          default:
            console.log(model);
            this.resultado = this.http.get(
              `${environment.apiServiceBaseUri}/` + accion.url,
              model
            );
            break;
        }

        this.resultado.subscribe((data) => {
          if (model) {
            if (model.responseType) {
              const blob: any = new Blob([data], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            }
          }

          this.matSnackBar
            .open('Solicitud enviada correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            })
            .afterDismissed()
            .subscribe(() => {
              this.badgeResultado.next(data);
            });
        });
      }
    }
  }
}
