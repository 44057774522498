import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BeneficiarioService } from 'src/app/core/services/beneficiario.service';
import {
  TipoTransferencias,
  TipoOpj,
  Concepto,
  ResumenOPJ,
  FormaPagoOPJ,
} from 'src/app/core/model/opjs.model';
import { Documento } from 'src/app/core/model/token.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { OpjService } from 'src/app/core/services/opj.service';
import { CuentaJudicialService } from 'src/app/core/services/cuentaJudicial.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  infoCuenta,
  destinoCuenta,
  ConceptoOpj,
} from 'src/app/core/model/cuenta.model';
import { Coelsa } from 'src/app/core/model/coelsa.model';
import { AgendaService } from 'src/app/core/services/agenda.service';
import { IfStmt } from '@angular/compiler';
import { Agenda } from 'src/app/core/model/agenda.model';
import { MatTableDataSource } from '@angular/material/table';
import { WindowUtils } from 'msal';

@Component({
  selector: 'app-opj',
  templateUrl: './opj.component.html',
  styleUrls: ['./opj.component.css'],
})
export class OpjComponent implements OnInit {
  today: Date = new Date();  
  formAltaOpj: FormGroup;
  formBeneficiario: FormGroup;
  formCuentaDestino: FormGroup;
  cbuOrigen: number;
  formConcepto: FormGroup;
  cuentaOrigen: infoCuenta;
  registroError: string;
  tipoTransferencia: TipoTransferencias[];
  tipoOpj: TipoOpj[];
  circunscripcion: any[];
  listTipodocumento: Documento[];
  listConceptos: Concepto[];
  agendaJudicial: Agenda[];
  itemAgenda: number;
  itemConceptoOpj: ConceptoOpj[] = [];
  destinatario: Coelsa;
  cuitValid = false;
  flagCircuscripcion = false;
  erorrCBU = '';
  cuitFacultadoValid = false;
  beneficiario: any;
  titularCBU: any;
  facultado: any;
  isBeneficiario = false;
  isFacultado = false;
  isDestinarioLoad = false;
  isTributo = false;
  isOtroTitular = false;
  failBenef = false;
  destCbu = true;
  dataSource = new MatTableDataSource([]);
  bloquear = false;
  esErrorAfip: boolean = false; 


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private opjService: OpjService,
    private agendaService: AgendaService,
    private benefService: BeneficiarioService,
    private cuentaService: CuentaJudicialService,
    private activatedRoute: ActivatedRoute,
    private matSnackBar: MatSnackBar
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.cbuOrigen = params.cbu;
      this.cuentaService.getInfoCuenta(this.cbuOrigen).subscribe(
        (data) => {
          this.cuentaOrigen = data;
        },
        (err) => {
          console.log(err);
        }
      );
    });

    // buscamos los tipos de OPJ
    this.opjService.ListarTiposOpjs().subscribe((data: TipoOpj[]) => {
      this.tipoOpj = data;
    });

    // this.opjService.ListarTipoTransferencias()
    // .subscribe((data: TipoTransferencias[]) =>
    //   {
    //     this.tipoTransferencia = data;
    //   });

    this.cuentaService.getTipoDocumento().subscribe((data: Documento[]) => {
      this.listTipodocumento = data;
    });
  }

  agregarConcepto() {
    // Validamos que no se ingreso el mismo concepto
    const idConcept = this.formConcepto.value.idConcepto;

    // Validamos si selecciono concepto
    if (idConcept === -1) {
      this.matSnackBar.open('Seleccione un concepto de la lista', 'Ok', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }

    if (
      this.itemConceptoOpj.filter((item) => item.idConcepto === idConcept)
        .length === 0
    ) {
      const concepto = this.listConceptos.find(
        (f) => f.codigoConcepto === idConcept
      ).descripcion;
      const itemContepto = {
        importe: +this.formConcepto.value.importe,
        despcipcion: concepto,
        idConcepto: this.formConcepto.value.idConcepto,
      };

      this.itemConceptoOpj.push(itemContepto);

      // Limpiamos el formulario

      const init = { idConcepto: -1, importe: '' };
      this.formConcepto.patchValue(init);
    } else {
      this.matSnackBar.open('El concepto ingresado ya existe', 'Ok', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  applyFilter(event: Event) {
    /*const filterValue = (event.target as HTMLInputElement).value;
    this.agendaJudicial = this.agendaJudicial.filter(f => f.aliasAgenda === filterValue.trim().toLowerCase());

    if (filterValue === '')
    {
      this.agendaJudicial = this.dataSource.data;
    }*/
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarItem(idConcepto) {
    this.itemConceptoOpj = this.itemConceptoOpj.filter(
      (item) => item.idConcepto !== idConcepto
    );
  }

  get getImporteTotal(): number {
    let impTot = 0;

    for (let i = 0; i < this.itemConceptoOpj.length; i++) {
      impTot += this.itemConceptoOpj[i].importe;
    }

    return impTot;
    // return this.itemConceptoOpj
    //   .reduce((sum: number, b: ConceptoOpj) => sum + (+b.importe), 0);
  }

  get resumenOpj(): ResumenOPJ {
    let cbu = null;
    let ntitular = null;
    if (this.destinatario) {
      cbu = this.destinatario.cuenta.nro_cbu;
      if (this.titularCBU) {
        ntitular = this.titularCBU.nombre;
      } else {
        ntitular = '';
      }
    }

    if (
      this.formAltaOpj.value.tipoOPJ !== '' &&
      this.formAltaOpj.value.tipoTransferncia !== ''
    ) {
      const topj = this.tipoOpj.find(
        (f) => f.idTipoOpj === this.formAltaOpj.value.tipoOPJ
      );
      const tTrans = this.tipoTransferencia.find(
        (f) => f.id === this.formAltaOpj.value.tipoTransferncia
      );

      const resumen = {
        CtaOrigen: this.cuentaOrigen.cuenta,
        TipoOPJ: topj ? topj.descripcion : '',
        TipoTransf: tTrans ? tTrans.descripcion : '',
        cbu,
        titular: ntitular,
        importe: this.getImporteTotal,
      };

      return resumen;
    } else {
      return new ResumenOPJ();
    }
  }

  selectCircunscripciones(item) {
    // buscamos los tipos de OPJ
    this.isTributo = true;
    this.opjService
      .ListarTiposOpjsContribucion(item)
      .subscribe((data: TipoOpj) => {
        var Newtipos: TipoOpj[] = [];
        Newtipos.push(data);
        this.tipoOpj = Newtipos;
        this.buscarCbu(data.cbuDestino.replace('-', ''));
      });
  }

  selectTipoOpj(item) {
    // buscamos las sucursales
    const selectTipoOpj = this.tipoOpj.find((f) => f.idTipoOpj === item);

    //Verificamos que el tipo de opj es contribucion:

    if (selectTipoOpj.idTipoOpj === 2) {
      this.flagCircuscripcion = true;
      this.isDestinarioLoad = false;
      // buscamos los tipos de OPJ
      this.opjService.ListarCircunscripcionOpjs().subscribe((data: any[]) => {
        this.circunscripcion = data;
        var Circcontrol = this.formAltaOpj.controls['OPJcircunscripciones'];
        Circcontrol.setValidators(Validators.compose([Validators.required]));
        Circcontrol.updateValueAndValidity();
      });
    } else {
      var Circcontrol = this.formAltaOpj.controls['OPJcircunscripciones'];
      Circcontrol.setValidators(Validators.compose([]));
      Circcontrol.updateValueAndValidity();
      this.flagCircuscripcion = false;
      this.isDestinarioLoad = true;
    }

    this.listConceptos = selectTipoOpj.conceptos;
    if (selectTipoOpj.cbuDestino && selectTipoOpj.idTipoOpj !== 2) {
      this.isTributo = true;
      this.buscarCbu(selectTipoOpj.cbuDestino.replace('-', ''));
    } else {
      this.isTributo = false;
    }

    this.tipoTransferencia = selectTipoOpj.tipoTransferenciasPermitidas;
    this.itemConceptoOpj = [];
    this.formAltaOpj.patchValue({
      tipoTransferncia: '',
    });
    this.destinatario = null;
    this.isDestinarioLoad = false;
    const respCbu = {
      tipoDocumento: 1,
      nroDocumento: '',
      nombre: '',
    };
    this.formBeneficiario.patchValue(respCbu);
  }

  selectTipoTransferencia(tipoTranf) {
    let soloBPN = false;
    if (tipoTranf === FormaPagoOPJ.transferenciaBPN) {
      this.isBeneficiario = true;
      this.isOtroTitular = false;
      this.isFacultado = false;
      soloBPN = true;
      if (!this.isTributo) {
        this.isDestinarioLoad = true;
      }
    }

    if (tipoTranf === FormaPagoOPJ.cobroCaja) {
      this.isBeneficiario = true;
      this.isFacultado = true;
      this.isOtroTitular = false;
      this.isDestinarioLoad = false;
    }

    if (tipoTranf === FormaPagoOPJ.otrosBancos) {
      this.isBeneficiario = true;
      this.isFacultado = true;
      this.isOtroTitular = true;
      this.isDestinarioLoad = true;
    }

    if (this.isDestinarioLoad) {
      this.agendaService.getListaAgenda(soloBPN).subscribe((agen) => {
        this.agendaJudicial = agen;
        this.dataSource = new MatTableDataSource(this.agendaJudicial);
        console.log(agen);
      });
    }
  }

  selectAgenda(item: Agenda) {
    console.log(item);
    this.itemAgenda = item.id;
    this.destinatario = item.aliasCBU;
    const respCbu = {
      tipoDocumento: 1,
      nroDocumento: item.aliasCBU.titular.cuit,
      nombre: item.aliasCBU.titular.nombre,
    };

    this.titularCBU = respCbu;
    this.formBeneficiario.patchValue(respCbu);
  }


    
  buscarBeneficiario(item: string): void {
    // Reseteamos el estado cada vez que el CUIT cambia
    this.resetCuitStatus();  
  
    if (this.isValidCuit(item)) {
      this.benefService.buscarCuitAfip(item).subscribe(
        (data) => {
          if (this.isCuitValid(data.codigoError)) {
            this.setBeneficiario(data); // Si el CUIT es válido, actualizamos el beneficiario
          } else {
            this.handleError(data.codigoError); // Si no es válido, manejamos el error
          }
        },
        (err) => this.handleRequestError(err) // Manejo de errores de conexión
      );
    }
  }
  
  
  private setBeneficiario(data: any): void {
    this.esErrorAfip = false; // Inicializamos la variable para determinar si es error de AFIP
    let nombreCompleto = '';
    let condicionImpositiva = '';
    let esBeneficiarioValido = false;
  
    const procesarDatosPersona = () => {
      if (data.datosGenerales.apellido || data.datosGenerales.nombre) {
        // Caso de persona física
        const apellido = data.datosGenerales.apellido || '';
        const nombre = data.datosGenerales.nombre || '';
        nombreCompleto = `${apellido}${nombre ? ', ' + nombre : ''}`;
        return true;
      } else if (data.datosGenerales.razonSocial) {
        // Caso de persona jurídica
        nombreCompleto = data.datosGenerales.razonSocial;
        return true;
      }
      return false;
    };
  
    if (data.codigoError === 0) {
      esBeneficiarioValido = procesarDatosPersona();
      condicionImpositiva = data.descripcionCondicionAfip;
      if (!esBeneficiarioValido) {
        nombreCompleto = 'No disponible';
      }
    } else if (data.codigoError === 20) {
      if (procesarDatosPersona()) {
        esBeneficiarioValido = true;
        condicionImpositiva = data.descripcionCondicionAfip;
      } else {
        condicionImpositiva = data.mensajeError || 'No se pudo obtener el nombre del beneficiario';
        this.esErrorAfip = true;
        nombreCompleto = 'No disponible';
      }
    } else {
      // Para otros códigos de error, mantenemos el comportamiento actual
      nombreCompleto = 'No disponible';
      condicionImpositiva = data.mensajeError || 'Error al obtener datos del beneficiario';
      this.esErrorAfip = true;
    }
  
    this.beneficiario = { 
      apellido: nombreCompleto, 
      nombre: '', 
      condicionImpositiva: condicionImpositiva 
    };
  
    this.cuitValid = esBeneficiarioValido;
    this.failBenef = !esBeneficiarioValido;
  }
  
  
  
  
  private isValidCuit(cuit: string): boolean {
    return cuit && cuit.length === 11;
  }
  
  private isCuitValid(codigoError: number): boolean {
    return codigoError === 0 || codigoError === 20;
  }
  

  
  private handleError(codigoError: number): void {
    let mensaje = '';
  
    if (codigoError === -1) {
      mensaje = 'No se puede verificar el CUIT en este momento. Ocurrió un error con AFIP.';
    } else if (codigoError === -3) {
      mensaje = 'Ocurrió un error en el sistema. Por favor, intente más tarde.';
    }
  
    this.beneficiario = { 
      apellido: 'No disponible', 
      nombre: '', 
      condicionImpositiva: mensaje 
    };
  
    this.cuitValid = false;
    this.failBenef = true;  // Indicamos que hubo un error
  }
  
  private handleRequestError(err: any): void {
    let mensajeError = '';
    this.esErrorAfip = false; // Inicializamos para errores que no son de AFIP
  
    // Si el error es un 404 o código de error -2, significa que el CUIT no existe.
    if (err.status === 404 || err.error?.codigoErrorAfip === -2) {
      mensajeError = 'El CUIT ingresado no existe.';
      this.cuitValid = false;
      this.failBenef = true;  // Indicamos que hubo un error de CUIT no encontrado
    } 
    // Si el error es de AFIP (-1), mostramos el mensaje adecuado.
    else if (err.error?.codigoErrorAfip === -1) {
      mensajeError = 'No se puede verificar el CUIT en este momento. Ocurrió un error con AFIP.';
      this.esErrorAfip = true; // Error de AFIP
      this.cuitValid = false;
      this.failBenef = true;  // Indicamos que hubo un error de AFIP
    } 
    // Si el error es del sistema (-3), mostramos el mensaje adecuado.
    else if (err.error?.codigoErrorAfip === -3) {
      mensajeError = 'Ocurrió un error en el sistema. Por favor, intente más tarde.';
      this.esErrorAfip = true; // Error de sistema
      this.cuitValid = false;
      this.failBenef = true;  // Indicamos que hubo un error general en el sistema
    } 
    // Si el error es por otro motivo o un error inesperado.
    else {
      mensajeError = 'El CUIT ingresado no es válido.';
      this.cuitValid = false;
      this.failBenef = true;
    }
  
    // Asignamos los valores de error al objeto beneficiario.
    this.beneficiario = {
      apellido: 'No disponible',
      nombre: '',
      condicionImpositiva: mensajeError
    };
  }
  
  
  
  
  
  private resetCuitStatus(): void {
    // Al cambiar el CUIT reseteamos todo para evitar mensajes previos incorrectos
    this.beneficiario = { apellido: '', nombre: '', condicionImpositiva: '' };
    this.cuitValid = false;
    this.failBenef = false;
  }

  buscarFacultado(item) {
    if (item) {
      if (item.length === 11) {
        this.benefService.buscarCuitAfip(item).subscribe(
          (data) => {
            const benef = {
              apellido: data.datosGenerales.apellido,
              nombre: data.datosGenerales.nombre,
            };

            this.facultado = benef;
            this.cuitFacultadoValid = true;
          },
          (err) => {
            this.cuitFacultadoValid = false;
          }
        );
      } else {
        this.cuitFacultadoValid = false;
      }
    }
  }

  buscarCbu(cbu) {
    if (cbu.length === 22) {
      this.erorrCBU = null;
      const reqCoelsa = {
        alias: { valor: null },
        cuenta: { nro_cbu: cbu },
      };

      this.opjService.getCuentaDestino(reqCoelsa).subscribe(
        (data: Coelsa) => {
          this.destinatario = data;
          this.erorrCBU = '';
          ////// cargamos de forma automatica el destinatario
          if (data.titulares.length === 1) {
            const respCbu = {
              tipoDocumento: 1,
              nroDocumento: this.destinatario.titulares[0].cuit,
              nombre: this.destinatario.titulares[0].nombre,
            };

            this.titularCBU = respCbu;
            this.formBeneficiario.patchValue(respCbu);
          }
        },
        (error) => {
          this.erorrCBU = 'No se pudo encontrar el CBU ingresado';
        }
      );

      this.cuitValid = true;
    } else {
      this.cuitValid = false;
    }
  }

  buscarAlias(alias) {
    if (alias.length > 4) {
      this.erorrCBU = null;
      const reqCoelsa = {
        alias: { valor: alias },
        cuenta: { nro_cbu: null },
      };

      this.opjService.getCuentaDestino(reqCoelsa).subscribe(
        (data: Coelsa) => {
          this.destinatario = data;
          this.erorrCBU = '';
          /// cargamos de forma automatica el destinatario
          if (data.titulares.length === 1) {
            const respCbu = {
              tipoDocumento: 1,
              nroDocumento: this.destinatario.titulares[0].cuit,
              nombre: this.destinatario.titulares[0].nombre,
            };

            this.titularCBU = respCbu;
            this.formBeneficiario.patchValue(respCbu);
          }
        },
        (error) => {
          this.erorrCBU = 'No se pudo encontrar el CBU ingresado';
        }
      );

      this.cuitValid = true;
    } else {
      this.cuitValid = false;
    }
  }

  selectTitutalCbu(item) {
    const filtro = this.destinatario.titulares.find((f) => f.cuit === item);
    if (filtro) {
      const respCbu = {
        tipoDocumento: 1,
        nroDocumento: filtro.cuit,
        nombre: filtro.nombre,
      };

      this.titularCBU = respCbu;
      this.formBeneficiario.patchValue(respCbu);
    }
  }

  saveRegister(event: Event) {
    event.preventDefault();
    let cargoFacultado = null;

    if (this.formBeneficiario.value.facultado) {
      if (this.cuitFacultadoValid) {
        cargoFacultado = {
          tipoDocumento: 1,
          numeroDocumento: this.formBeneficiario.value.documentofacultado,
        };
      } else {
        this.registroError = 'Debe especificar un cuit valido del facultado';
        return;
      }
    }

    this.bloquear = true;
    let istitulaCbu = null;
    let isCbu = null;
    if (this.isDestinarioLoad || this.isTributo) {
      if (this.formBeneficiario.value.otroTitular) {
        istitulaCbu = {
          tipoDocumento: 1,
          numeroDocumento: this.titularCBU.nroDocumento,
        };
      }
      if (!this.destinatario) {
        this.bloquear = false;
        this.registroError = 'Debe especificar un destinatario';
        return;
      }

      isCbu = this.destinatario.cuenta.nro_cbu;
    }

    const nuevaOpj = {
      tipoOPJ: +this.formAltaOpj.value.tipoOPJ,
      tipoTransferncia: +this.formAltaOpj.value.tipoTransferncia,
      cbuOrigen: this.cuentaOrigen.cbu,
      cbuDestino: isCbu,
      comentarios: this.formConcepto.value.comentario,
      beneficiario: {
        tipoDocumento: 1,
        numeroDocumento: this.formBeneficiario.value.nroDocumento,
      },
      titularCBU: istitulaCbu,
      facultado: cargoFacultado,
      conceptos: this.itemConceptoOpj,
    };

    this.opjService.nuevaOpj(nuevaOpj).subscribe(
      (rel) => {
        // Guardamos los datos en la agenda
        if (this.formCuentaDestino.value.guardaAgenda) {
          const agendaSave = new Agenda();
          agendaSave.aliasCBU = this.destinatario;
          agendaSave.nroDoc = this.titularCBU.nroDocumento;
          agendaSave.mail = this.formCuentaDestino.value.email;
          agendaSave.aliasAgenda = this.formCuentaDestino.value.denominacion;
          (agendaSave.aliasCBU.titular.cuit = this.titularCBU.nroDocumento),
            (agendaSave.aliasCBU.titular.nombre = this.titularCBU.nombre);

          this.agendaService.guardarAgenda(agendaSave).subscribe(
            (data) => {
              this.mostrarMensaje();
            },
            (error) => {
              this.matSnackBar
                .open('Orden de Pago Judicial Creada Correctamente', 'Ok', {
                  duration: 4000,
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                })
                .afterDismissed()
                .subscribe(() => {
                  this.matSnackBar
                    .open(error.error.detalle, 'Ok', {
                      duration: 4000,
                      horizontalPosition: 'center',
                      verticalPosition: 'bottom',
                    })
                    .afterDismissed()
                    .subscribe(() => {
                      //this.router.navigate(['./cuentas/cuentas-judiciales']);
                      location.reload();
                    });
                });
            }
          );
        } else {
          this.mostrarMensaje();
        }
      },
      (err) => {
        this.registroError = err.error.detalle;
        this.bloquear = false;
      }
    );
  }

  mostrarMensaje() {
    this.matSnackBar
      .open('Orden de Pago Judicial Creada Correctamente', 'Ok', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      })
      .afterDismissed()
      .subscribe(() => {
        //this.router.navigate(['./cuentas/cuentas-judiciales']);
        location.reload();
      });
  }

  private buildForm() {
    this.formAltaOpj = this.formBuilder.group({
      tipoOPJ: ['', [Validators.required]],
      OPJcircunscripciones: [''],
      tipoTransferncia: ['', [Validators.required]],
    });

    this.formBeneficiario = this.formBuilder.group({
      nroDocumento: ['', [Validators.required]],
      facultado: [''],
      documentofacultado: [''],
      otroTitular: [''],
    });

    this.formCuentaDestino = this.formBuilder.group({
      tipoDest: ['true', [Validators.required]],
      cbuDestino: [''],
      titular: [''],
      aliasDestino: [''],
      guardaAgenda: [''],
      denominacion: [''],
      email: [''],
    });

    this.formConcepto = this.formBuilder.group({
      idConcepto: [''],
      comentario: [''],
      importe: [''],
    });
  }
}
