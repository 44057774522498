import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  TipoTransferencias,
  TipoOpj,
  Coordenadas,
  ModelOpjs,
  NuevaTarjCoord,
} from '../model/opjs.model';
import { environment } from 'src/environments/environment';
import { Coelsa } from '../model/coelsa.model';
import { Observable } from 'rxjs';
import { GetionOpj, Estado } from '../model/gestion-opj.model';
import { FilterCuentas } from '../model/cuenta.model';
import { OpjSegmento } from '../model/opj-admin.model';

@Injectable({
  providedIn: 'root',
})
export class OpjService {
  constructor(private http: HttpClient) {}

  ListarTipoTransferencias() {
    return this.http.get<TipoTransferencias[]>(
      `${environment.apiServiceBaseUri}/TipoTransferencias`
    );
  }

  ListarTiposOpjs() {
    return this.http.get<TipoOpj[]>(
      `${environment.apiServiceBaseUri}/TiposOpjs`
    );
  }

  ListarTiposOpjsContribucion(idCircunscripcion) {
    return this.http.get<TipoOpj>(
      `${environment.apiServiceBaseUri}/TiposOpjs/2/${idCircunscripcion}`
    );
  }

  ListarCircunscripcionOpjs() {
    return this.http.get<any[]>(
      `${environment.apiServiceBaseUri}/Circunscripciones/extendida`
    );
  }

  getEstadoOpjs() {
    return this.http.get<Estado[]>(
      `${environment.apiServiceBaseUri}/EstadosOpjs`
    );
  }

  getCuentaDestino(modelo) {
    return this.http.post<Coelsa>(
      `${environment.apiServiceBaseUri}/Coelsa/AliasCBU`,
      modelo
    );
  }

  nuevaOpj(opj) {
    return this.http.post(`${environment.apiServiceBaseUri}/Opjs/`, opj);
  }

  ImprimirMultiple(array) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/Opjs/comprobante/`,
      array,
      { responseType: 'blob' }
    );
  }

  EditarOpj(idOpj, opj) {
    return this.http.put(
      `${environment.apiServiceBaseUri}/Opjs/Editar/${idOpj}/`,
      opj
    );
  }

  getOpjs(
    juzgado: string,
    page: number,
    orderColumn: string,
    orderByColumn: string
  ): Observable<ModelOpjs> {
    let param = '';
    if (juzgado !== '') {
      param += `juzgado=${juzgado}&`;
    }
    const requestUrl = `${
      environment.apiServiceBaseUri
    }/Opjs?${param}RegXPag=30&nroPag=${
      page + 1
    }&orderColumn=${orderColumn}&orderByColumn=${orderByColumn}`;
    return this.http.get<ModelOpjs>(requestUrl);
  }

  getOpjsSubFilter(
    juzgado: string,
    filter: FilterCuentas,
    page: number,
    orderColumn: string,
    orderByColumn: string
  ): Observable<ModelOpjs> {
    let param = '';
    if (filter.caratula) {
      param += `juzgado=${filter.caratula.juzgado}&caratula=${filter.caratula.carat}`;
    }

    if (filter.expediente) {
      param += `juzgado=${filter.expediente.juzgado}&expediente=${filter.expediente.exp}`;
    }

    if (filter.CBU) {
      param += `cbu=${filter.CBU}`;
    }

    if (filter.OPJE) {
      param += `idOpj=${filter.OPJE}`;
    }

    if (filter.cta) {
      param += `juzgado=${filter.juzgado}&cta=${filter.cta}&subcta=${filter.subCta}`;
    }

    if (filter.operador) {
      param += `operador=${filter.operador}`;
    }

    let requestUrl = '';
    if (param === '') {
      if (juzgado !== '') {
        param += `juzgado=${juzgado}&`;
      }
      requestUrl = `${
        environment.apiServiceBaseUri
      }/Opjs?${param}RegXPag=30&nroPag=${
        page + 1
      }&orderColumn=${orderColumn}&orderByColumn=${orderByColumn}`;
    } else {
      requestUrl = `${
        environment.apiServiceBaseUri
      }/Opjs?${param}&RegXPag=30&nroPag=${
        page + 1
      }&orderColumn=${orderColumn}&orderByColumn=${orderByColumn}`;
    }
    return this.http.get<ModelOpjs>(requestUrl);
  }

  getOpjSegmento(page: number): Observable<OpjSegmento> {
    const requestUrl = `${
      environment.apiServiceBaseUri
    }/AdminSegOficial/opjs?regXPag=30&nroPag=${page + 1}`;
    return this.http.get<OpjSegmento>(requestUrl);
  }

  getOpjsFilter(
    filter: FilterCuentas,
    estado: string,
    page: number
  ): Observable<ModelOpjs> {
    let param = '';
    if (filter.caratula) {
      param += `juzgado=${filter.caratula.juzgado}&caratula=${filter.caratula.carat}`;
    }

    if (filter.expediente) {
      param += `juzgado=${filter.expediente.juzgado}&expediente=${filter.expediente.exp}`;
    }

    if (filter.CBU) {
      param += `cbu=${filter.CBU}`;
    }

    if (filter.OPJE) {
      param += `idOpj=${filter.OPJE}`;
    }

    if (filter.cta) {
      param += `juzgado=${filter.juzgado}&cta=${filter.cta}&subcta=${filter.subCta}`;
    }

    if (filter.operador) {
      param += `operador=${filter.operador}`;
    }

    if (estado) {
      param += `juzgado=${filter.juzgado}&estado=${estado}`;
    }

    const requestUrl = `${
      environment.apiServiceBaseUri
    }/Opjs?RegXPag=30&nroPag=${page + 1}&${param}`;
    return this.http.get<ModelOpjs>(requestUrl);
  }

  getOpjsAutorizar(
    juzgado: string,
    page: number,
    orderColumn: string,
    orderByColumn: string
  ): Observable<ModelOpjs> {
    const requestUrl = `${
      environment.apiServiceBaseUri
    }/Opjs?estado=1&juzgado=${juzgado}&RegXPag=30&nroPag=${
      page + 1
    }&orderColumn=${orderColumn}&orderByColumn=${orderByColumn}`;
    return this.http.get<ModelOpjs>(requestUrl);
  }

  getOpjsAutorizarFilter(
    filter: FilterCuentas,
    page: number
  ): Observable<ModelOpjs> {
    let param = '';
    if (filter.caratula) {
      param += `juzgado=${filter.caratula.juzgado}&caratula=${filter.caratula.carat}`;
    }

    if (filter.expediente) {
      param += `juzgado=${filter.expediente.juzgado}&expediente=${filter.expediente.exp}`;
    }

    if (filter.CBU) {
      param += `cbu=${filter.CBU}`;
    }

    if (filter.OPJE) {
      param += `idOpj=${filter.OPJE}`;
    }

    if (filter.cta) {
      param += `juzgado=${filter.juzgado}&cta=${filter.cta}&subcta=${filter.subCta}`;
    }
    const requestUrl = `${
      environment.apiServiceBaseUri
    }/Opjs?estado=1&RegXPag=15&nroPag=${page + 1}&${param}`;
    return this.http.get<ModelOpjs>(requestUrl);
  }

  getCoordenada() {
    return this.http.get<Coordenadas>(
      `${environment.apiServiceBaseUri}/TarjetaCoordenadas/GenerarCoordenadas`
    );
  }

  getBTOnline() {
    return this.http.get<boolean>(
      `${environment.apiServiceBaseUri}/Schemas/isCambioDeFecha`
    );
  }

  getEstadoCoordenada() {
    return this.http.get<any>(
      `${environment.apiServiceBaseUri}/TarjetaCoordenadas/`
    );
  }

  isValidCoordenada(model: Coordenadas) {
    return this.http.get(
      `${environment.apiServiceBaseUri}/TarjetaCoordenadas/isValidCoordenadas/${model.coord1}/${model.coord2}/${model.valorCoord1}/${model.valorCoord2}`
    );
  }

  nuevaTarjeta(modelo: NuevaTarjCoord) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/TarjetaCoordenadas/`,
      modelo
    );
  }

  AutorizarOpj(opjs) {
    return this.http.put(`${environment.apiServiceBaseUri}/Opjs/`, opjs);
  }
}
