<form [formGroup]="formCuentaDestino" class="formulario">
  <mat-card>
    <mat-card-header>
      <mat-card-title> Solicitar Asociacion de Cuenta </mat-card-title>
      <mat-error *ngIf="this.registroError != ''">
        <br />
        {{ this.registroError }}
      </mat-error>
    </mat-card-header>
    <mat-card-content>
      <br />

      <mat-radio-group
        formControlName="tipoAsociacion"
        class="radio-group"
        aria-label="Ingrese Cbu o Alias"
      >
        <mat-radio-button value="cuenta" checked>Cuenta</mat-radio-button>
        <mat-radio-button class="item-radio" value="cbu">CBU</mat-radio-button>
        <mat-radio-button class="item-radio" value="expediente"
          >Expendiente</mat-radio-button
        >
        <mat-radio-button class="item-radio" value="caratula"
          >Caratula</mat-radio-button
        >
      </mat-radio-group>

      <div *ngIf="formCuentaDestino.value.tipoAsociacion === 'cuenta'">
        <br />
        <div class="row">
          <div class="col-sm-2">
            <mat-form-field class="input-width-cuenta" appearance="fill">
              <mat-label>Cuenta</mat-label>
              <input matInput autofocus formControlName="cuenta" />
            </mat-form-field>
          </div>
          <div>
            <h3><b>/</b></h3>
          </div>
          <div class="col-sm-1">
            <mat-form-field class="input-width-cuenta" appearance="fill">
              <mat-label>Sub Cuenta</mat-label>
              <input matInput autofocus formControlName="subcuenta" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="formCuentaDestino.value.tipoAsociacion === 'cbu'">
        <br />
        <mat-form-field class="input-width" appearance="fill">
          <mat-label>CBU</mat-label>
          <input
            matInput
            mask="00000000-00000000000000"
            autofocus
            formControlName="cbu"
          />
        </mat-form-field>
      </div>

      <div *ngIf="formCuentaDestino.value.tipoAsociacion === 'expediente'">
        <br />
        <!-- <mat-form-field class="input-width" appearance="fill">
                        <mat-label>Juzgado</mat-label>
                        <mat-select formControlName="juzgado" >
                          <mat-option *ngFor="let item of this.listJuzgado" [value]="item.id" >{{item.id + ' - ' + item.descripcion}}</mat-option>
                        </mat-select>
                      </mat-form-field> -->

        <mat-form-field class="input-autocomplete" appearance="fill">
          <mat-label>Juzgado</mat-label>
          <input
            type="text"
            placeholder="Seleccione Juzgado"
            matInput
            formControlName="juzgado"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              class="option-autocomplete"
              *ngFor="let option of filteredOptions | async"
              [value]="option.id"
            >
              <span class="option-autocomplete-cod">{{ option.id }}</span>
              {{ " - " + option.descripcion }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <br />
        <mat-form-field class="input-width" appearance="fill">
          <mat-label>Expediente</mat-label>
          <input matInput formControlName="expediente" />
        </mat-form-field>
      </div>

      <div *ngIf="formCuentaDestino.value.tipoAsociacion === 'caratula'">
        <br />
        <mat-form-field class="input-autocomplete" appearance="fill">
          <mat-label>Juzgado</mat-label>
          <input
            type="text"
            placeholder="Seleccione Juzgado"
            matInput
            formControlName="juzgado"
            [matAutocomplete]="auto2"
          />
          <mat-autocomplete #auto2="matAutocomplete">
            <mat-option
              class="option-autocomplete"
              *ngFor="let option of filteredOptions | async"
              [value]="option.id"
            >
              <span class="option-autocomplete-cod">{{ option.id }}</span>
              {{ " - " + option.descripcion }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <br />
        <mat-form-field class="input-width" appearance="fill">
          <mat-label>Caratula</mat-label>
          <input matInput autofocus formControlName="caratula" />
        </mat-form-field>
      </div>

      <button mat-raised-button color="primary" (click)="buildData()">
        Buscar <mat-icon>search</mat-icon>
      </button>

      <!-- Listamos los datos de la tabla  -->

      <div *ngIf="isGrillaVisible">
        <table
          mat-table
          [dataSource]="dataSource"
          class="full-width-table"
          matSort
          aria-label="Elements"
        >
          <!-- cuenta Column -->
          <ng-container matColumnDef="cuenta">
            <th mat-header-cell *matHeaderCellDef>Nro Cuenta</th>
            <td mat-cell *matCellDef="let element">
              <b>{{ element.cuenta }}</b>
            </td>
          </ng-container>

          <!-- cbu Column -->
          <ng-container matColumnDef="cbu">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>CBU</th>
            <td
              mat-cell
              *matCellDef="let element"
              [matTooltip]="element.cbu"
              matTooltipPosition="above"
            >
              XXXX {{ element.cbu.substring(0, 4) }}
            </td>
          </ng-container>

          <!-- caratula Column -->
          <ng-container matColumnDef="caratula">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Caratula</th>
            <td mat-cell *matCellDef="let element">{{ element.caratula }}</td>
          </ng-container>

          <!-- expediente Column -->
          <ng-container matColumnDef="expediente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Expediente
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [matTooltip]="element.expediente"
              matTooltipPosition="above"
            >
              {{ element.expediente.substring(0, 5) }}...
            </td>
          </ng-container>

          <!-- saldoStr Column -->
          <ng-container matColumnDef="nombreJuzgado">
            <th mat-header-cell *matHeaderCellDef>Juzgado</th>
            <td mat-cell *matCellDef="let element">
              <b color="warn"> {{ element.nombreJuzgado }} </b>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let row">
              <mat-icon
                *ngIf="row.isRestringida"
                matTooltip="Cuenta Reservada"
                matTooltipPosition="above"
                style="color: rgb(196, 109, 109)"
                >lock</mat-icon
              >
              <button
                *ngIf="!row.isRestringida"
                mat-icon-button
                color="primary"
                [matMenuTriggerFor]="menu"
                aria-label="Acciones"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  *ngFor="let item of row.acciones"
                  mat-menu-item
                  (click)="ejecutar(item)"
                >
                  <mat-icon [ngClass]="item.cssColor">{{
                    item.icono
                  }}</mat-icon>
                  <span>{{ item.titulo }}</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            [ngClass]="{ warn: element.isRestringida }"
            *matRowDef="let element; columns: displayedColumns"
          ></tr>
        </table>

        <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
      </div>
    </mat-card-content>
    <mat-card-actions *ngIf="isValid">
      <button
        mat-stroked-button
        color="warn"
        type="button"
        [routerLink]="['/cuentas/']"
      >
        Cancelar
      </button>
      <button
        [disabled]="formCuentaDestino.invalid"
        mat-raised-button
        color="primary"
        type="submit"
      >
        Guardar
      </button>
    </mat-card-actions>
  </mat-card>
</form>
