import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Coordenadas, estadoOpj } from 'src/app/core/model/opjs.model';
import { OpjService } from 'src/app/core/services/opj.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CustomDialogComponent,
  DialogData,
} from 'src/app/custom-dialog/custom-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-opj-anular',
  templateUrl: './opj-anular.component.html',
  styleUrls: ['./opj-anular.component.css'],
})
export class OpjAnularComponent implements OnInit {
  @ViewChild('inputB') coordB: ElementRef;
  formAutorizar: FormGroup;
  coordenadas: Coordenadas = new Coordenadas();
  isInvalidCoordenada: string;
  isAutorizando: boolean;

  constructor(
    public dialogRef: MatDialogRef<CustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataRef: DialogData,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private opjService: OpjService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.opjService.getEstadoCoordenada().subscribe(
      (data) => {
        if (data.idEstado === 1) {
          this.isInvalidCoordenada = null;
          this.opjService.getCoordenada().subscribe((coord) => {
            this.coordenadas = coord;
          });
        } else {
          this.isInvalidCoordenada = data.descripcionEstado;
        }
      },
      (err) => {
        this.isInvalidCoordenada = err;
      }
    );
  }

  nextItemCoord(item) {
    if (item.length === 2) {
      this.coordB.nativeElement.focus();
    }
  }

  validarCoordenadas(num) {
    if (num.length === 2) {
      this.coordenadas.valorCoord1 = +this.formAutorizar.value.coordA;
      this.coordenadas.valorCoord2 = +this.formAutorizar.value.coordB;
      this.opjService.isValidCoordenada(this.coordenadas).subscribe(
        (resp) => {
          this.isInvalidCoordenada = null;
        },
        (error) => {
          this.formAutorizar.get('coordA').setErrors({
            incorrect: true,
          });
          this.formAutorizar.get('coordB').setErrors({
            incorrect: true,
          });

          this.isInvalidCoordenada = 'Coordenadas invalidas';
        }
      );
    }
  }

  AnularOpj(event) {
    this.opjService.getBTOnline().subscribe((data) => {
      if (!data) {
        // Seteamos el valor de las coordenadas
        const arrray = [+this.dataRef];
        this.coordenadas.valorCoord1 = +this.formAutorizar.value.coordA;
        this.coordenadas.valorCoord2 = +this.formAutorizar.value.coordB;
        this.isAutorizando = true;

        const autorizar = {
          opjs: arrray,
          estado: estadoOpj.Anular,
          coordenadas: this.coordenadas,
          motivo: this.formAutorizar.value.comentario,
        };

        this.opjService.AutorizarOpj(autorizar).subscribe(
          (data) => {
            this.isAutorizando = false;
            this.matSnackBar.open('Opj Anulada correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            });
            this.dialogRef.close();
          },
          (err) => {
            this.isAutorizando = false;
            console.log(err);
          }
        );
      } else {
        this.matSnackBar.open(
          'En estos momentos no es posible realizar la acción solicitada. Por favor reintente en unos minutos',
          'Ok',
          {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );
      }
    });
  }

  private buildForm() {
    this.formAutorizar = this.formBuilder.group({
      coordA: ['', [Validators.required]],
      coordB: ['', [Validators.required]],
      comentario: ['', [Validators.required]],
    });
  }
}
