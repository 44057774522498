<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2 class="titulo">Editar perfiles usuario</h2>
    </mat-card-title>

    <mat-error *ngIf="this.registroError != ''">
      <br />
      {{ this.registroError }}
    </mat-error>
  </mat-card-header>
  <mat-card-content style="text-align: left">
    <mat-divider></mat-divider>
    <br />
    <h3 class="margen">
      DNI: <b>{{ this.userInfo.nroDoc }}</b>
    </h3>
    <h3 class="margen">
      <b>{{ this.userInfo.apellido + "," + this.userInfo.nombre }}</b>
    </h3>

    <p class="titulo margen">Roles Asignados:</p>
    <mat-list role="list">
      <mat-list-item *ngFor="let item of perfiles" role="listitem"
        ><button
          color="warn"
          mat-icon-button
          (click)="quitarPerfil(item.idRol, item.descripcion)"
        >
          <mat-icon>delete_sweep</mat-icon>
        </button>
        {{ item.descripcion }}
      </mat-list-item>
    </mat-list>
  </mat-card-content>
  <mat-card-actions style="text-align: right">
    <button mat-stroked-button color="warn" type="button" mat-dialog-close>
      Cancelar
    </button>
  </mat-card-actions>
</mat-card>
