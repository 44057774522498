import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Roles } from 'src/app/core/model/tipo-usuario.model';
import { Juzgado, Organismo } from 'src/app/core/model/organismo.model';
import { Circunscripcion, TokenModel } from 'src/app/core/model/token.model';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { CambioCuentasService } from 'src/app/core/services/cambio-cuentas.service';
import { AccountService } from 'src/app/core/services/account.service';
import { TokenService } from 'src/app/core/services/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SolicitudCambioOrganismo } from 'src/app/core/model/solicitud-cambio-organismo.model';
import {
  CambioCuenta,
  SolicitudCambioCuenta,
} from 'src/app/core/model/solicitud-cambio-cuenta.model';

@Component({
  selector: 'app-editar-solicitud-cuenta',
  templateUrl: './editar-solicitud-cuenta.component.html',
  styleUrls: ['./editar-solicitud-cuenta.component.css'],
})
export class EditarSolicitudCuentaComponent implements OnInit {
  form: FormGroup;
  registroError: string;
  hide = true;
  tipoRoles: Roles[];
  listJuzgado: Juzgado[];
  listOrganizmo: Organismo[];
  listCircuns: Circunscripcion[];
  panelAbogado: boolean;
  panelJuzgado: boolean;
  id: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private cambioService: CambioCuentasService,
    private accountService: AccountService,
    private tokenService: TokenService,
    private matSnackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params.id;
      const modelUser: TokenModel = this.tokenService.getToken();
      // buscamos los perfiles
      this.cambioService
        .getSolicitud(this.id)
        .subscribe((solicitud: SolicitudCambioCuenta) => {
          // buscamos los perfiles
          this.accountService
            .getTipoUsuario()
            .subscribe((tipoUsuario: Roles[]) => {
              this.tipoRoles = tipoUsuario;
              // buscamos los organizmos
              this.accountService
                .getOrganizmo()
                .subscribe((orga: Organismo[]) => {
                  this.listOrganizmo = orga;
                  // buscamos las circuscripciones
                  this.accountService
                    .getCircuscripcion()
                    .subscribe((circuns: any[]) => {
                      this.listCircuns = circuns;
                      const user = {
                        documento:
                          modelUser.documento.descripcion +
                          ' ' +
                          modelUser.nroDocumento,
                        perfil: modelUser.roles[0].descripcion,
                        idOrganismo: solicitud.organismo,
                        juzgadoTitular: solicitud.juzgadoTitular,
                        matricula: solicitud.matriculaNro,
                        idCircunscripcion: solicitud.circunscripcion,
                        idRol: solicitud.perfil.idRol,
                        detalle: solicitud.detalle,
                      };

                      const rol = this.tipoRoles.find(
                        (f) => f.idRol === solicitud.perfil.idRol
                      );
                      if (rol.tsj) {
                        this.panelAbogado = false;
                        this.panelJuzgado = true;
                      } else {
                        this.panelAbogado = true;
                        this.panelJuzgado = false;
                      }
                      this.form.patchValue(user);
                    });
                });
            });
        });
    });
  }

  selectPerfil(perfil) {
    const rol = this.tipoRoles.find((f) => f.idRol === perfil);
    if (rol.tsj) {
      this.panelAbogado = false;
      this.panelJuzgado = true;
    } else {
      this.panelAbogado = true;
      this.panelJuzgado = false;
    }
  }

  selectJuzgado(item) {
    this.listJuzgado = this.listOrganizmo.find((f) => f.id === item).juzgados;
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      /*Validamos que completo los datos de abogado o juzgado*/
      const solicitud: CambioCuenta = {
        perfilSolicitado: this.form.value.idRol,
        estadoSolicitud: 1,
        juzgadoTitular: this.form.value.juzgadoTitular,
        circunscripcion: this.form.value.idCircunscripcion,
        organismo: this.form.value.idOrganismo,
        matriculaNro: this.form.value.matricula,
        cuit: this.form.value.cuit,
        detalle: this.form.value.observacion,
      };

      this.cambioService.editarSolicitud(this.id, solicitud).subscribe(
        (newRegistro) => {
          console.log(newRegistro);
          this.matSnackBar
            .open('Solicitud enviada correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            })
            .afterDismissed()
            .subscribe(() => {
              this.router.navigate(['./cuenta/mis-solicitudes']);
            });
        },
        (err) => {
          console.log(err);
          this.registroError = err.error.detalle;
        }
      );
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      documento: [''],
      perfil: [''],
      observacion: ['', [Validators.required]],
      idRol: [null, [Validators.required]],
      idCircunscripcion: null,
      matricula: '',
      cuit: '',
      juzgadoTitular: '',
      idOrganismo: null,
    });
  }
}
