import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { OpjService } from 'src/app/core/services/opj.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { GetionOpj, Estado } from 'src/app/core/model/gestion-opj.model';
import { TokenModel } from 'src/app/core/model/token.model';
import { AccionesService } from 'src/app/core/services/acciones.service';
import { TokenService } from 'src/app/core/services/token.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { merge, of as observableOf, Observable } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { Acciones, FilterCuentas } from 'src/app/core/model/cuenta.model';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModelOpjs } from 'src/app/core/model/opjs.model';
import { OpjAnularComponent } from '../opj-anular/opj-anular.component';
import { TipoPerfil } from 'src/app/core/model/tipo-usuario.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from 'src/app/admin/dialog/dialog.component';

@Component({
  selector: 'app-gestion-opj',
  templateUrl: './gestion-opj.component.html',
  styleUrls: ['./gestion-opj.component.css'],
})
export class GestionOpjComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<ModelOpjs>;

  resultsLength = 0;
  autorizarOpj = false;
  filteredOptions: Observable<Estado[]>;
  listEstado: Estado[];
  formAutorizar: FormGroup;
  juzActual: string;
  isLoadingResults = true;
  formFiltro: FormGroup;
  isRateLimitReached = false;
  selection = new SelectionModel<GetionOpj>(true, []);
  registros: GetionOpj;
  userInfo: TokenModel;
  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'select',
    'idOpj',
    'caratula',
    'expediente',
    'cuitBeneficiario',
    'cbuDestino',
    'operador',
    'montoOPJStr',
    'tipo',
    'fechaAlta',
    'estado',
    'actions',
  ];

  diccionario = {
    caratula: 'Caratula',
    expediente: 'Expdte',
    cuitBeneficiario: 'NroDoc',
    cbuDestino: 'CbuDestino',
    operador: 'Operador',
    montoOPJStr: 'montoOPJ',
    estado: 'Estado',
  };

  constructor(
    private opjService: OpjService,
    private accionService: AccionesService,
    private dialogConfirm: MatDialog,
    private tokenService: TokenService,
    private matSnackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.buildForm();
  }

  ngAfterViewInit(): void {
    if (!this.sort) {
      return;
    }

    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          const filter = new FilterCuentas();

          if (this.formFiltro.value.tipoAsociacion === 'caratula') {
            filter.caratula = {
              juzgado: this.juzgadoDefault,
              carat: this.formFiltro.value.caratula,
            };
          }

          if (this.formFiltro.value.tipoAsociacion === 'expediente') {
            filter.expediente = {
              juzgado: this.juzgadoDefault,
              exp: this.formFiltro.value.expediente,
            };
          }

          if (this.formFiltro.value.tipoAsociacion === 'opje') {
            filter.OPJE = this.formFiltro.value.opje;
          }

          if (this.formFiltro.value.tipoAsociacion === 'cbu') {
            filter.CBU = this.formFiltro.value.cbu;
          }

          //Agregamos el filtro de operador
          if (this.formFiltro.value.tipoAsociacion === 'operador') {
            filter.operador = this.formFiltro.value.operador;
          }

          if (this.formFiltro.value.tipoAsociacion === 'cuenta') {
            filter.juzgado = this.juzgadoDefault;
            filter.subCta = this.formFiltro.value.subcuenta;
            filter.cta = this.formFiltro.value.cuenta;
          }

          filter.juzgado = this.juzgadoDefault;
          let valueSort = this.diccionario[this.sort.active];

          if (valueSort === undefined) {
            valueSort = 'FechaAlta';
            this.sort.direction = this.sort.direction
              ? this.sort.direction
              : 'desc';
          }

          return this.opjService.getOpjsSubFilter(
            this.juzgadoDefault,
            filter,
            this.paginator.pageIndex,
            valueSort,
            this.sort.direction
          );
        }),
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.totalRegistros;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((opjs: ModelOpjs) => {
        this.dataSource = new MatTableDataSource(opjs.opjs);
      });
  }

  ngOnInit(): void {
    this.userInfo = this.tokenService.getToken();
    this.juzActual = this.juzgadoDefault;
    this.opjService.getEstadoOpjs().subscribe((data) => {
      this.listEstado = data;
    });

    this.filteredOptions = this.formFiltro.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value.estado))
    );
  }

  private _filter(value: string): Estado[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.listEstado.filter((option) =>
        option.descripcion.toLowerCase().includes(filterValue)
      );
    } else {
      return this.listEstado;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  get juzgadoDefault(): string {
    if (
      this.userInfo.roles.find((f) => f.idRol === TipoPerfil.Admin_Colegio) ||
      this.userInfo.roles.find((f) => f.idRol === TipoPerfil.Abogado)
    ) {
      return '';
    }

    const juz = localStorage.getItem(`juzgado${this.userInfo.nroDocumento}`);
    if (this.juzActual !== juz) {
      this.juzActual = juz;
      this.ngAfterViewInit();
    }

    if (juz) {
      return juz;
    } else {
      return this.userInfo.juzgadoDefault.id;
    }
  }

  ejecutar(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      this.dialogConfirm
        .open(OpjAnularComponent, {
          data: accion.body,
        })
        .afterClosed()
        .subscribe((data) => {
          this.ngAfterViewInit();
        });
    } else {
      if (accion.schemaJson === 'redirect') {
        this.router.navigate([accion.url]);
      } else {
        this.accionService.customAccion(accion);
      }
    }
  }

  buildData() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          const filter = new FilterCuentas();

          if (this.formFiltro.value.tipoAsociacion === 'caratula') {
            filter.caratula = {
              juzgado: this.juzgadoDefault,
              carat: this.formFiltro.value.caratula,
            };
          }

          if (this.formFiltro.value.tipoAsociacion === 'expediente') {
            filter.expediente = {
              juzgado: this.juzgadoDefault,
              exp: this.formFiltro.value.expediente,
            };
          }

          if (this.formFiltro.value.tipoAsociacion === 'opje') {
            filter.OPJE = this.formFiltro.value.opje;
          }

          if (this.formFiltro.value.tipoAsociacion === 'cbu') {
            if (!this.formFiltro.value.cbu) {
              this.matSnackBar.open('Ingrese un cbu valido', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              return;
            }
            filter.CBU = this.formFiltro.value.cbu;
          }

          //Agregamos el filtro de operador
          if (this.formFiltro.value.tipoAsociacion === 'operador') {
            if (!this.formFiltro.value.operador) {
              this.matSnackBar.open(
                'Ingrese un nombre de operador valido',
                'Ok',
                {
                  duration: 3000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                }
              );
              return;
            }
            filter.operador = this.formFiltro.value.operador;
          }

          if (this.formFiltro.value.tipoAsociacion === 'cuenta') {
            if (!this.formFiltro.value.cuenta) {
              this.matSnackBar.open('Ingrese una cuenta valida ', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              return;
            }
            filter.juzgado = this.juzgadoDefault;
            filter.subCta = this.formFiltro.value.subcuenta;
            filter.cta = this.formFiltro.value.cuenta;
          }

          filter.juzgado = this.juzgadoDefault;

          return this.opjService.getOpjsFilter(
            filter,
            this.formFiltro.value.estado,
            this.paginator.pageIndex
          );
        }),
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.totalRegistros;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((opjs: ModelOpjs) => {
        this.dataSource = new MatTableDataSource(opjs.opjs);
      });
  }

  checkboxLabel(row?: GetionOpj): string {
    if (row.process) {
      if (!this.selection.isSelected(row)) {
        row.process.error = null;

        row.process.icon = null;
      }
    }

    // Establecemos de manera fija la cantidad de items a imprimir
    if (this.selection.selected.length > 10) {
      this.matSnackBar.open('No se pueden seleccionar mas de 10 items', 'Ok', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      this.selection.deselect(row);
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.idOpj + 1
    }`;
  }

  imprimirItems(event) {
    const arrray = [];

    this.selection.selected.forEach((item) => {
      arrray.push(item.idOpj);
      console.log(arrray);
    });

    // Crear funcion para solicitar confirmacion y luego peticionar descartar los documentos
    this.dialogConfirm
      .open(DialogComponent, {
        data: `¿Esta seguro de descargar los ${arrray.length} items seleccionados  ?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.opjService.ImprimirMultiple(arrray).subscribe((data: any) => {
            console.log(data);
            const blob: any = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          });
        }
      });
  }

  limpiarBusqueda() {
    debugger;
    const filter = {
      tipoAsociacion: this.formFiltro.value.tipoAsociacion,
      cuenta: '',
      subcuenta: '',
      cbu: '',
      juzgado: '',
      estado: '',
      opje: '',
      operador: '',
      expediente: '',
      caratula: '',
    };
    this.formFiltro.patchValue(filter);
    this.ngAfterViewInit();
  }

  private buildForm() {
    this.formFiltro = this.formBuilder.group({
      tipoAsociacion: ['expediente', [Validators.required]],
      cuenta: [''],
      subcuenta: [''],
      cbu: [''],
      estado: [''],
      juzgado: [''],
      expediente: [''],
      opje: [''],
      caratula: [''],
      operador: [''],
    });
  }
}
