<h1 class="mat-h1">
  Movimientos de la Cuenta N°
  <b>{{ this.cuenta.toString() + "/" + this.subcta.toString() }}</b>
</h1>
<form [formGroup]="formFiltro">
  <div class="row">
    <div class="col-sm-3">
      <mat-form-field color="accent" appearance="fill">
        <mat-label>Fecha desde</mat-label>
        <input
          matInput
          [matDatepicker]="picker1"
          type="text"
          (dateChange)="this.refrescarGrilla()"
          formControlName="desde"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1 color="primary"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-sm-3">
      <mat-form-field color="accent" appearance="fill">
        <mat-label>Fecha Hasta</mat-label>
        <input
          matInput
          [matDatepicker]="picker2"
          type="text"
          (dateChange)="this.refrescarGrilla()"
          formControlName="hasta"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2 color="primary"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-sm-3">
      <button
        mat-raised-button
        color="primary"
        style="margin-top: 10px"
        (click)="imprimirReporte()"
      >
        <span class="material-icons">print</span> Imprimir Reporte
      </button>
    </div>
  </div>
</form>
<!-- <div>[disabled]="formFiltro.invalid"
    <mat-form-field>
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Buscar">
    </mat-form-field>
</div> -->

<table
  mat-table
  [dataSource]="dataSource"
  class="full-width-table"
  matSort
  aria-label="Elements"
>
  <!-- cuenta Column -->
  <ng-container matColumnDef="fecha">
    <th mat-header-cell *matHeaderCellDef>Fecha</th>
    <td mat-cell *matCellDef="let element">
      <b>{{ element.fecha }}</b>
    </td>
  </ng-container>

  <!-- cbu Column -->
  <ng-container matColumnDef="concepto">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Concepto</th>
    <td mat-cell *matCellDef="let element">{{ element.concepto }}</td>
  </ng-container>

  <!-- caratula Column -->
  <ng-container matColumnDef="referencia">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Referencia</th>
    <td mat-cell *matCellDef="let element">{{ element.referencia }}</td>
  </ng-container>

  <!-- expediente Column -->
  <ng-container matColumnDef="importe">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Importe</th>
    <td mat-cell *matCellDef="let element">
      <b>{{ element.importe }}</b>
    </td>
  </ng-container>

  <!-- saldoStr Column -->
  <ng-container matColumnDef="saldo">
    <th mat-header-cell *matHeaderCellDef>Saldo</th>
    <td mat-cell *matCellDef="let element">{{ element.saldo }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    [ngClass]="{ warn: element.debito }"
    *matRowDef="let element; columns: displayedColumns"
  ></tr>
</table>

<mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
