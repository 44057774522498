<div class="row">
  <div class="col-lg-6">
    <form [formGroup]="form" (ngSubmit)="saveRegister($event)">
      <mat-card>
        <mat-card-header>
          <mat-card-title> Solicitud Cambio de Cuenta </mat-card-title>
          <br />
          <mat-error *ngIf="this.registroError != ''">
            {{ this.registroError }}
          </mat-error>
        </mat-card-header>
        <mat-card-content style="text-align: center">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Nro Documento</mat-label>
            <input readonly matInput type="text" formControlName="documento" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Perfil Actual</mat-label>
            <input readonly matInput formControlName="perfil" />
          </mat-form-field>
          <br />
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Nuevo Perfil</mat-label>
            <mat-select
              formControlName="idRol"
              (selectionChange)="selectPerfil($event.value)"
            >
              <mat-option
                *ngFor="let item of this.tipoRoles"
                [value]="item.idRol"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <br />
          <div *ngIf="this.panelAbogado">
            <!-- Debera seleccionar circuscripcion demo DEVOPS-->
            <mat-form-field class="input-width" appearance="fill">
              <mat-label>*Matricula</mat-label>
              <input matInput type="text" formControlName="matricula" />
            </mat-form-field>
            <br />
            <mat-form-field class="input-width" appearance="fill">
              <mat-label>*CUIT/CUIL</mat-label>
              <input matInput type="text" formControlName="cuit" />
            </mat-form-field>
            <br />
            <mat-form-field class="input-width" appearance="fill">
              <mat-label>*Circunscripcion</mat-label>
              <mat-select formControlName="idCircunscripcion">
                <mat-option
                  *ngFor="let item of this.listCircuns"
                  [value]="item.id"
                  >{{ item.descripcion }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <br />
          </div>
          <div *ngIf="this.panelJuzgado">
            <!-- Debera seleccionar un organizmo -->
            <mat-form-field class="input-width" appearance="fill">
              <mat-label>*Organismo</mat-label>
              <mat-select
                formControlName="idOrganismo"
                (selectionChange)="selectJuzgado($event.value)"
              >
                <mat-option
                  *ngFor="let item of this.listOrganizmo"
                  [value]="item.id"
                  >{{ item.descripcion }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <br />
            <!-- Debera seleccionar Juzgado -->
            <mat-form-field class="input-width" appearance="fill">
              <mat-label>*Juzgado Titular</mat-label>
              <mat-select formControlName="juzgadoTitular">
                <mat-option
                  *ngFor="let item of this.listJuzgado"
                  [value]="item.id"
                  >{{ item.descripcion }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <br />
          </div>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>*Observacion</mat-label>
            <textarea
              matInput
              placeholder="ingrese alguna observacion"
              formControlName="observacion"
            ></textarea>
          </mat-form-field>
          <mat-error> *Campos requeridos </mat-error>
        </mat-card-content>
        <mat-card-actions style="text-align: right">
          <button
            mat-stroked-button
            color="warn"
            type="button"
            [routerLink]="['/']"
          >
            Cancelar
          </button>
          <button
            [disabled]="form.invalid"
            mat-raised-button
            color="primary"
            type="submit"
          >
            Enviar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</div>
