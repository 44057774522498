import { Component, OnInit } from '@angular/core';
import { CircuscripcionService } from 'src/app/core/services/circuscripcion.service';
import { SolicitudCircunscripcion } from 'src/app/core/model/solicitud-circuscripcion.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AccionesService } from 'src/app/core/services/acciones.service';

@Component({
  selector: 'app-pendiente-cambio-circuscripcion',
  templateUrl: './pendiente-cambio-circuscripcion.component.html',
  styleUrls: ['./pendiente-cambio-circuscripcion.component.css'],
})
export class PendienteCambioCircuscripcionComponent implements OnInit {
  registros: SolicitudCircunscripcion[];
  registroError: string;
  dataSource = new MatTableDataSource(this.registros);
  displayedColumns = [
    'documento',
    'nombre',
    'circunscripcion',
    'matriculaNro',
    'fechaSolicitud',
    'estado',
    'actions',
  ];

  constructor(
    private circService: CircuscripcionService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private accionService: AccionesService
  ) {
    this.accionService.badgeResultado$.subscribe((data) => {
      this.buidGrid();
    });
  }

  ngOnInit(): void {
    this.buidGrid();
  }

  buidGrid() {
    this.circService.MisPendientes().subscribe((reg) => {
      this.registros = reg;
      this.dataSource = new MatTableDataSource(this.registros);
    });
  }

  ejecutar(accion) {
    this.accionService.customAccion(accion);
  }
}
