<div class="grid-container">
  <h1 class="mat-h1">Solicitudes Pendientes de cambio</h1>
  <mat-error *ngIf="this.registroError != ''">
    <br />
    {{ this.registroError }}
  </mat-error>
  <div class="mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSource"
      class="full-width-table"
      aria-label="Elements"
    >
      <!-- Id Column -->
      <ng-container matColumnDef="documento">
        <th mat-header-cell *matHeaderCellDef>Nro Documento</th>
        <td mat-cell *matCellDef="let row">
          {{
            row.usuario.documento.descripcion + " " + row.usuario.nroDocumento
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef>Nombre</th>
        <td mat-cell *matCellDef="let row">
          {{ row.usuario.apellido + ", " + row.usuario.nombre }}
        </td>
      </ng-container>

      <ng-container matColumnDef="circunscripcion">
        <th mat-header-cell *matHeaderCellDef>Circunscripcion Solicitada</th>
        <td mat-cell *matCellDef="let row">
          {{ row.circunscripcion.descripcion }}
        </td>
      </ng-container>

      <!-- nombre Column -->
      <ng-container matColumnDef="matriculaNro">
        <th mat-header-cell *matHeaderCellDef>Matricula</th>
        <td mat-cell *matCellDef="let row">{{ row.matriculaNro }}</td>
      </ng-container>

      <!-- DOC Column -->
      <ng-container matColumnDef="fechaSolicitud">
        <th mat-header-cell *matHeaderCellDef>Fec. Solicitud</th>
        <td mat-cell *matCellDef="let row">
          {{ row.fechaSolicitud | date: "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef>Estado</th>
        <td mat-cell *matCellDef="let row">
          <b>{{ row.estado.descripcion }} </b>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Acciones</th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            color="primary"
            *ngIf="row.estado.puedeEditar"
            [matMenuTriggerFor]="menu"
            aria-label="Acciones"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              *ngFor="let item of row.acciones"
              mat-menu-item
              (click)="ejecutar(item)"
            >
              <mat-icon [ngClass]="item.cssColor">{{ item.icono }}</mat-icon>
              <span>{{ item.titulo }}</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
