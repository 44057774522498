<div class="grid-container">
  <h1 class="mat-h1">Solicitud de acceso</h1>
  <mat-form-field>
    <mat-label>Filtrar</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
  </mat-form-field>
  <div class="mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSource"
      class="full-width-table"
      matSort
      aria-label="Elements"
    >
      <!-- Id Column -->
      <ng-container matColumnDef="tipoDocumento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Doc</th>
        <td mat-cell *matCellDef="let row">{{ row.tipoDocumento }}</td>
      </ng-container>

      <!-- DOC Column -->
      <ng-container matColumnDef="nroDocumento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nro Doc</th>
        <td mat-cell *matCellDef="let row">{{ row.nroDocumento }}</td>
      </ng-container>

      <!-- nombre Column -->
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Nombre Completo
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.nombre + ", " + row.apellido }}
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let row">{{ row.email }}</td>
      </ng-container>

      <!-- Perfil Column -->
      <ng-container matColumnDef="rol">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Perfil</th>
        <td mat-cell *matCellDef="let row">
          <b> {{ row.rol.descripcion }} </b>
        </td>
      </ng-container>

      <!-- Organismo Column -->
      <ng-container matColumnDef="organismo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Organismo</th>
        <td mat-cell *matCellDef="let row">
          <b> {{ row.organismo ? row.organismo.descripcion : "" }} </b>
        </td>
      </ng-container>

      <!-- fechaSol Column -->
      <ng-container matColumnDef="fechaSol">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Reg.</th>
        <td mat-cell *matCellDef="let row">{{ row.fechaAlta }}</td>
      </ng-container>

      <!--<ng-container matColumnDef="actions">
             <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let row"> 
              <a mat-raised-button (click)="habilitar(row)">Habilitar</a>
              <a mat-raised-button color="warn"(click)="bloquear(row)">Bloquear</a>
            </td> 
        </ng-container>-->

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Acciones</th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            color="primary"
            [matMenuTriggerFor]="menu"
            aria-label="Acciones"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="habilitar(row)">
              <mat-icon class="material-icons color_green"
                >done_outline</mat-icon
              >
              <span>Habilitar</span>
            </button>
            <button mat-menu-item (click)="bloquear(row)">
              <mat-icon color="warn">clear</mat-icon>
              <span>Rechazar</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      #paginator
      [length]="dataSource?.data.length"
      [pageIndex]="0"
      [pageSize]="25"
      [pageSizeOptions]="[25, 50, 100, 250]"
    >
    </mat-paginator>
  </div>
</div>
