import { Injectable } from '@angular/core';
import { Roles } from '../../core/model/tipo-usuario.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserInfo, Perfiles } from '../model/user-info.model';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  constructor(private http: HttpClient) {}

  getUsuario(id: string) {
    // Falta Metodo para cambiar clave , nombre y demas
    return this.http.get(
      `${environment.apiServiceBaseUri}/usuario/getUsuario/${id}`
    );
  }

  getInfoUsuario() {
    return this.http.get<UserInfo>(
      `${environment.apiServiceBaseUri}/usuario/getInfoUsuario`
    );
  }

  createUsuarioRoot(registro) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/Usuarios/registrarUsuario`,
      registro
    );
  }

  editUsuario(id: string, registro: any) {
    // Filtrador por perfil administracion
    return this.http.put(
      `${environment.apiServiceBaseUri}/usuario/EditUsuario/${id}`,
      registro
    );
  }

  EditUsuarioSession(registro: any) {
    // Falta Metodo para cambiar clave , nombre y demas
    return this.http.put(
      `${environment.apiServiceBaseUri}/usuarios/`,
      registro
    );
  }

  quitarPerfilUsuario(tipoDoc, nroDoc, idPerfil) {
    return this.http.delete(
      `${environment.apiServiceBaseUri}/Perfiles/${tipoDoc}/${nroDoc}/${idPerfil}`
    );
  }

  getPerfilesUsuario(tipoDoc, nroDoc) {
    return this.http.get<Perfiles[]>(
      `${environment.apiServiceBaseUri}/Perfiles/${tipoDoc}/${nroDoc}`
    );
  }

  inactivarUsuario(id: string) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/usuario/InactivarUsuario/${id}`,
      ''
    );
  }

  getUsuarioRoot() {
    return this.http.get(
      `${environment.apiServiceBaseUri}/usuario/listUsuariosRoot`
    );
  }
}
