import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  CuentaJudicial,
  ModelCuentasJudicial,
  infoCuenta,
  FilterCuentas,
} from '../model/cuenta.model';
import { TiposExpedientes, Sucursales, Sectores } from '../model/juzgado.model';
import { Juzgado } from '../model/organismo.model';
import { Documento } from '../model/token.model';
import { Observable } from 'rxjs';
import { MovimientoCuentas } from '../model/movimientos.model';
import { OpjModel } from '../model/opjs.model';

@Injectable({
  providedIn: 'root',
})
export class CuentaJudicialService {
  constructor(private http: HttpClient) {}

  ListarCuentasJudiciales() {
    // Este perfil es para el supervisor de organizmo o administrador
    return this.http.get<CuentaJudicial[]>(
      `${environment.apiServiceBaseUri}/Juzgados/Cuentas?idJuzgado=CSMTF&RegXPag=15&NroPag=1`
    );
  }

  getTipoExpediente() {
    return this.http.get<TiposExpedientes[]>(
      `${environment.apiServiceBaseUri}/TiposExpedientes`
    );
  }

  getSucursales(id: string) {
    return this.http.get<Sucursales[]>(
      `${environment.apiServiceBaseUri}/Sucursales/${id}`
    );
  }

  getJuzgadoFamilia() {
    return this.http.get<Juzgado[]>(
      `${environment.apiServiceBaseUri}/Juzgados/Juzgados_SNFamilia`
    );
  }

  getTipoDocumento() {
    return this.http.get<Documento[]>(
      `${environment.apiServiceBaseUri}/TiposDocumentos/TiposDocumentoBT`
    );
  }

  getSectores() {
    return this.http.get<Sectores[]>(
      `${environment.apiServiceBaseUri}/Sectores/`
    );
  }

  altaCuentaJudicial(cuenta) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/CuentasJudiciales/AltaCuentaJudicial/`,
      cuenta
    );
  }

  relCuentaBeneficiario(modelo) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/CuentasJudiciales/AltaRelCuentaJudicialBeneficiario/`,
      modelo
    );
  }

  getCuentas(
    juzgado: string,
    page: number,
    orderColumn: string,
    orderByColumn: string
  ): Observable<ModelCuentasJudicial> {
    const requestUrl = `${
      environment.apiServiceBaseUri
    }/CuentasJudiciales/BuscarCuentas?juzgado=${juzgado}&RegXPag=15&Pag=${
      page + 1
    }&orderColumn=${orderColumn}&orderByColumn=${orderByColumn}`;
    return this.http.get<ModelCuentasJudicial>(requestUrl);
  }

  getCuentasSubFilter(
    juzgado: string,
    filter: FilterCuentas,
    page: number,
    orderColumn: string,
    orderByColumn: string
  ): Observable<ModelCuentasJudicial> {
    let param = '';
    if (filter.caratula) {
      param += `juzgado=${filter.caratula.juzgado}&caratula=${filter.caratula.carat}`;
    }

    if (filter.expediente) {
      param += `juzgado=${filter.expediente.juzgado}&expediente=${filter.expediente.exp}`;
    }

    if (filter.CBU) {
      param += `cbu=${filter.CBU}`;
    }

    if (filter.cta) {
      param += `juzgado=${filter.juzgado}&cta=${filter.cta}&subcta=${filter.subCta}`;
    }

    let requestUrl = '';
    if (param !== '') {
      requestUrl = `${
        environment.apiServiceBaseUri
      }/CuentasJudiciales/BuscarCuentas?${param}&RegXPag=15&Pag=${
        page + 1
      }&orderColumn=${orderColumn}&orderByColumn=${orderByColumn}`;
    } else {
      requestUrl = `${
        environment.apiServiceBaseUri
      }/CuentasJudiciales/BuscarCuentas?juzgado=${juzgado}&RegXPag=15&Pag=${
        page + 1
      }&orderColumn=${orderColumn}&orderByColumn=${orderByColumn}`;
    }

    return this.http.get<ModelCuentasJudicial>(requestUrl);
  }

  getCuentasFilter(
    filter: FilterCuentas,
    page: number
  ): Observable<ModelCuentasJudicial> {
    let param = '';
    if (filter.caratula) {
      param += `juzgado=${filter.caratula.juzgado}&caratula=${filter.caratula.carat}`;
    }

    if (filter.expediente) {
      param += `juzgado=${filter.expediente.juzgado}&expediente=${filter.expediente.exp}`;
    }

    if (filter.CBU) {
      param += `cbu=${filter.CBU}`;
    }

    if (filter.cta) {
      param += `juzgado=${filter.juzgado}&cta=${filter.cta}&subcta=${filter.subCta}`;
    }

    const requestUrl = `${
      environment.apiServiceBaseUri
    }/CuentasJudiciales/BuscarCuentas?${param}&RegXPag=15&Pag=${page + 1}`;
    return this.http.get<ModelCuentasJudicial>(requestUrl);
  }

  getReportePDFCuentas(filter: FilterCuentas, page: number) {
    let param = '';
    if (filter.caratula) {
      param += `juzgado=${filter.caratula.juzgado}&caratula=${filter.caratula.carat}`;
    }

    if (filter.expediente) {
      param += `juzgado=${filter.expediente.juzgado}&expediente=${filter.expediente.exp}`;
    }

    if (filter.CBU) {
      param += `cbu=${filter.CBU}`;
    }

    if (filter.cta) {
      param += `juzgado=${filter.juzgado}&cta=${filter.cta}&subcta=${filter.subCta}`;
    }

    if (param === '') param += `juzgado=${filter.juzgado}`;

    const requestUrl = `${
      environment.apiServiceBaseUri
    }/CuentasJudiciales/BuscarCuentasPDF?${param}&RegXPag=15&Pag=${
      page + 1
    }&orderColumn=CuentaNro&orderByColumn=asc`;
    return this.http.get(requestUrl, { responseType: 'blob' });
  }

  getComprobanteAltaCuenta(idCuenta) {
    const requestUrl = `${environment.apiServiceBaseUri}/CuentasJudiciales/comprobante/${idCuenta}`;
    return this.http.get(requestUrl, { responseType: 'blob' });
  }

  getCuentasAsociar(
    filter: FilterCuentas,
    page: number
  ): Observable<ModelCuentasJudicial> {
    let param = '';
    if (filter.caratula) {
      param += `juzgado=${filter.caratula.juzgado}&caratula=${filter.caratula.carat}`;
    }

    if (filter.expediente) {
      param += `juzgado=${filter.expediente.juzgado}&expediente=${filter.expediente.exp}`;
    }

    if (filter.CBU) {
      param += `cbu=${filter.CBU}`;
    }

    if (filter.cta) {
      param += `cta=${filter.cta}&subcta=${filter.subCta}`;
    }

    const requestUrl = `${
      environment.apiServiceBaseUri
    }/CuentasJudiciales/BuscarCuentas?${param}&RegXPag=15&Pag=${page + 1}`;
    return this.http.get<ModelCuentasJudicial>(requestUrl);
  }

  getMovimientos(
    cta: string,
    subcta: string,
    fechaDesde: string,
    fechaHasta: string,
    page: number
  ): Observable<MovimientoCuentas> {
    const requestUrl = `${
      environment.apiServiceBaseUri
    }/CuentasJudiciales/GetMovimientos?cta=${cta}&subcta=${subcta}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&RegXPag=20&Pag=${
      page + 1
    }`;
    return this.http.get<MovimientoCuentas>(requestUrl);
  }

  getImprimirMovimientos(
    cta: string,
    subcta: string,
    fechaDesde: string,
    fechaHasta: string,
    regTotal: number
  ) {
    const requestUrl = `${environment.apiServiceBaseUri}/CuentasJudiciales/GetMovimientosPDF?cta=${cta}&subcta=${subcta}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&RegXPag=${regTotal}&Pag=1`;
    return this.http.get(requestUrl, { responseType: 'blob' });
  }

  getInfoCuenta(cbu) {
    const requestUrl = `${environment.apiServiceBaseUri}/CuentasJudiciales/GetInfoCuenta/${cbu}`;
    return this.http.get<infoCuenta>(requestUrl);
  }

  getOpj(idOpj) {
    const requestUrl = `${environment.apiServiceBaseUri}/Opjs/${idOpj}`;
    return this.http.get<OpjModel>(requestUrl);
  }
}
