import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/admin/dialog/dialog.component';
import { ModeloValidacion } from 'src/app/core/model/organismo.model';

@Component({
  selector: 'app-alerta-cambios',
  templateUrl: './alerta-cambios.component.html',
  styleUrls: ['./alerta-cambios.component.css'],
})
export class AlertaCambiosComponent implements OnInit {
  constructor(
    public dialogo: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public mensaje: ModeloValidacion[]
  ) {
    console.log(mensaje);
  }

  cerrarDialogo(): void {
    this.dialogo.close(false);
  }
  confirmado(): void {
    this.dialogo.close(true);
  }

  ngOnInit() {}
}

// 1171950002
