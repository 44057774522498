import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AccountService } from 'src/app/core/services/account.service';
import { Juzgado } from 'src/app/core/model/juzgado.model';
import { JuzgadoService } from 'src/app/core/services/juzgado.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  ModelCuentasJudicial,
  FilterCuentas,
  Acciones,
} from 'src/app/core/model/cuenta.model';
import { merge, of as observableOf, Observable } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { CuentaJudicialService } from 'src/app/core/services/cuentaJudicial.service';
import { AccionesService } from 'src/app/core/services/acciones.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-solicitar-cuenta',
  templateUrl: './solicitar-cuenta.component.html',
  styleUrls: ['./solicitar-cuenta.component.css'],
})
export class SolicitarCuentaComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<ModelCuentasJudicial>;

  formCuentaDestino: FormGroup;
  registroError: string;
  listJuzgado: Juzgado[];
  isValid = false;
  filteredOptions: Observable<Juzgado[]>;
  resultsLength = 0;
  isLoadingResults = true;
  isGrillaVisible = true;
  isRateLimitReached = false;
  registros: ModelCuentasJudicial;
  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'cuenta',
    'cbu',
    'caratula',
    'expediente',
    'nombreJuzgado',
    'actions',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private cuentasService: CuentaJudicialService,
    private accionService: AccionesService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private juzgadoServ: JuzgadoService
  ) {
    this.buildForm();
    this.accionService.badgeResultado$.subscribe((registros) => {
      this.buildData();
    });
  }

  ngOnInit(): void {
    this.juzgadoServ.getJuzgados().subscribe((data) => {
      this.listJuzgado = data;
    });

    this.filteredOptions = this.formCuentaDestino.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value.juzgado))
    );
  }

  ngAfterViewInit(): void {}

  ejecutar(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      this.router.navigate([accion.url]);
    } else {
      this.accionService.customAccion(accion);
    }
  }

  buildData() {
    this.isGrillaVisible = true;
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          const filter = new FilterCuentas();

          if (this.formCuentaDestino.value.tipoAsociacion === 'caratula') {
            if (!this.formCuentaDestino.value.juzgado) {
              this.matSnackBar.open('Seleccione un juzgado destino', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              return;
            }
            filter.caratula = {
              juzgado: this.formCuentaDestino.value.juzgado,
              carat: this.formCuentaDestino.value.caratula,
            };
          }

          if (this.formCuentaDestino.value.tipoAsociacion === 'expediente') {
            if (!this.formCuentaDestino.value.juzgado) {
              this.matSnackBar.open('Seleccione un juzgado destino', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              return;
            }

            filter.expediente = {
              juzgado: this.formCuentaDestino.value.juzgado,
              exp: this.formCuentaDestino.value.expediente,
            };
          }

          if (this.formCuentaDestino.value.tipoAsociacion === 'cbu') {
            if (!this.formCuentaDestino.value.cbu) {
              this.matSnackBar.open('Ingrese un cbu valido', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              return;
            }
            filter.CBU = this.formCuentaDestino.value.cbu;
          }

          if (this.formCuentaDestino.value.tipoAsociacion === 'cuenta') {
            if (!this.formCuentaDestino.value.cuenta) {
              this.matSnackBar.open('Ingrese una cuenta valida ', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              return;
            }
            filter.subCta = this.formCuentaDestino.value.subcuenta;
            filter.cta = this.formCuentaDestino.value.cuenta;
          }

          return this.cuentasService.getCuentasAsociar(
            filter,
            this.paginator.pageIndex
          );
        }),
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.cantidadDePaginas * data.registrosPorPagina;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((cuentas: ModelCuentasJudicial) => {
        this.dataSource = new MatTableDataSource(cuentas.cuentas);
      });
  }

  private _filter(value: string): Juzgado[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.listJuzgado.filter(
        (option) =>
          option.descripcion.toLowerCase().includes(filterValue) ||
          option.id.toLowerCase().includes(filterValue)
      );
    } else {
      return this.listJuzgado;
    }
  }

  private buildForm() {
    this.formCuentaDestino = this.formBuilder.group({
      tipoAsociacion: ['cbu', [Validators.required]],
      cuenta: [''],
      subcuenta: [''],
      cbu: [''],
      juzgado: [''],
      expediente: [''],
      caratula: [''],
    });
  }
}
