import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportesService {
  constructor(private http: HttpClient) {}

  downloadFile(cbu): Observable<any> {
    return this.http.get(
      `${environment.apiServiceBaseUri}/CuentasJudiciales/comprobante/${cbu}`,
      { responseType: 'blob' }
    );
  }
}
