import { Injectable } from '@angular/core';
import { Roles, TipoPerfil } from '../../core/model/tipo-usuario.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Juzgado, Organismo } from '../model/organismo.model';
import { Circunscripcion } from '../model/Circunscripcion.model';
import { Registro } from '../model/registro.model';
import { SolicitudCambioPerfil } from '../model/solicitud-perfil.model';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient) {}

  getTipoUsuario() {
    return this.http.get<Roles[]>(`${environment.apiServiceBaseUri}/Roles`);
  }

  getMisRolesCompatibles() {
    return this.http.get<Roles[]>(
      `${environment.apiServiceBaseUri}/Roles/MisRolesCompatibles`
    );
  }

  solicitarPerfil(solicitud: any) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/SolicitudesPerfiles`,
      solicitud
    );
  }

  getSolicitud(id) {
    return this.http.get<any>(
      `${environment.apiServiceBaseUri}/SolicitudesPerfiles/${id}`
    );
  }

  borrarSolicitud(id: number) {
    return this.http.delete(
      `${environment.apiServiceBaseUri}/SolicitudesPerfiles/${id}`
    );
  }

  editarSolicitud(id: number, solicitud: any) {
    return this.http.put(
      `${environment.apiServiceBaseUri}/SolicitudesPerfiles/${id}`,
      solicitud
    );
  }

  MisSolicitudes() {
    return this.http.get<SolicitudCambioPerfil[]>(
      `${environment.apiServiceBaseUri}/SolicitudesPerfiles/MisSolicitudes`
    );
  }

  MisPendientes() {
    return this.http.get<SolicitudCambioPerfil[]>(
      `${environment.apiServiceBaseUri}/SolicitudesPerfiles/MisPendientes`
    );
  }

  getJuzgados() {
    return this.http.get<Juzgado[]>(
      `${environment.apiServiceBaseUri}/Juzgados/Juzgados`
    );
  }

  getOrganizmo() {
    return this.http.get<Organismo[]>(
      `${environment.apiServiceBaseUri}/Organismos`
    );
  }

  getCircuscripcion() {
    return this.http.get<Circunscripcion[]>(
      `${environment.apiServiceBaseUri}/Circunscripciones`
    );
  }

  nuevaSolicitud(registro: Registro) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/Usuarios/Registrar`,
      registro
    );
  }
}
