import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  GestionModelOrganismo,
  Juzgado,
} from 'src/app/core/model/organismo.model';
import { OrganizmoService } from 'src/app/core/services/organizmo.service';
import { Acciones } from 'src/app/core/model/cuenta.model';
import { AccionesService } from 'src/app/core/services/acciones.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-organismo',
  templateUrl: './list-organismo.component.html',
  styleUrls: ['./list-organismo.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ListOrganismoComponent implements OnInit {
  @ViewChild('outerSort', { static: true }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<Juzgado>>;

  dataSource: MatTableDataSource<GestionModelOrganismo>;
  organismoData: GestionModelOrganismo[] = [];
  columnsToDisplay = ['id', 'descripcion', 'actions'];
  innerDisplayedColumns = ['id', 'descripcion'];
  expandedElement: GestionModelOrganismo | null;

  constructor(
    private cd: ChangeDetectorRef,
    private orgSvr: OrganizmoService,
    private accionService: AccionesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.orgSvr.getOrganismo().subscribe((result) => {
      result.forEach((org) => {
        if (
          org.juzgados &&
          Array.isArray(org.juzgados) &&
          org.juzgados.length
        ) {
          this.organismoData = [
            ...this.organismoData,
            { ...org, juzgados: new MatTableDataSource(org.juzgados) },
          ];
        } else {
          this.organismoData = [...this.organismoData, org];
        }
      });

      this.dataSource = new MatTableDataSource(this.organismoData);
      this.dataSource.sort = this.sort;
    });
  }

  toggleRow(element: GestionModelOrganismo) {
    element.juzgados &&
    (element.juzgados as MatTableDataSource<Juzgado>).data.length
      ? (this.expandedElement =
          this.expandedElement === element ? null : element)
      : null;
    this.cd.detectChanges();
    this.innerTables.forEach(
      (table, index) =>
        ((table.dataSource as MatTableDataSource<Juzgado>).sort =
          this.innerSort.toArray()[index])
    );
  }

  applyFilter(filterValue: string) {
    this.innerTables.forEach(
      (table, index) =>
        ((table.dataSource as MatTableDataSource<Juzgado>).filter = filterValue
          .trim()
          .toLowerCase())
    );
  }

  ejecutar(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      this.router.navigate([accion.url]);
    } else {
      this.accionService.customAccion(accion);
    }
  }
}
