import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../services/token.service';
import { throwError } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoginUser } from '../model/login.model';
import { TokenModel, Tokens } from '../model/token.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private refreshTokenTimeout;

  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService
  ) {}

  loginRestApi(login: LoginUser) {
    // refrecamos los token
    this.tokenService.borrarToken();

    return this.http
      .post(`${environment.apiServiceBaseUri}/Usuarios/authenticate`, login, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        tap(
          (resp: TokenModel) => {
            console.log(resp);
            this.tokenService.savetoken(resp);
            this.startRefreshTokenTimer();
            return resp;
          },
          (err: any) => {
            if (err.status === 409) {
              return throwError(err.error.detalle);
            } else {
              return throwError(err.error.detalle);
            }
          }
        )
      );
  }

  getTokenJudiciales(token: string) {
    // refrecamos los token
    this.tokenService.borrarToken();
    const header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
    };

    return this.http
      .post(`${environment.apiServiceBaseUri}/Sso/Authenticate`, {}, header)
      .pipe(
        tap(
          (resp: TokenModel) => {
            console.log(resp);
            this.tokenService.savetoken(resp);
            this.startRefreshTokenTimer();
            return resp;
          },
          (err: any) => {
            if (err.status === 409) {
              return throwError(err);
            } else {
              return throwError(err);
            }
          }
        )
      );
  }

  refreshToken() {
    const tok = this.tokenService.getToken();
    return this.http
      .post<any>(`${environment.apiServiceBaseUri}/Usuarios/RefreshToken`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + tok.tokens.refreshToken.token,
        },
      })
      .pipe(
        tap(
          (refresh: Tokens) => {
            this.tokenService.refreshToken(refresh);
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  RefreshUsuario() {
    const tok = this.tokenService.getToken();
    return this.http
      .get<any>(`${environment.apiServiceBaseUri}/Usuarios/RefreshUsuario`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + tok.tokens.refreshToken.token,
        },
      })
      .pipe(
        tap(
          (userRefresh: TokenModel) => {
            userRefresh.tokens = tok.tokens;
            this.tokenService.savetoken(userRefresh);
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  validarEmail(
    codigoValidacion: string,
    tipoDoc: number,
    nroDoc: number,
    confirma: number
  ) {
    // tslint:disable-next-line: max-line-length
    return this.http.post(
      `${environment.apiServiceBaseUri}/Usuarios/ConfirmarEmail`,
      {
        codigoValidacion,
        tipoDoc,
        nroDoc: nroDoc.toString(),
        c: confirma,
      }
    );
  }

  cambiarClave(clave: string) {
    // tslint:disable-next-line: max-line-length
    return this.http.post(`${environment.apiServiceBaseUri}/Claves`, {
      claveJudicial: clave,
    });
  }

  cambiarClaveHash(clave: string, hash: string) {
    return this.http.post(`${environment.apiServiceBaseUri}/Claves`, {
      claveJudicial: clave,
      hashOlvido: hash,
    });
  }

  RegistrarUsuarioHash(clave: string, hash: string) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/Usuarios/ConfirmarInvitacionUsuario`,
      {
        clave,
        hash,
      }
    );
  }

  getInfoUsuario(hash: string) {
    return this.http.get<any>(
      `${environment.apiServiceBaseUri}/Claves/GetUsuarioPorHash?hash=` + hash
    );
  }

  getUsuarioHash(hash: string) {
    return this.http.post<any>(
      `${environment.apiServiceBaseUri}/Usuarios/GetDatosDeUsuarioEnHash?hash=`,
      {
        hash,
      }
    );
  }

  recordaClave(tipoDoc) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/Claves/OlvidoPassword`,
      tipoDoc
    );
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const userInfo = this.tokenService.getToken();

    // set a timeout to refresh the token a minute before it expires
    //const expiresMin = userInfo.tokens.accessToken.expire_in - 1;
    const expiresMin = 1 - 0.2;
    const timeout = expiresMin * (60 * 1000);
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshToken().subscribe(),
      timeout
    );
  }

  logout() {
    // refrecamos los token
    return this.http.get(`${environment.apiServiceBaseUri}/Usuarios/Logout`);
  }

  limpiarToken() {
    this.tokenService.borrarToken();
    clearTimeout(this.refreshTokenTimeout);
  }
}
