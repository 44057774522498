<h1 class="mat-h1">Listado de OPJE</h1>

<div>
  <mat-form-field>
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
  </mat-form-field>
</div>

<table
  mat-table
  [dataSource]="dataSource"
  class="full-width-table"
  matSort
  aria-label="Elements"
>
  <!-- idOpj Column -->
  <ng-container matColumnDef="idOpj">
    <th mat-header-cell *matHeaderCellDef>#Nro Orden</th>
    <td mat-cell *matCellDef="let element">
      <b>{{ element.idOpj }}</b>
    </td>
  </ng-container>

  <!-- caratula Column -->
  <ng-container matColumnDef="caratula">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Caratula</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.caratula"
      matTooltipPosition="above"
    >
      {{ element.caratula.substring(0, 5) }}...
    </td>
  </ng-container>

  <!-- expediente Column -->
  <ng-container matColumnDef="expediente">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Expediente</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.expediente"
      matTooltipPosition="above"
    >
      {{ element.expediente.substring(0, 5) }}...
    </td>
  </ng-container>

  <!-- bene Column -->
  <ng-container matColumnDef="cuitBeneficiario">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>CUIT/CUIL Benef</th>
    <td mat-cell *matCellDef="let element">{{ element.cuitBeneficiario }}</td>
  </ng-container>

  <!-- cbu dest Column -->
  <ng-container matColumnDef="cbuDestino">
    <th mat-header-cell *matHeaderCellDef>CBU Dest.</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.cbuDestino"
      matTooltipPosition="above"
    >
      <b color="warn"> XXXX..{{ element.cbuDestino.substring(17, 4) }} </b>
    </td>
  </ng-container>

  <!-- operador Column -->
  <ng-container matColumnDef="operador">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Operador</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.operador.apellido + ', ' + element.operador.nombre"
      matTooltipPosition="above"
    >
      {{ element.operador.apellido.substring(0, 4) }}...
    </td>
  </ng-container>

  <!-- Monto Column -->
  <ng-container matColumnDef="montoOPJStr">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Monto($)</th>
    <td mat-cell *matCellDef="let element">
      <b style="warn"> {{ element.montoOPJStr }} </b>
    </td>
  </ng-container>

  <!-- tipo Column -->
  <ng-container matColumnDef="tipo">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
    <td mat-cell *matCellDef="let element">{{ element.tipo }}</td>
  </ng-container>

  <!-- Fecha Alta Column -->
  <ng-container matColumnDef="estado">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
    <td mat-cell *matCellDef="let element" class="color-estado">
      <b>{{ element.estado.descripcion }}</b>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Acciones</th>
    <td mat-cell *matCellDef="let row">
      <button
        mat-icon-button
        color="primary"
        [matMenuTriggerFor]="menu"
        aria-label="Acciones"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          *ngFor="let item of row.acciones"
          mat-menu-item
          (click)="ejecutar(item)"
        >
          <mat-icon [ngClass]="item.cssColor">{{ item.icono }}</mat-icon>
          <span>{{ item.titulo }}</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
</table>

<mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
