import { Component, OnInit } from '@angular/core';
import { NovedadesService } from 'src/app/core/services/novedades.service';
import { Novedades } from 'src/app/core/model/menu.model';

@Component({
  selector: 'app-novedades',
  templateUrl: './novedades.component.html',
  styleUrls: ['./novedades.component.css'],
})
export class NovedadesComponent implements OnInit {
  listNovedades: Novedades[];
  constructor(private novService: NovedadesService) {}

  ngOnInit(): void {
    this.novService.getNovedades().subscribe((nov) => {
      this.listNovedades = nov;
    });
  }
}
