import { Component } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  @BlockUI() blockUI: NgBlockUI;
  title = 'hbjudiciales-angular';

  constructor() {
    this.blockUI.start('Cargando...'); // Start blocking
    setTimeout(() => {
      this.blockUI.stop(); // Stop blocking
    }, 2000);
  }
}
