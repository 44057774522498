import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Juzgado } from 'src/app/core/model/organismo.model';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { AccountService } from 'src/app/core/services/account.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganizmoService } from 'src/app/core/services/organizmo.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertaCambiosComponent } from '../alerta-cambios/alerta-cambios.component';

@Component({
  selector: 'app-edit-organismo',
  templateUrl: './edit-organismo.component.html',
  styleUrls: ['./edit-organismo.component.css'],
})
export class EditOrganismoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  registroError: string;
  form: FormGroup;
  id: number;
  juzgados: Juzgado[] = [];
  juzDisp: Juzgado[] = [];
  datasource = new MatTableDataSource([]);
  selectable = true;
  removable = true;
  selection = new SelectionModel<Juzgado>(true, []);
  displayedColumns: string[] = ['select', 'juzgado'];

  constructor(
    private formBuilder: FormBuilder,
    private accountSer: AccountService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private dialogConfirm: MatDialog,
    private activatedRoute: ActivatedRoute,
    private orgService: OrganizmoService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params.id;

      // vamos a buscar el organismo y los juzgados asociados
      this.orgService.getOrganismoItem(this.id).subscribe((org) => {
        // agregamos al form la denominacion
        this.form.patchValue({ organismo: org.descripcion });
        this.juzgados = org.juzgados;

        // Traemos todos los juzgados
        this.accountSer.getJuzgados().subscribe((juz) => {
          this.juzDisp = juz;
          const juzFilter = this.juzgados.map((item) => item.id);
          const disponible = this.juzDisp.filter(
            (j) => !juzFilter.includes(j.id)
          );
          this.datasource = new MatTableDataSource(disponible);
          this.datasource.paginator = this.paginator;
        });
      });
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  remove(juz: Juzgado): void {
    const index = this.juzgados.indexOf(juz);

    if (index >= 0) {
      this.juzgados.splice(index, 1);
      const juzFilter = this.juzgados.map((item) => item.id);
      const disponible = this.juzDisp.filter((j) => !juzFilter.includes(j.id));
      this.datasource = new MatTableDataSource(disponible);
      this.datasource.paginator = this.paginator;
    }
  }

  checkboxLabel(row?: Juzgado): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  chechedJuz(check, item) {
    if (check.checked) {
      this.juzgados.push(item);
      const juzFilter = this.juzgados.map((item) => item.id);
      const disponible = this.juzDisp.filter((j) => !juzFilter.includes(j.id));
      this.datasource = new MatTableDataSource(disponible);
      this.datasource.paginator = this.paginator;
    }
  }

  saveRegister(event) {
    const editOrg = {
      id: this.id,
      descripcion: this.form.value.organismo,
      juzgados: this.juzgados,
    };

    // Validamos que la modificacion no afecte ningun registro
    this.orgService
      .validarCambioOrganismo(this.id, editOrg)
      .subscribe((valid) => {
        if (valid.length > 0) {
          this.dialogConfirm
            .open(AlertaCambiosComponent, {
              data: valid,
            })
            .afterClosed()
            .subscribe((confirmado: boolean) => {
              if (confirmado) {
                this.guardarRegistro(editOrg);
              }
            });
        } else {
          this.guardarRegistro(editOrg);
        }
      });
  }

  guardarRegistro(editOrg) {
    this.orgService.EditarOrganismo(this.id, editOrg).subscribe(
      (newRegistro) => {
        console.log(newRegistro);
        this.matSnackBar
          .open('Organismo editado correctamente', 'Ok', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          })
          .afterDismissed()
          .subscribe(() => {
            this.router.navigate(['./organismo/gestion']);
          });
      },
      (err) => {
        console.log(err);
        this.registroError = err.error.detalle;
      }
    );
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      organismo: ['', Validators.required],
    });
  }
}
