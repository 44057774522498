import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { AbogadoService } from 'src/app/core/services/abogado.service';
import { InformeIngresoAbogado } from 'src/app/core/model/colegio.model';
import 'moment/locale/es';
import { FormControl } from '@angular/forms';
import { saveAs } from 'file-saver';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-reporte-ingreso',
  templateUrl: './reporte-ingreso.component.html',
  styleUrls: ['./reporte-ingreso.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ReporteIngresoComponent {
  isGrillaVisible = false;
  sinDatos = 0;
  dataSource = new MatTableDataSource([]);
  periodo = new FormControl();
  displayedColumns: string[] = [
    'tipoDocDesc',
    'nroDoc',
    'apellidoYNombre',
    'matriculaNro',
    'ingreso',
  ];

  constructor(
    private abogaService: AbogadoService,
    private dateAdapter: DateAdapter<any>,
    private matSnackBar: MatSnackBar
  ) {
    this.dateAdapter.setLocale('es');
  }

  buildData() {
    if (!this.periodo.value) {
      this.matSnackBar.open('Ingrese un periodo valido ', 'Ok', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      return;
    }

    const date = this.periodo.value as Date;

    this.abogaService
      .getInformeIngreso(date.getMonth() + 1, date.getFullYear())
      .subscribe(
        (movimientos: InformeIngresoAbogado) => {
          if (movimientos) {
            this.isGrillaVisible = true;
            this.dataSource = new MatTableDataSource(movimientos.detalle);
            this.sinDatos = 2;
          } else {
            this.isGrillaVisible = false;
            this.dataSource = new MatTableDataSource([]);
            this.sinDatos = 1;
          }
        },
        (error) => {
          this.isGrillaVisible = false;
          this.dataSource = new MatTableDataSource([]);
          this.sinDatos = 1;
        }
      );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  printData() {
    const date = this.periodo.value as Date;
    this.abogaService
      .getReporteIngreso(date.getMonth() + 1, date.getFullYear())
      .subscribe((data) => {
        saveAs(data, `reporte-ingreso.csv`);
      });
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.periodo.setValue(
      new Date(normalizedMonth.year(), normalizedMonth.month(), 1)
    );
    datepicker.close();
  }
}
