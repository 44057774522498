import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SolicitudCuentaAbogado } from 'src/app/core/model/colegio.model';
import { AbogadoService } from 'src/app/core/services/abogado.service';
import { AccionesService } from 'src/app/core/services/acciones.service';
import { Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { Acciones } from 'src/app/core/model/cuenta.model';

@Component({
  selector: 'app-autorizar-cuenta',
  templateUrl: './autorizar-cuenta.component.html',
  styleUrls: ['./autorizar-cuenta.component.css'],
})
export class AutorizarCuentaComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<SolicitudCuentaAbogado>;

  registroError: string;
  isValid = false;

  resultsLength = 0;
  isLoadingResults = true;
  isGrillaVisible = true;
  isRateLimitReached = false;
  registros: SolicitudCuentaAbogado;
  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'id',
    'nombre',
    'juzgado',
    'cuenta',
    'caratula',
    'expediente',
    'actions',
  ];

  constructor(
    private abogadoService: AbogadoService,
    private accionService: AccionesService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.buildData();
    this.accionService.badgeResultado$.subscribe((registros) => {
      this.buildData();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ejecutar(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      this.router.navigate([accion.url]);
    } else {
      this.accionService.customAccion(accion);
    }
  }

  buildData() {
    this.isGrillaVisible = true;
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.abogadoService.getSolicitudCuentaAbogado(
            this.paginator.pageIndex
          );
        }),
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.paginacion.cant_Paginas;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((cuentas: SolicitudCuentaAbogado) => {
        this.dataSource = new MatTableDataSource(cuentas.colCuentasColegio);
      });
  }
}
