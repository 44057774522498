<h2 mat-dialog-title class="tituloPopUp">Anular OPJ #{{ dataRef }}</h2>
<mat-dialog-content class="mat-typography">
  <div class="progressAuto" *ngIf="this.isAutorizando">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div style="margin: 2rem">
    <form [formGroup]="formAutorizar" autocomplete="off">
      <h2>Para confirmar la operacion ingrese las siguientes coordenadas</h2>
      <mat-error *ngIf="isInvalidCoordenada"
        ><h3>{{ isInvalidCoordenada }}</h3></mat-error
      >
      <mat-form-field class="input-coord" appearance="fill">
        <mat-label>{{ this.coordenadas.coord1 }}</mat-label>
        <input
          matInput
          mask="00"
          class="ocultarCoordenadas"
          #inputA
          autocomplete="off"
          (ngModelChange)="nextItemCoord($event)"
          autofocus
          formControlName="coordA"
        />
      </mat-form-field>
      <mat-form-field class="input-coord" appearance="fill">
        <mat-label>{{ this.coordenadas.coord2 }}</mat-label>
        <input
          matInput
          mask="00"
          #inputB
          class="ocultarCoordenadas"
          autocomplete="off"
          (ngModelChange)="validarCoordenadas($event)"
          formControlName="coordB"
        />
      </mat-form-field>
      <br />
      <mat-form-field style="width: 650px" appearance="fill">
        <mat-label>Comentario</mat-label>
        <textarea
          matInput
          formControlName="comentario"
          placeholder="Ingrese un comentario de la operacion..."
        ></textarea>
      </mat-form-field>
      <div *ngIf="!this.isAutorizando">
        <button mat-raised-button (click)="this.dialogRef.close()">
          Cancelar
        </button>
        <button
          mat-raised-button
          class="margenBoton"
          color="primary"
          [disabled]="this.formAutorizar.invalid"
          (click)="AnularOpj($event)"
        >
          Anular
        </button>
      </div>
    </form>
  </div>
</mat-dialog-content>
