import { Injectable } from '@angular/core';
import { TokenModel, Tokens } from '../model/token.model';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor() {}

  savetoken(token: TokenModel) {
    localStorage.setItem('authorizationData', JSON.stringify(token));
  }

  getToken() {
    const token = localStorage.getItem('authorizationData');
    return JSON.parse(token) as TokenModel;
  }

  refreshToken(newToken: Tokens) {
    const token = localStorage.getItem('authorizationData');
    const tokenM = JSON.parse(token) as TokenModel;
    tokenM.tokens = newToken;
    this.savetoken(tokenM);
  }

  borrarToken() {
    localStorage.removeItem('authorizationData');
  }
}
