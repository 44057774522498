import { Component, OnInit } from '@angular/core';
import {
  Sucursales,
  TiposExpedientes,
  Sectores,
} from 'src/app/core/model/juzgado.model';
import { CuentaJudicialService } from 'src/app/core/services/cuentaJudicial.service';
import { Juzgado } from 'src/app/core/model/organismo.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Documento } from 'src/app/core/model/token.model';
import { BeneficiarioService } from 'src/app/core/services/beneficiario.service';
import { Beneficiario } from 'src/app/core/model/beneficiario.model';
import { AltaCuentaJudicial } from 'src/app/core/model/cuenta.model';
import { MatDialog } from '@angular/material/dialog';
import { AltaBeneficiarioComponent } from '../alta-beneficiario/alta-beneficiario.component';

@Component({
  selector: 'app-alta-cuenta',
  templateUrl: './alta-cuenta.component.html',
  styleUrls: ['./alta-cuenta.component.css'],
})
export class AltaCuentaComponent implements OnInit {
  formAltaCuenta: FormGroup;
  formBeneficiario: FormGroup;
  registroError: string;
  year: string;
  idCuenta: number;
  tipoExpediente: TiposExpedientes[];
  listJuzgado: Juzgado[];
  listSucursal: Sucursales[];
  defaultSector: number;
  listSectores: Sectores[];
  listTipodocumento: Documento[];
  beneficiario: Beneficiario;
  domicilio: string;
  cuitValid = false;
  requiereBeneficiario = false;
  createBenef = false;
  altaBenef = false;
  detalleSector: string;
  bloquear = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private benefService: BeneficiarioService,
    private cuentaService: CuentaJudicialService,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    const currentTime = new Date();
    this.year = currentTime.getFullYear().toString();
    this.buildForm();
  }

  ngOnInit(): void {
    // buscamos los expedientes
    this.cuentaService
      .getTipoExpediente()
      .subscribe((data: TiposExpedientes[]) => {
        this.tipoExpediente = data;
      });

    // buscamos los juzgados
    this.cuentaService.getJuzgadoFamilia().subscribe((data: Juzgado[]) => {
      this.listJuzgado = data;
    });

    // buscamos los juzgados
    this.cuentaService.getSectores().subscribe((data: Sectores[]) => {
      this.listSectores = data;
    });

    this.cuentaService.getTipoDocumento().subscribe((data: Sectores[]) => {
      this.listTipodocumento = data;
    });
  }

  selectJuzgado(item) {
    // buscamos las sucursales
    this.cuentaService.getSucursales(item).subscribe((data: Sucursales[]) => {
      this.listSucursal = data;
    });

    if (this.listJuzgado.find((f) => f.id === item).familia) {
      this.requiereBeneficiario = true;
    } else {
      this.requiereBeneficiario = false;
    }
  }

  selectSucursal(idSucursal: number) {
    this.domicilio = this.listSucursal.find(
      (f) => f.id === idSucursal
    ).domicilio;
  }

  selectSector(sector: number) {
    this.detalleSector = this.listSectores.find((f) => f.id === sector).detalle;
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.formAltaCuenta.valid) {
      if (this.requiereBeneficiario) {
        if (!this.formAltaCuenta.value.sucursal) {
          this.registroError =
            'Debe seleccionar una sucursal para retirar la tarjeta de debito';
          return;
        }
      }

      this.bloquear = true;
      const model: AltaCuentaJudicial = this.formAltaCuenta.value;
      if (this.requiereBeneficiario) {
        this.defaultSector = this.listSectores.find(
          (f) => f.descripcion == 'Privado'
        ).id;
        model.sector = +this.defaultSector;
      } else model.sector = +this.formAltaCuenta.value.sector;
      model.anio = this.formAltaCuenta.value.anio.toString();

      if (!model.sucursal) {
        // Dejamos la sucursal definida de forma predeterminada
        model.sucursal = 43;
      }

      // Damos de alta la cuenta judicial

      this.cuentaService.altaCuentaJudicial(model).subscribe(
        (data: AltaCuentaJudicial) => {
          this.idCuenta = data.id;
          if (this.requiereBeneficiario) {
            if (this.altaBenef) {
              this.beneficiario.cbu = data.cbu;
              this.benefService
                .crearBeneficiario(this.beneficiario)
                .subscribe((benef) => {
                  this.mostrarMensaje();
                });
            } else {
              if (this.cuitValid) {
                const relBenef = {
                  cbu: data.cbu,
                  tipoDocumentoBT: this.formBeneficiario.value.idTipoDocumento,
                  nroDocumento: this.formBeneficiario.value.beneficiario,
                };
                this.cuentaService
                  .relCuentaBeneficiario(relBenef)
                  .subscribe((rel) => {
                    this.mostrarMensaje();
                  });
              } else {
                this.mostrarMensaje();
              }
            }
          } else {
            this.mostrarMensaje();
          }
        },
        (err) => {
          this.registroError = err.error.detalle;
          this.bloquear = false;
        }
      );
    }
  }

  mostrarMensaje() {
    this.matSnackBar
      .open('Cuenta Judicial creada correctamente', 'Ok', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      })
      .afterDismissed()
      .subscribe(() => {
        // Falta agragar imprimier reporte
        this.cuentaService
          .getComprobanteAltaCuenta(this.idCuenta)
          .subscribe((report) => {
            const blob: any = new Blob([report], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          });

        this.router.navigate(['./']);
      });
  }

  buscarBeneficiario(docuemento) {
    if (docuemento.length === 11) {
      if (this.createBenef === false) {
        this.benefService
          .getBeneficiario(this.formBeneficiario.value.beneficiario)
          .subscribe(
            (data: Beneficiario) => {
              this.beneficiario = data;
              this.formBeneficiario.value.idTipoDocumento =
                data.persona.tipoDocumentoBT;
              this.cuitValid = true;
              this.createBenef = false;
            },
            (err) => {
              this.cuitValid = false;
              this.createBenef = true;
            }
          );
      }
    } else {
      this.cuitValid = false;
      this.createBenef = false;
    }
  }

  altaBeneficiario() {
    this.dialog
      .open(AltaBeneficiarioComponent, {
        data: {
          cuit: this.formBeneficiario.value.idTipoDocumento,
          beneficiario: this.formBeneficiario.value.beneficiario,
        },
      })
      .afterClosed()
      .subscribe((benef: Beneficiario) => {
        this.beneficiario = benef;
        this.createBenef = false;
        this.altaBenef = true;
        const vm = {
          idTipoDocumento: benef.persona.tipoDocumentoBT,
          beneficiario: benef.persona.nroDocumento,
        };

        this.formBeneficiario.patchValue(vm);
      });
  }

  //Cambiar año
  private buildForm() {
    this.formAltaCuenta = this.formBuilder.group({
      idTipoExpediente: ['EXD', [Validators.required]],
      idJuzgado: ['', [Validators.required]],
      expediente: ['', [Validators.required]],
      anio: [this.year, [Validators.required]],
      caratula: [null, [Validators.required]],
      sector: [0, [Validators.required]],
      sucursal: [''],
    });

    this.formBeneficiario = this.formBuilder.group({
      idTipoDocumento: [2],
      beneficiario: [''],
    });
  }
}
