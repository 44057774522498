<h1 mat-dialog-title>
  <mat-icon class="icon-alerta">warning</mat-icon> Advertencia de Cambio
</h1>
<div mat-dialog-content>
  <h4 class="titulo">
    Se encontraron las siguientes advertencias, esta seguro de continuar ?
  </h4>
  <mat-list role="list">
    <mat-list-item *ngFor="let item of mensaje" role="listitem"
      ><mat-icon class="icon-item">bookmark_border</mat-icon
      >{{ item.detalle }}</mat-list-item
    >
  </mat-list>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cerrarDialogo()">No</button>
  <button
    mat-stroked-button
    color="warn"
    (click)="confirmado()"
    cdkFocusInitial
  >
    Sí, Continuar
  </button>
</div>
