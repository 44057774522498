import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MyErrorStateMatcher } from './../../core/util/validators';
import { AccountService } from '../../core/services/account.service';
import { UsuarioService } from '../../core/services/usuario.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-registro-edit',
  templateUrl: './registro-edit.component.html',
  styleUrls: ['./registro-edit.component.css'],
})
export class RegistroEditComponent implements OnInit {
  form: FormGroup;
  registroError: string;
  hide = true;
  matcher = new MyErrorStateMatcher();
  perfil: string;
  listCircuns: any[];
  panelAbogado: boolean;
  id: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private accountService: AccountService,
    private usuarioService: UsuarioService,
    private matSnackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params.id;
      this.usuarioService.getUsuario(this.id).subscribe((usuario: any) => {
        console.log(usuario.idPerfil);
        this.form.patchValue(usuario);
        if (usuario.idPerfil === 1) {
          this.perfil = 'Administrador Colegio de Abogados';
          this.panelAbogado = true;
        } else {
          this.panelAbogado = false;
          this.perfil = 'Supervisor de Superintendencia';
        }
      });
    });

    // buscamos los juzgados
    this.accountService.getCircuscripcion().subscribe((data: any[]) => {
      this.listCircuns = data;
      console.log(this.listCircuns);
    });
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      /*Validamos que completo los datos de abogado o juzgado*/
      const registro = this.form.value;
      // Valida que el perfil sea abogado o colegio
      console.log(registro.perfil);
      if (this.perfil === 'Administrador Colegio de Abogados') {
        if (registro.idCircunscripcion === '') {
          this.registroError = 'debe seleccionar una circunscripcion';
          return;
        }
        registro.idPerfil = 1;
      } else {
        registro.idPerfil = 6;
      }

      this.usuarioService.editUsuario(this.id, registro).subscribe(
        (newRegistro) => {
          console.log(newRegistro);
          this.matSnackBar
            .open('Registro actualizado correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            })
            .afterDismissed()
            .subscribe(() => {
              this.router.navigate(['./admin']);
            });
        },
        (err) => {
          console.log(err);
          this.registroError = err.error.ExceptionMessage;
        }
      );
    }
  }

  cancelarRegistro() {
    this.router.navigate(['admin/']);
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      apellido: ['', [Validators.required]],
      dni: ['', [Validators.required]],
      perfil: '',
      idPerfil: 0,
      email: ['', [Validators.email]],
      idCircunscripcion: '',
    });
  }
}
