import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { infoCuenta, ConceptoOpj } from 'src/app/core/model/cuenta.model';
import {
  TipoTransferencias,
  TipoOpj,
  Concepto,
  ResumenOPJ,
  FormaPagoOPJ,
  OpjModel,
} from 'src/app/core/model/opjs.model';
import { Documento } from 'src/app/core/model/token.model';
import { Agenda } from 'src/app/core/model/agenda.model';
import { Coelsa } from 'src/app/core/model/coelsa.model';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { OpjService } from 'src/app/core/services/opj.service';
import { AgendaService } from 'src/app/core/services/agenda.service';
import { BeneficiarioService } from 'src/app/core/services/beneficiario.service';
import { CuentaJudicialService } from 'src/app/core/services/cuentaJudicial.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-opj-edit',
  templateUrl: './opj-edit.component.html',
  styleUrls: ['./opj-edit.component.css'],
})
export class OpjEditComponent implements OnInit {
  formAltaOpj: FormGroup;
  formBeneficiario: FormGroup;
  formCuentaDestino: FormGroup;
  idOpj: number;
  formConcepto: FormGroup;
  cuentaOrigen: infoCuenta;
  registroError: string;
  tipoTransferencia: TipoTransferencias[];
  tipoOpj: TipoOpj[];
  listTipodocumento: Documento[];
  listConceptos: Concepto[];
  agendaJudicial: Agenda[];
  itemAgenda: number;
  itemConceptoOpj: ConceptoOpj[] = [];
  destinatario: Coelsa;
  cuitValid = false;
  erorrCBU = '';
  cuitFacultadoValid = false;
  beneficiario: any;
  titularCBU: any;
  facultado: any;
  isBeneficiario = false;
  isFacultado = false;
  isDestinarioLoad = false;
  isTributo = false;
  isOtroTitular = false;
  failBenef = false;
  destCbu = true;
  dataSource = new MatTableDataSource([]);

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private opjService: OpjService,
    private agendaService: AgendaService,
    private benefService: BeneficiarioService,
    private cuentaService: CuentaJudicialService,
    private activatedRoute: ActivatedRoute,
    private matSnackBar: MatSnackBar
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.idOpj = params.id;
      this.cuentaService.getOpj(this.idOpj).subscribe(
        (data) => {
          this.cuentaService.getInfoCuenta(data.cbuOrigen).subscribe(
            (ci) => {
              this.cuentaOrigen = ci;

              this.opjService
                .ListarTipoTransferencias()
                .subscribe((tt: TipoTransferencias[]) => {
                  this.tipoTransferencia = tt;
                  this.selectTipoTransferencia(data.tipoTransferncia);
                  // buscamos los tipos de OPJ
                  this.opjService
                    .ListarTiposOpjs()
                    .subscribe((to: TipoOpj[]) => {
                      this.tipoOpj = to;
                      const selectTipoOpj = this.tipoOpj.find(
                        (f) => f.idTipoOpj === data.tipoOPJ
                      );
                      this.listConceptos = selectTipoOpj.conceptos;
                      this.cuentaService
                        .getTipoDocumento()
                        .subscribe((dc: Documento[]) => {
                          this.listTipodocumento = dc;
                          // Seteamos los valores para recuperar los datos cargados
                          this.formAltaOpj.patchValue({
                            tipoOPJ: data.tipoOPJ,
                            tipoTransferncia: data.tipoTransferncia,
                          });

                          const ben = {
                            nroDocumento: '',
                            facultado: '',
                            documentofacultado: '',
                            otroTitular: '',
                          };

                          if (data.beneficiario) {
                            ben.nroDocumento =
                              data.beneficiario.numeroDocumento;
                          }

                          if (data.facultado) {
                            ben.facultado = data.facultado.numeroDocumento;
                            ben.documentofacultado =
                              data.facultado.tipoDocumento.toString();
                            ben.otroTitular = 'true';
                          }

                          this.formBeneficiario.patchValue(ben);

                          this.formCuentaDestino.patchValue({
                            tipoDest: 'true',
                            cbuDestino: data.cbuDestino,
                            titular: '',
                            aliasDestino: '',
                            guardaAgenda: '',
                            denominacion: '',
                            email: '',
                          });

                          this.buscarCbu(data.cbuDestino);

                          for (let i = 0; i < data.conceptos.length; i++) {
                            this.formConcepto.patchValue({
                              idConcepto: data.conceptos[i].idConcepto,
                              importe: data.conceptos[i].importe,
                              comentario: data.comentarios,
                            });

                            this.agregarConcepto();
                          }
                        });
                    });
                });
            },
            (err) => {
              console.log(err);
            }
          );
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  agregarConcepto() {
    // Validamos que no se ingreso el mismo concepto
    const idConcept = this.formConcepto.value.idConcepto;

    // Validamos si selecciono concepto
    if (idConcept === -1) {
      this.matSnackBar.open('Seleccione un concepto de la lista', 'Ok', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }

    if (
      this.itemConceptoOpj.filter((item) => item.idConcepto === idConcept)
        .length === 0
    ) {
      const concepto = this.listConceptos.find(
        (f) => f.codigoConcepto === idConcept
      ).descripcion;
      const itemContepto = {
        importe: +this.formConcepto.value.importe,
        despcipcion: concepto,
        idConcepto: this.formConcepto.value.idConcepto,
      };

      this.itemConceptoOpj.push(itemContepto);

      // Limpiamos el formulario

      const init = { idConcepto: -1, importe: '' };
      this.formConcepto.patchValue(init);
    } else {
      this.matSnackBar.open('El concepto ingresado ya existe', 'Ok', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  applyFilter(event: Event) {
    /*const filterValue = (event.target as HTMLInputElement).value;
    this.agendaJudicial = this.agendaJudicial.filter(f => f.aliasAgenda === filterValue.trim().toLowerCase());

    if (filterValue === '')
    {
      this.agendaJudicial = this.dataSource.data;
    }*/
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarItem(idConcepto) {
    this.itemConceptoOpj = this.itemConceptoOpj.filter(
      (item) => item.idConcepto !== idConcepto
    );
  }

  get getImporteTotal(): number {
    return this.itemConceptoOpj.reduce(
      (sum: number, b: ConceptoOpj) => sum + +b.importe,
      0
    );
  }

  get resumenOpj(): ResumenOPJ {
    let cbu = null;
    let ntitular = null;
    if (this.destinatario) {
      cbu = this.destinatario.cuenta.nro_cbu;
      if (this.titularCBU) {
        ntitular = this.titularCBU.nombre;
      } else {
        ntitular = '';
      }
    }

    if (
      this.formAltaOpj.value.tipoOPJ !== '' &&
      this.formAltaOpj.value.tipoTransferncia !== ''
    ) {
      const topj = this.tipoOpj.find(
        (f) => f.idTipoOpj === this.formAltaOpj.value.tipoOPJ
      );
      const tTrans = this.tipoTransferencia.find(
        (f) => f.id === this.formAltaOpj.value.tipoTransferncia
      );

      const resumen = {
        CtaOrigen: this.cuentaOrigen.cuenta,
        TipoOPJ: topj ? topj.descripcion : '',
        TipoTransf: tTrans ? tTrans.descripcion : '',
        cbu,
        titular: ntitular,
        importe: this.getImporteTotal,
      };

      return resumen;
    } else {
      return new ResumenOPJ();
    }
  }

  selectTipoOpj(item) {
    // buscamos las sucursales
    const selectTipoOpj = this.tipoOpj.find((f) => f.idTipoOpj === item);
    this.listConceptos = selectTipoOpj.conceptos;
    if (selectTipoOpj.cbuDestino) {
      this.isTributo = true;
      this.buscarCbu(selectTipoOpj.cbuDestino.replace('-', ''));
    }

    this.tipoTransferencia = selectTipoOpj.tipoTransferenciasPermitidas;
    this.itemConceptoOpj = [];
    this.formAltaOpj.patchValue({
      tipoTransferncia: '',
    });
    this.destinatario = null;
    const respCbu = {
      tipoDocumento: 1,
      nroDocumento: '',
      nombre: '',
    };
    this.formBeneficiario.patchValue(respCbu);
  }

  selectTipoTransferencia(tipoTranf) {
    let soloBPN = false;
    if (tipoTranf === FormaPagoOPJ.transferenciaBPN) {
      this.isBeneficiario = false;
      soloBPN = true;
      if (!this.isTributo) {
        this.isDestinarioLoad = true;
      }
    }

    if (tipoTranf === FormaPagoOPJ.cobroCaja) {
      this.isBeneficiario = true;
      this.isFacultado = true;
      this.isOtroTitular = false;
      this.isDestinarioLoad = false;
    }

    if (tipoTranf === FormaPagoOPJ.otrosBancos) {
      this.isBeneficiario = true;
      this.isFacultado = true;
      this.isOtroTitular = true;
      this.isDestinarioLoad = true;
    }

    if (this.isDestinarioLoad) {
      this.agendaService.getListaAgenda(soloBPN).subscribe((agen) => {
        this.agendaJudicial = agen;
        this.dataSource = new MatTableDataSource(this.agendaJudicial);
        console.log(agen);
      });
    }
  }

  selectAgenda(item: Agenda) {
    console.log(item);
    this.itemAgenda = item.id;
    this.destinatario = item.aliasCBU;
    const respCbu = {
      tipoDocumento: 1,
      nroDocumento: item.aliasCBU.titular.cuit,
      nombre: item.aliasCBU.titular.nombre,
    };

    this.titularCBU = respCbu;
    this.formBeneficiario.patchValue(respCbu);
  }

  buscarBeneficiario(item) {
    if (item) {
      if (item.length === 11) {
        this.benefService.buscarCuitAfip(item).subscribe(
          (data) => {
            let benef = {};
            if (data.datosGenerales.apellido) {
              benef = {
                apellido: data.datosGenerales.apellido,
                nombre: data.datosGenerales.nombre,
              };
            } else {
              benef = {
                apellido: data.datosGenerales.razonSocial,
              };
            }

            this.beneficiario = benef;
            this.cuitValid = true;
            this.failBenef = false;
          },
          (err) => {
            this.cuitValid = false;
            this.failBenef = true;
            this.beneficiario = null;
          }
        );
      } else {
        this.cuitValid = false;
        this.failBenef = false;
      }
    }
  }

  buscarFacultado(item) {
    if (item) {
      if (item.length === 11) {
        this.benefService.buscarCuitAfip(item).subscribe(
          (data) => {
            const benef = {
              apellido: data.datosGenerales.apellido,
              nombre: data.datosGenerales.nombre,
            };

            this.facultado = benef;
            this.cuitFacultadoValid = true;
          },
          (err) => {
            this.cuitFacultadoValid = false;
          }
        );
      } else {
        this.cuitFacultadoValid = false;
      }
    }
  }

  buscarCbu(cbu) {
    if (cbu.length === 22) {
      this.erorrCBU = null;
      const reqCoelsa = {
        alias: { valor: null },
        cuenta: { nro_cbu: cbu },
      };

      this.opjService.getCuentaDestino(reqCoelsa).subscribe(
        (data: Coelsa) => {
          this.destinatario = data;
          this.erorrCBU = '';
          /// cargamos de forma automatica el destinatario
          if (data.titulares.length === 1) {
            const respCbu = {
              tipoDocumento: 1,
              nroDocumento: this.destinatario.titulares[0].cuit,
              nombre: this.destinatario.titulares[0].nombre,
            };

            this.titularCBU = respCbu;
            this.formBeneficiario.patchValue(respCbu);
          }
        },
        (error) => {
          this.erorrCBU = 'No se pudo encontrar el CBU ingresado';
        }
      );

      this.cuitValid = true;
    } else {
      this.cuitValid = false;
    }
  }

  buscarAlias(alias) {
    if (alias.length > 4) {
      this.erorrCBU = null;
      const reqCoelsa = {
        alias: { valor: alias },
        cuenta: { nro_cbu: null },
      };

      this.opjService.getCuentaDestino(reqCoelsa).subscribe(
        (data: Coelsa) => {
          this.destinatario = data;
          this.erorrCBU = '';
          /// cargamos de forma automatica el destinatario
          if (data.titulares.length === 1) {
            const respCbu = {
              tipoDocumento: 1,
              nroDocumento: this.destinatario.titulares[0].cuit,
              nombre: this.destinatario.titulares[0].nombre,
            };

            this.titularCBU = respCbu;
            this.formBeneficiario.patchValue(respCbu);
          }
        },
        (error) => {
          this.erorrCBU = 'No se pudo encontrar el CBU ingresado';
        }
      );

      this.cuitValid = true;
    } else {
      this.cuitValid = false;
    }
  }

  selectTitutalCbu(item) {
    const filtro = this.destinatario.titulares.find((f) => f.cuit === item);
    if (filtro) {
      const respCbu = {
        tipoDocumento: 1,
        nroDocumento: filtro.cuit,
        nombre: filtro.nombre,
      };

      this.titularCBU = respCbu;
      this.formBeneficiario.patchValue(respCbu);
    }
  }

  saveRegister(event: Event) {
    event.preventDefault();

    let cargoFacultado = null;
    if (this.cuitFacultadoValid) {
      cargoFacultado = {
        tipoDocumento: 1,
        numeroDocumento: this.formBeneficiario.value.documentofacultado,
      };
    }

    let istitulaCbu = null;
    let isCbu = null;
    if (this.isDestinarioLoad || this.isTributo) {
      if (this.formBeneficiario.value.otroTitular) {
        istitulaCbu = {
          tipoDocumento: 1,
          numeroDocumento: this.titularCBU.nroDocumento,
        };
      }

      isCbu = this.destinatario.cuenta.nro_cbu;
    }

    const nuevaOpj = {
      tipoOPJ: +this.formAltaOpj.value.tipoOPJ,
      tipoTransferncia: +this.formAltaOpj.value.tipoTransferncia,
      cbuOrigen: this.cuentaOrigen.cbu,
      cbuDestino: isCbu,
      beneficiario: {
        tipoDocumento: 1,
        numeroDocumento: this.formBeneficiario.value.nroDocumento,
      },
      titularCBU: istitulaCbu,
      facultado: cargoFacultado,
      conceptos: this.itemConceptoOpj,
    };

    this.opjService.EditarOpj(this.idOpj, nuevaOpj).subscribe(
      (rel) => {
        // Guardamos los datos en la agenda
        if (this.formCuentaDestino.value.guardaAgenda) {
          const agendaSave = new Agenda();
          agendaSave.aliasCBU = this.destinatario;
          agendaSave.nroDoc = this.titularCBU.nroDocumento;
          agendaSave.mail = this.formCuentaDestino.value.email;
          agendaSave.aliasAgenda = this.formCuentaDestino.value.denominacion;
          (agendaSave.aliasCBU.titular.cuit = this.titularCBU.nroDocumento),
            (agendaSave.aliasCBU.titular.nombre = this.titularCBU.nombre);

          this.agendaService.guardarAgenda(agendaSave).subscribe((data) => {
            this.mostrarMensaje();
          });
        } else {
          this.mostrarMensaje();
        }
      },
      (err) => {
        this.registroError = err.error.detalle;
      }
    );
  }

  mostrarMensaje() {
    this.matSnackBar
      .open('Orden de Pago Judicial Creada Correctamente', 'Ok', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      })
      .afterDismissed()
      .subscribe(() => {
        this.router.navigate(['./cuentas/cuentas-judiciales']);
      });
  }

  private buildForm() {
    this.formAltaOpj = this.formBuilder.group({
      tipoOPJ: ['', [Validators.required]],
      tipoTransferncia: ['', [Validators.required]],
    });

    this.formBeneficiario = this.formBuilder.group({
      nroDocumento: ['', [Validators.required]],
      facultado: [''],
      documentofacultado: [''],
      otroTitular: [''],
    });

    this.formCuentaDestino = this.formBuilder.group({
      tipoDest: ['true', [Validators.required]],
      cbuDestino: [''],
      titular: [''],
      aliasDestino: [''],
      guardaAgenda: [''],
      denominacion: [''],
      email: [''],
    });

    this.formConcepto = this.formBuilder.group({
      idConcepto: [''],
      comentario: ['', [Validators.required]],
      importe: [''],
    });
  }
}
