import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NovedadesService } from 'src/app/core/services/novedades.service';
import { Novedades } from 'src/app/core/model/menu.model';
import { TokenService } from 'src/app/core/services/token.service';
import { TokenModel } from 'src/app/core/model/token.model';
import { JuzgadoService } from 'src/app/core/services/juzgado.service';
import { Juzgado } from 'src/app/core/model/organismo.model';
import { TipoPerfil } from 'src/app/core/model/tipo-usuario.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { NovedadesComponent } from '../novedades/novedades.component';
import { MatDialog } from '@angular/material/dialog';
import { CacheService } from 'src/app/core/services/cache.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css'],
})
export class TopnavComponent implements AfterViewInit, OnInit {
  public pushRightClass: string;
  listJuzgado: Juzgado[];
  cantMessage: number;
  userInfo: TokenModel;

  constructor(
    private juzgadoServ: JuzgadoService,
    public dialog: MatDialog,
    private router: Router,
    private loginSvr: AuthService,
    private tokenService: TokenService,
    private cacheService: CacheService
  ) {}

  ngOnInit() {
    this.userInfo = this.tokenService.getToken();
    this.cantMessage = this.userInfo.novedades;
    this.pushRightClass = 'push-right';

    //validamos si hay ya cargado por cache el juzgado
    let cache = this.cacheService.getCache('juzgados');
    if (cache) {
      this.listJuzgado = cache;
    } else
      this.juzgadoServ.getMisJuzgados().subscribe((data) => {
        this.listJuzgado = data;
        this.cacheService.saveCache('juzgados', data);
      });
  }

  saveJuzgado(item) {
    localStorage.setItem(`juzgado${this.userInfo.nroDocumento}`, item);
  }

  cerrarSesion() {
    this.loginSvr.logout().subscribe((data) => {
      this.loginSvr.limpiarToken();
      this.router.navigate(['/auth/login']);
    });
  }

  mostrarNovedad() {
    this.dialog.open(NovedadesComponent, {
      width: '480px',
      position: {
        top: '10px',
        right: '5px',
      },
    });
  }

  get juzgadoDefault(): string {
    if (
      this.userInfo.roles.find((f) => f.idRol === TipoPerfil.Admin_Colegio) ||
      this.userInfo.roles.find((f) => f.idRol === TipoPerfil.Abogado) ||
      this.userInfo.roles.find(
        (f) => f.idRol === TipoPerfil.SuperIntendente
      ) /*||
          this.userInfo.roles.find(f => f.idRol === TipoPerfil.Supervisor_Org )*/
    ) {
      return '';
    }

    const juz = localStorage.getItem(`juzgado${this.userInfo.nroDocumento}`);
    if (juz) {
      return juz;
    } else {
      if (this.userInfo.juzgadoDefault) {
        return this.userInfo.juzgadoDefault.id;
      } else {
        return '';
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.userInfo) {
    }
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    console.log('demo');
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }
}
