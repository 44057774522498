import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { JuzgadoService } from 'src/app/core/services/juzgado.service';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioJuzgado } from 'src/app/core/model/usuario-juzgado.mode';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccionesService } from 'src/app/core/services/acciones.service';
import { Acciones } from 'src/app/core/model/cuenta.model';
import { Router } from '@angular/router';
import { PerfilesComponent } from '../perfiles/perfiles.component';
import { InfoPerfilUsuario } from 'src/app/core/model/solicitud-perfil.model';

@Component({
  selector: 'app-administradores',
  templateUrl: './administradores.component.html',
  styleUrls: ['./administradores.component.css'],
})
export class AdministradoresComponent implements AfterViewInit {
  registros: UsuarioJuzgado[];
  dataSource: MatTableDataSource<UsuarioJuzgado>;
  displayedColumns: string[] = [
    'dni',
    'nombre',
    'email',
    'juzgadoTitular',
    'rol',
    'estado',
    'actions',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private juzgadoService: JuzgadoService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private accionService: AccionesService,
    public dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource([]);
    this.buscarAdministradores();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  buscarAdministradores() {
    this.juzgadoService
      .ListarAdministradores()
      .subscribe((data: UsuarioJuzgado[]) => {
        this.registros = data;
        this.dataSource = new MatTableDataSource(this.registros);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  ejecutar(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      if (accion.url === 'juzgado/perfiles') {
        const model = JSON.parse(accion.body) as InfoPerfilUsuario;
        const dialogRef = this.dialog
          .open(PerfilesComponent, {
            width: '450px',
            data: model,
          })
          .afterClosed()
          .subscribe((data) => {
            this.buscarAdministradores();
          });
      } else {
        this.router.navigate([accion.url]);
      }
    } else {
      this.accionService.customAccion(accion);
    }
  }
}
