import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  SolicitudCambioOrganismo,
  CambioOrganismo,
} from '../model/solicitud-cambio-organismo.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CambioOrganismoService {
  controller = '/SolicitudesOrganismos';
  constructor(private http: HttpClient) {}

  MisSolicitudes() {
    return this.http.get<SolicitudCambioOrganismo[]>(
      `${environment.apiServiceBaseUri + this.controller}/MisSolicitudes`
    );
  }

  MisPendientes() {
    return this.http.get<SolicitudCambioOrganismo[]>(
      `${environment.apiServiceBaseUri + this.controller}/MisPendientes`
    );
  }

  getSolicitud(id: number) {
    return this.http.get<SolicitudCambioOrganismo>(
      `${environment.apiServiceBaseUri + this.controller}/${id}`
    );
  }

  editarSolicitud(id: number, modelo: CambioOrganismo) {
    return this.http.put<SolicitudCambioOrganismo[]>(
      `${environment.apiServiceBaseUri + this.controller}/${id}`,
      modelo
    );
  }

  borrarSolicitud(id: number) {
    return this.http.delete<SolicitudCambioOrganismo[]>(
      `${environment.apiServiceBaseUri + this.controller}/${id}`
    );
  }

  solicitarCambio(modelo: CambioOrganismo) {
    return this.http.post(
      `${environment.apiServiceBaseUri + this.controller}/`,
      modelo
    );
  }
}
