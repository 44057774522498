<div class="row">
  <div class="col-lg-6">
    <form [formGroup]="form" (ngSubmit)="saveRegister($event)">
      <mat-card>
        <mat-card-header>
          <mat-card-title> Editar mis datos de cuenta </mat-card-title>
          <mat-error *ngIf="this.registroError != ''">
            <br />
            <b> {{ this.registroError }}</b>
          </mat-error>
        </mat-card-header>
        <mat-card-content style="text-align: center">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="nombre" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Apellido</mat-label>
            <input matInput formControlName="apellido" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>DNI</mat-label>
            <input matInput type="text" formControlName="dni" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>CUIT/CUIL</mat-label>
            <input matInput type="text" formControlName="cuit" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>email</mat-label>
            <input matInput formControlName="email" />
          </mat-form-field>
          <br />
        </mat-card-content>
        <mat-card-actions style="text-align: right">
          <button
            mat-stroked-button
            color="warn"
            type="button"
            (click)="cancelarRegistro()"
          >
            Cancelar
          </button>
          <button
            [disabled]="form.invalid"
            mat-raised-button
            color="primary"
            type="submit"
          >
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</div>
