export class Roles {
  idRol: number;
  descripcion: string;
  tsj: boolean;
}

export enum TipoPerfil {
  Admin_Colegio = 1,
  Admin_Juzgado = 2,
  Abogado = 3,
  Operador = 4,
  Auditor = 5,
  Supervisor_Org = 6,
  SuperIntendente = 7,
}
