import { Component, OnInit, Inject } from '@angular/core';
import { TokenModel } from 'src/app/core/model/token.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfilesComponent } from 'src/app/juzgado/perfiles/perfiles.component';
import { Acciones } from 'src/app/core/model/cuenta.model';
import { Router } from '@angular/router';
import { AccionesService } from 'src/app/core/services/acciones.service';

@Component({
  selector: 'app-novedades-criticas',
  templateUrl: './novedades-criticas.component.html',
  styleUrls: ['./novedades-criticas.component.css'],
})
export class NovedadesCriticasComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PerfilesComponent>,
    @Inject(MAT_DIALOG_DATA) public userInfo: TokenModel,
    private router: Router,
    private accionService: AccionesService
  ) {}

  ngOnInit(): void {}

  ejecutar(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      this.router.navigate([accion.url]);
    } else {
      this.accionService.customAccion(accion);
    }
  }
}
