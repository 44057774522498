import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../material/material.module';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { TopnavComponent } from './layout/topnav/topnav.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { LayoutComponent } from './layout/layout.component';
import { SolicitudesComponent } from './solicitudes/solicitudes.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { AuthInterceptor } from './auth.interceptor';
import { AdminComponent } from './admin/admin.component';
import { RegistrarComponent } from './admin/registrar/registrar.component';
import { RegistroEditComponent } from './admin/registro-edit/registro-edit.component';
import { DialogComponent } from './admin/dialog/dialog.component';
import { CircunscripcionComponent } from './abogados/circunscripcion/circunscripcion.component';
import { AccountComponent } from './account/account.component';
import { OperadoresComponent } from './juzgado/operadores/operadores.component';
import { AdministradoresComponent } from './juzgado/administradores/administradores.component';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { CuentasComponent } from './cuentas/cuentas.component';
import { UsuariosComponent } from './juzgado/usuarios/usuarios.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { SolicitudCambioComponent } from './perfil/solicitud-cambio/solicitud-cambio.component';
import { SolicitudRealizadasComponent } from './perfil/solicitud-realizadas/solicitud-realizadas.component';
import { EditarSolicitudComponent } from './perfil/editar-solicitud/editar-solicitud.component';
import { SolicitudPendientesComponent } from './perfil/solicitud-pendientes/solicitud-pendientes.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialDesignFrameworkModule } from '@ajsf/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomDialogComponent } from './custom-dialog/custom-dialog.component';
import { EditarSolicitudCuentaComponent } from './account/editar-solicitud-cuenta/editar-solicitud-cuenta.component';
import { PendienteCambioCuentaComponent } from './account/pendiente-cambio-cuenta/pendiente-cambio-cuenta.component';
import { SolicitudCambioCuentaComponent } from './account/solicitud-cambio-cuenta/solicitud-cambio-cuenta.component';
import { MisSolicitudesCambioCuentaComponent } from './account/mis-solicitudes-cambio-cuenta/mis-solicitudes-cambio-cuenta.component';
import { EditarSolicitudOrganismoComponent } from './organismo/editar-solicitud-organismo/editar-solicitud-organismo.component';
import { SolicitudCambioOrganismoComponent } from './organismo/solicitud-cambio-organismo/solicitud-cambio-organismo.component';
import { PendienteCambioOrganismoComponent } from './organismo/pendiente-cambio-organismo/pendiente-cambio-organismo.component';
import { MisSolicitudesCambioOrganismoComponent } from './organismo/mis-solicitudes-cambio-organismo/mis-solicitudes-cambio-organismo.component';
import { CambioClaveComponent } from './account/cambio-clave/cambio-clave.component';
import { AltaCuentaComponent } from './cuentas/alta-cuenta/alta-cuenta.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { AltaBeneficiarioComponent } from './cuentas/alta-beneficiario/alta-beneficiario.component';
import { MovimientosComponent } from './cuentas/movimientos/movimientos.component';
import { OpjComponent } from './cuentas/opj/opj.component';
import { GestionOpjComponent } from './cuentas/gestion-opj/gestion-opj.component';
import { AutorizarOpjComponent } from './cuentas/autorizar-opj/autorizar-opj.component';
import { OpjAnularComponent } from './cuentas/opj-anular/opj-anular.component';
import { SolicitarCuentaComponent } from './colegio/solicitar-cuenta/solicitar-cuenta.component';
import { RegistroSolicitudCuentaComponent } from './colegio/registro-solicitud-cuenta/registro-solicitud-cuenta.component';
import { AutorizarCuentaComponent } from './colegio/autorizar-cuenta/autorizar-cuenta.component';
import { MisCuentasComponent } from './abogados/mis-cuentas/mis-cuentas.component';
import { TarjetaCoordenadasComponent } from './cuentas/tarjeta-coordenadas/tarjeta-coordenadas.component';
import { HomeComponent } from './home/home.component';
import { EditarSolicitudCircuscripcionComponent } from './circuscripcion/editar-solicitud-circuscripcion/editar-solicitud-circuscripcion.component';
import { MisSolicitudesCircuscripcionComponent } from './circuscripcion/mis-solicitudes-circuscripcion/mis-solicitudes-circuscripcion.component';
import { PendienteCambioCircuscripcionComponent } from './circuscripcion/pendiente-cambio-circuscripcion/pendiente-cambio-circuscripcion.component';
import { SolicitarCambioCircuscripcionComponent } from './circuscripcion/solicitar-cambio-circuscripcion/solicitar-cambio-circuscripcion.component';
import { NovedadesComponent } from './layout/novedades/novedades.component';
import { PerfilesComponent } from './juzgado/perfiles/perfiles.component';
import { NovedadesCriticasComponent } from './layout/novedades-criticas/novedades-criticas.component';
import { GestionJuzgadoComponent } from './organismo/gestion-juzgado/gestion-juzgado.component';
import { ListOrganismoComponent } from './organismo/list-organismo/list-organismo.component';
import { EditOrganismoComponent } from './organismo/edit-organismo/edit-organismo.component';
import { AlertaCambiosComponent } from './organismo/alerta-cambios/alerta-cambios.component';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { OpjsComponent } from './admin/opjs/opjs.component';
import { environment } from '../environments/environment';
import { OpjEditComponent } from './cuentas/opj-edit/opj-edit.component';
import { ReporteIngresoComponent } from './colegio/reporte-ingreso/reporte-ingreso.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    TopnavComponent,
    SidebarComponent,
    SolicitudesComponent,
    AdminComponent,
    RegistrarComponent,
    RegistroEditComponent,
    DialogComponent,
    CircunscripcionComponent,
    AccountComponent,
    OperadoresComponent,
    AdministradoresComponent,
    CuentasComponent,
    UsuariosComponent,
    SolicitudCambioComponent,
    SolicitudRealizadasComponent,
    EditarSolicitudComponent,
    SolicitudPendientesComponent,
    CustomDialogComponent,
    EditarSolicitudCuentaComponent,
    PendienteCambioCuentaComponent,
    SolicitudCambioCuentaComponent,
    MisSolicitudesCambioCuentaComponent,
    EditarSolicitudOrganismoComponent,
    SolicitudCambioOrganismoComponent,
    PendienteCambioOrganismoComponent,
    MisSolicitudesCambioOrganismoComponent,
    CambioClaveComponent,
    AltaCuentaComponent,
    AltaBeneficiarioComponent,
    MovimientosComponent,
    OpjComponent,
    GestionOpjComponent,
    AutorizarOpjComponent,
    OpjAnularComponent,
    SolicitarCuentaComponent,
    RegistroSolicitudCuentaComponent,
    AutorizarCuentaComponent,
    MisCuentasComponent,
    TarjetaCoordenadasComponent,
    HomeComponent,
    EditarSolicitudCircuscripcionComponent,
    MisSolicitudesCircuscripcionComponent,
    PendienteCambioCircuscripcionComponent,
    SolicitarCambioCircuscripcionComponent,
    NovedadesComponent,
    PerfilesComponent,
    NovedadesCriticasComponent,
    GestionJuzgadoComponent,
    ListOrganismoComponent,
    EditOrganismoComponent,
    AlertaCambiosComponent,
    OpjsComponent,
    OpjEditComponent,
    ReporteIngresoComponent,
  ],
  imports: [
    BrowserModule,
    RecaptchaModule,
    AppRoutingModule,
    CommonModule,
    FlexLayoutModule,
    MsalModule.forRoot({
      auth: {
        clientId: environment.AzureClientId, // This is your client ID
        authority: environment.AzureAuthority,
        redirectUri: environment.AzureRedirectUri, // This is your redirect URI ///fEe2T9X6o-swnL.qG~5T~XuG6wu148QfEr
      },
    }),
    MaterialDesignFrameworkModule,
    HttpClientModule,
    BlockUIModule.forRoot({
      message: 'Cargando...',
    }),
    BlockUIHttpModule.forRoot({
      requestFilters: [
        {
          method: 'GET',
          url: /\/Menues/,
        },
        {
          method: 'PUT',
          url: /\/Opjs/,
        },
        {
          method: 'POST',
          url: /\/RefreshToken/,
        },
      ],
    }), // Import Block UI Http Module Usuarios/RefreshToken
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(maskConfig),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatChipsModule,
    MatStepperModule,
    MatRadioModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
