<div *ngIf="!isEdit">
  <form
    [formGroup]="formTarjeta"
    class="formulario"
    autocomplete="off"
    (ngSubmit)="saveRegister($event)"
  >
    <mat-card>
      <mat-card-header>
        <mat-card-title> Asocie su tarjeta de coordenadas </mat-card-title>
        <mat-error *ngIf="this.registroError != ''">
          <br />
          {{ this.registroError }}
        </mat-error>
      </mat-card-header>
      <mat-card-content>
        <div class="card">
          <div class="row">
            <div class="col-6 label-tarjeta">
              <h4><b>Tarjeta N°</b></h4>
            </div>
            <div class="col-6 input-tarjeta">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  formControlName="nroTarjeta"
                  autofocus
                  placeholder="Ingrese #numero"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="card-body">
            <img
              src="/assets/images/tarjeta.png"
              height="130px"
              width="300px"
            />
          </div>
        </div>
        <br />

        <h2>Ingrese las siguientes coordenadas</h2>
        <mat-error *ngIf="isInvalidCoordenada"
          ><h3>{{ isInvalidCoordenada }}</h3></mat-error
        >
        <mat-form-field class="input-coord" appearance="fill">
          <mat-label>{{ this.coordenadas.coord1 }}</mat-label>
          <input
            matInput
            mask="00"
            class="ocultarCoordenadas"
            #inputA
            autocomplete="off"
            (ngModelChange)="nextItemCoord($event)"
            autofocus
            formControlName="coordA"
          />
        </mat-form-field>
        <mat-form-field class="input-coord" appearance="fill">
          <mat-label>{{ this.coordenadas.coord2 }}</mat-label>
          <input
            matInput
            mask="00"
            #inputB
            class="ocultarCoordenadas"
            autocomplete="off"
            formControlName="coordB"
          />
        </mat-form-field>
        <br />
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-stroked-button
          color="warn"
          type="button"
          [routerLink]="['/']"
        >
          Cancelar
        </button>
        <button
          [disabled]="formTarjeta.invalid"
          mat-raised-button
          color="primary"
          type="submit"
        >
          Guardar
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
<div *ngIf="isEdit">
  <form [formGroup]="formEditTarjeta" class="formulario" autocomplete="off">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Actualmente Ud. posee una tarjeta de coordenadas para operar en HB
          Judiciales BPN.
        </mat-card-title>
        <br />
        <mat-error *ngIf="this.registroError != ''">
          <br />
          {{ this.registroError }}
        </mat-error>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-5">
            <div class="card">
              <div class="row">
                <div class="col-8 label-tarjeta">
                  <h4>
                    <b
                      >Tarjeta N° 0000000{{
                        this.formEditTarjeta.value.nroTarjeta
                      }}</b
                    >
                  </h4>
                </div>
              </div>
              <div class="card-body" *ngIf="!isBloqueda">
                <img
                  src="/assets/images/tarjeta.png"
                  style="filter: blur(2px)"
                  height="130px"
                  width="300px"
                />
              </div>
              <div class="card-body" *ngIf="isBloqueda">
                <img
                  src="/assets/images/tarjetaBloqueada.PNG"
                  height="130px"
                  width="300px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="!isBloqueda" class="col-7">
            <div class="margenDenuncia" style="max-width: 350px">
              <p>
                <b>Atención:</b> Si ha extraviado o le han sustraído su tarjeta,
                deberá dirigirse a una sucursal BPN para solicitar una nueva.
              </p>
            </div>
            <a
              *ngFor="let item of acciones"
              class="margenDenuncia"
              style="color: blue; text-decoration: underline"
              mat-flat-button
              (click)="ejecutar(item)"
              >{{ item.titulo }}</a
            >
          </div>
          <div *ngIf="isBloqueda" class="col-7">
            <div class="margenDenuncia" style="max-width: 350px">
              <p>
                Su tarjeta de coordenadas se encuentra <b>bloqueada</b>, le
                rogamos que se comunique con nuestro call center a través de
                nuestra línea gratuita: <strong>0-800-999-3338</strong>.
              </p>
              <p>
                Nuestro horario de atención es de Lunes a Viernes de 08:00 a
                20:00 hrs.
              </p>
              <p>¡Muchas Gracias!</p>
            </div>
            <a
              *ngFor="let item of acciones"
              class="margenDenuncia"
              style="color: blue; text-decoration: underline"
              mat-flat-button
              (click)="ejecutar(item)"
              >{{ item.titulo }}</a
            >
          </div>
        </div>

        <br />
      </mat-card-content>
      <mat-card-actions> </mat-card-actions>
    </mat-card>
  </form>
</div>
