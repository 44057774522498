<h2>Alta de Nuevo Beneficiario</h2>
<mat-vertical-stepper #stepper class="formulario" linear>
  <mat-step [stepControl]="formPersona">
    <form [formGroup]="formPersona">
      <ng-template matStepLabel>Datos Beneficiario</ng-template>
      <br />
      <div class="row">
        <div class="col">
          <mat-form-field class="anchoIzq" appearance="fill">
            <mat-label>Tipo de Documento</mat-label>
            <mat-select formControlName="tipoDocumentoBT">
              <mat-option
                *ngFor="let item of this.listTipodocumento"
                [value]="item.id"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="anchoInput" appearance="fill">
            <mat-label>Nro Documento</mat-label>
            <input
              matInput
              type="text"
              mask="00-00000000-00"
              formControlName="nroDocumento"
            />
          </mat-form-field>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          <mat-form-field class="anchoIzq" appearance="fill">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="nombre" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="anchoInput" appearance="fill">
            <mat-label>Apellido</mat-label>
            <input matInput formControlName="apellido" placeholder="" />
          </mat-form-field>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          <mat-form-field class="anchoIzq" appearance="fill">
            <mat-label>Fecha Nac</mat-label>
            <input
              matInput
              mask="00/00/0000"
              formControlName="fecNacimiento"
              placeholder="dd/MM/aaaa"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="anchoInput" appearance="fill">
            <mat-label>Pais Nacimiento</mat-label>
            <mat-select formControlName="paisNacimiento">
              <mat-option
                *ngFor="let item of this.listPais"
                [value]="item.id"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col" style="width: 40%">
          <mat-label><b>Genero</b></mat-label>
          <br />
          <mat-radio-group
            aria-label="Ingreso un genero"
            formControlName="sexo"
          >
            <mat-radio-button value="M">Hombre</mat-radio-button>
            <mat-radio-button style="margin-left: 10px" value="F"
              >Mujer</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <div class="col">
          <mat-form-field class="anchoInput" appearance="fill">
            <mat-label>Estado Civil</mat-label>
            <mat-select formControlName="estadoCivil">
              <mat-option
                *ngFor="let item of this.listEstadoCivil"
                [value]="item.id"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <br />
      <div>
        <button
          [disabled]="formPersona.invalid"
          matStepperNext
          mat-raised-button
          color="primary"
          type="submit"
        >
          Continuar
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="formContacto">
    <form [formGroup]="formContacto">
      <ng-template matStepLabel>Datos de Contacto</ng-template>
      <br />
      <mat-form-field class="input-width" appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" placeholder="@" />
      </mat-form-field>
      <br />
      <mat-form-field class="input-width" appearance="fill">
        <mat-label>Telefono</mat-label>
        <input
          matInput
          type="tel"
          formControlName="telefono"
          placeholder="(Cod Area) + Numero"
        />
      </mat-form-field>
      <br />
      <div>
        <button mat-raised-button matStepperPrevious>Anterior</button>
        <button
          [disabled]="formContacto.invalid"
          mat-raised-button
          color="primary"
          class="btnNext"
          matStepperNext
        >
          Siguiente
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="formDomicilio">
    <form [formGroup]="formDomicilio">
      <ng-template matStepLabel>Domicilio</ng-template>

      <div class="row">
        <div class="col" style="width: 40%">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Calle</mat-label>
            <input
              matInput
              formControlName="calle"
              placeholder="Nombre de la calle"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Numero</mat-label>
            <input matInput formControlName="nro" />
          </mat-form-field>
        </div>
      </div>

      <div class="row col-8">
        <div class="col">
          <mat-form-field class="input-width-contacto" appearance="fill">
            <mat-label>Piso</mat-label>
            <input matInput type="text" formControlName="piso" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field
            class="input-width-contacto margenBarrio"
            appearance="fill"
          >
            <mat-label>Depto.</mat-label>
            <input matInput formControlName="dpto" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col" style="width: 40%">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Pais</mat-label>
            <mat-select
              formControlName="idPais"
              (selectionChange)="buscarProvincias($event.value)"
            >
              <mat-option
                *ngFor="let item of this.listPais"
                [value]="item.id"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Provincia</mat-label>
            <mat-select
              formControlName="idProvincia"
              (selectionChange)="buscarLocalidad($event.value)"
            >
              <mat-option
                *ngFor="let item of this.listProvincias"
                [value]="item.id"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col" style="width: 40%">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Localidad</mat-label>
            <mat-select
              formControlName="idLocalidad"
              (selectionChange)="buscarCodigoPostal($event.value)"
            >
              <mat-option
                *ngFor="let item of this.listLocalidades"
                [value]="item.id"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Codigo postal</mat-label>
            <input matInput formControlName="codPostal" />
          </mat-form-field>
        </div>
      </div>
      <br />
      <div>
        <button mat-raised-button matStepperPrevious>Anterior</button>
        <button
          [disabled]="formDomicilio.invalid"
          mat-raised-button
          color="primary"
          class="btnNext"
          matStepperNext
        >
          Siguiente
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="formDomicilio">
    <ng-template matStepLabel>Finalizacion</ng-template>
    <br />
    <h2>Para Confirmar con la Operacion, Haga clic en Guardar</h2>
    <br />
    <div>
      <button mat-raised-button matStepperPrevious>Anterior</button>
      <button
        [disabled]="formDomicilio.invalid"
        mat-raised-button
        class="btnNext"
        color="primary"
        (click)="saveRegister($event); stepper.reset()"
      >
        Guardar
      </button>
    </div>
  </mat-step>
</mat-vertical-stepper>
