import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/core/services/token.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/core/util/validators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenModel } from 'src/app/core/model/token.model';

@Component({
  selector: 'app-cambio-clave',
  templateUrl: './cambio-clave.component.html',
  styleUrls: ['./cambio-clave.component.css'],
})
export class CambioClaveComponent implements OnInit {
  form: FormGroup;
  registroError: string;
  hide = true;
  matcher = new MyErrorStateMatcher();
  codigoValidacion: string;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private tokenService: TokenService,
    private router: Router,
    private authService: AuthService,
    private matSnackBar: MatSnackBar
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    const modelUser: TokenModel = this.tokenService.getToken();
    const user = {
      dni: modelUser.documento.descripcion + ' ' + modelUser.nroDocumento,
      claveJudicial: '',
      confirmarClave: '',
    };

    this.form.patchValue(user);
  }

  resetError() {
    this.registroError = '';
  }

  saveCambioClave(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      const registro = this.form.value;

      if (registro.claveJudicial !== registro.confirmarClave) {
        this.registroError = 'La clave y la confirmacion no son iguales';
        return;
      }

      this.authService
        .cambiarClaveHash(registro.claveJudicial, this.codigoValidacion)
        .subscribe(
          (newRegistro) => {
            console.log(newRegistro);
            this.matSnackBar
              .open('Clave cambiada correctamente', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
              })
              .afterDismissed()
              .subscribe(() => {
                this.router.navigate(['./auth/login']);
              });
          },
          (err) => {
            console.log(err);
            this.registroError = err.error.detalle;
          }
        );
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      dni: ['', [Validators.required]],
      claveJudicial: ['', [Validators.required]],
      confirmarClave: ['', [Validators.required]],
    });
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.claveJudicial.value;
    const confirmPass = group.controls.confirmarClave.value;
    return pass === confirmPass ? null : { notSame: true };
  }
}
