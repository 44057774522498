import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Coordenadas, NuevaTarjCoord } from 'src/app/core/model/opjs.model';
import { OpjService } from 'src/app/core/services/opj.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Acciones } from 'src/app/core/model/cuenta.model';
import { AccionesService } from 'src/app/core/services/acciones.service';

@Component({
  selector: 'app-tarjeta-coordenadas',
  templateUrl: './tarjeta-coordenadas.component.html',
  styleUrls: ['./tarjeta-coordenadas.component.css'],
})
export class TarjetaCoordenadasComponent implements OnInit {
  @ViewChild('inputB') coordB: ElementRef;
  formTarjeta: FormGroup;
  formEditTarjeta: FormGroup;
  registroError: string;
  isEdit = false;
  isBloqueda = false;
  acciones: Acciones[];
  coordenadas: Coordenadas = new Coordenadas();
  isInvalidCoordenada = false;

  constructor(
    private formBuilder: FormBuilder,
    private opjService: OpjService,
    private router: Router,
    private accionService: AccionesService,
    private matSnackBar: MatSnackBar
  ) {
    this.buildForm();
    this.accionService.badgeResultado$.subscribe((data) => {
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.opjService.getEstadoCoordenada().subscribe(
      (data) => {
        if (data.idEstado === 1) {
          // Mostrar Pantalla para denunciar la tarjeta
          const nro = {
            nroTarjeta: data.idTarjeta,
          };

          this.formEditTarjeta.patchValue(nro);
          this.acciones = data.acciones;
          console.log(data);
          this.isEdit = true;
        } else {
          this.isInvalidCoordenada = data.descripcionEstado;
          const nro = {
            nroTarjeta: data.idTarjeta,
          };

          this.formEditTarjeta.patchValue(nro);
          this.isEdit = true;
          this.acciones = data.acciones;
          this.isBloqueda = true;
        }
      },
      (err) => {
        // No tiene tarjeta asociada;
        // Randon Letras
        const characters = 'ABCDEFGHI';
        const Lran1 = characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
        const Lran2 = characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
        // Randon Numero
        const Nran1 = Math.floor(Math.random() * 9) + 1;
        const Nran2 = Math.floor(Math.random() * 9) + 1;
        this.coordenadas.coord1 = Lran1 + Nran1;
        this.isEdit = false;
        this.isInvalidCoordenada = false;
        this.coordenadas.coord2 = Lran2 + Nran2;
      }
    );
  }

  nextItemCoord(item) {
    if (item.length === 2) {
      this.coordB.nativeElement.focus();
    }
  }

  saveRegister(event) {
    const tarjeta = new NuevaTarjCoord();
    tarjeta.coord1 = this.coordenadas.coord1;
    tarjeta.coord2 = this.coordenadas.coord2;
    tarjeta.valor1 = +this.formTarjeta.value.coordA;
    tarjeta.valor2 = +this.formTarjeta.value.coordB;
    tarjeta.nroTarjeta = this.formTarjeta.value.nroTarjeta;

    this.opjService.nuevaTarjeta(tarjeta).subscribe(
      (newRegistro) => {
        console.log(newRegistro);
        this.matSnackBar
          .open('Tarjeta Asociada correctamente', 'Ok', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          })
          .afterDismissed()
          .subscribe(() => {
            window.location.reload();
          });
      },
      (err) => {
        console.log(err);
        this.registroError = err.error.detalle;
      }
    );
  }

  ejecutar(accion: Acciones) {
    this.accionService.customAccion(accion);
  }

  private buildForm() {
    this.formTarjeta = this.formBuilder.group({
      nroTarjeta: ['', [Validators.required]],
      coordA: [''],
      coordB: [''],
    });

    this.formEditTarjeta = this.formBuilder.group({
      nroTarjeta: ['', [Validators.required]],
    });
  }
}
