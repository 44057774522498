<h1 class="mat-h1">Gestion de OPJE (Juzgado {{ this.juzgadoDefault }})</h1>

<div class="fixed-printopj" *ngIf="this.selection.selected.length > 0">
  <div class="container">
    <div class="row">
      <div class="row col-xs-offset-9 col-xs-5">
        <button
          mat-raised-button
          class="margenBoton"
          color="primary"
          (click)="imprimirItems($event)"
        >
          Imprimir Seleccion
        </button>
      </div>
    </div>
  </div>
</div>

<mat-accordion class="filter-headers-align">
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> Filtro de Busqueda </mat-panel-title>
      <mat-panel-description>
        Buscar ordenes de pago judiciales
        <mat-icon>filter_alt</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <form [formGroup]="formFiltro">
        <mat-radio-group
          formControlName="tipoAsociacion"
          class="radio-group"
          aria-label="Ingrese Cbu o Alias"
        >
          <mat-radio-button class="item-radio" value="opje"
            >Nro OPJE
          </mat-radio-button>
          <mat-radio-button value="cuenta" checked>Cuenta</mat-radio-button>
          <mat-radio-button class="item-radio" value="cbu"
            >CBU</mat-radio-button
          >
          <mat-radio-button class="item-radio" value="expediente"
            >Expendiente</mat-radio-button
          >
          <mat-radio-button class="item-radio" value="caratula"
            >Caratula</mat-radio-button
          >
          <mat-radio-button class="item-radio" value="operador"
            >Operador</mat-radio-button
          >
          <mat-radio-button class="item-radio" value="estado"
            >Estado</mat-radio-button
          >
        </mat-radio-group>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'cuenta'">
          <br />
          <div class="row">
            <div class="col-sm-2">
              <mat-form-field class="input-width-cuenta" appearance="fill">
                <mat-label>Cuenta</mat-label>
                <input matInput autofocus formControlName="cuenta" />
              </mat-form-field>
            </div>
            <div>
              <h3><b>/</b></h3>
            </div>
            <div class="col-sm-1">
              <mat-form-field class="input-width-cuenta" appearance="fill">
                <mat-label>Sub Cuenta</mat-label>
                <input matInput autofocus formControlName="subcuenta" />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'cbu'">
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>CBU</mat-label>
            <input matInput autofocus formControlName="cbu" />
          </mat-form-field>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'expediente'">
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Expediente</mat-label>
            <input matInput autofocus formControlName="expediente" />
          </mat-form-field>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'caratula'">
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Caratula</mat-label>
            <input matInput autofocus formControlName="caratula" />
          </mat-form-field>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'operador'">
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Operador (Apellido)</mat-label>
            <input matInput autofocus formControlName="operador" />
          </mat-form-field>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'estado'">
          <br />
          <mat-form-field class="input-autocomplete" appearance="fill">
            <mat-label>Estado OPJE</mat-label>
            <mat-select formControlName="estado">
              <mat-option
                *ngFor="let option of this.listEstado"
                [value]="option.id"
                >{{ option.descripcion }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'opje'">
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Nro OPJE</mat-label>
            <input matInput autofocus formControlName="opje" />
          </mat-form-field>
        </div>

        <mat-action-row>
          <button
            mat-raised-button
            color="primary"
            autofocus
            (click)="buildData()"
          >
            Buscar <mat-icon>search</mat-icon>
          </button>
          <button mat-raised-button color="primary" (click)="limpiarBusqueda()">
            Limpiar <mat-icon>cleaning_services</mat-icon>
          </button>
        </mat-action-row>
      </form>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Filtrar Registros </mat-panel-title>
      <mat-panel-description>
        Filtra los datos de la grilla
        <mat-icon>list</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
    </mat-form-field>
  </mat-expansion-panel>
</mat-accordion>

<table
  mat-table
  [dataSource]="dataSource"
  class="full-width-table"
  matSort
  aria-label="Elements"
>
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>Seleccion</th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(element) : null"
        [checked]="selection.isSelected(element)"
        [aria-label]="checkboxLabel(element)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- idOpj Column -->
  <ng-container matColumnDef="idOpj">
    <th mat-header-cell *matHeaderCellDef>#Nro Orden</th>
    <td mat-cell *matCellDef="let element">
      <b>{{ element.idOpj }}</b>
    </td>
  </ng-container>

  <!-- caratula Column -->
  <ng-container matColumnDef="caratula">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Caratula</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.caratula"
      matTooltipPosition="above"
    >
      {{ element.caratula }}
    </td>
  </ng-container>

  <!-- expediente Column -->
  <ng-container matColumnDef="expediente">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Expediente</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.expediente"
      matTooltipPosition="above"
    >
      {{ element.expediente }}
    </td>
  </ng-container>

  <!-- bene Column -->
  <ng-container matColumnDef="cuitBeneficiario">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>CUIT/CUIL Benef</th>
    <td mat-cell *matCellDef="let element">{{ element.cuitBeneficiario }}</td>
  </ng-container>

  <!-- cbu dest Column -->
  <ng-container matColumnDef="cbuDestino">
    <th mat-header-cell *matHeaderCellDef>CBU Dest.</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.cbuDestino"
      matTooltipPosition="above"
    >
      <b color="warn"> {{ element.cbuDestino }} </b>
    </td>
  </ng-container>

  <!-- operador Column -->
  <ng-container matColumnDef="operador">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Operador</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.operador.apellido + ', ' + element.operador.nombre"
      matTooltipPosition="above"
    >
      {{ element.operador.apellido.substring(0, 4) }}...
    </td>
  </ng-container>

  <!-- Monto Column -->
  <ng-container matColumnDef="montoOPJStr">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Monto($)</th>
    <td mat-cell *matCellDef="let element">
      <b style="warn"> {{ element.montoOPJStr }} </b>
    </td>
  </ng-container>

  <!-- tipo Column -->
  <ng-container matColumnDef="tipo">
    <th mat-header-cell *matHeaderCellDef>Tipo</th>
    <td mat-cell *matCellDef="let element">{{ element.tipo }}</td>
  </ng-container>

  <!-- Fecha Alta Column -->
  <ng-container matColumnDef="fechaAlta">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Alta</th>
    <td class="fechaalta" mat-cell *matCellDef="let element">
      {{ element.fechaAlta.substring(0, 10) }}
    </td>
  </ng-container>

  <!-- Fecha Alta Column -->
  <ng-container matColumnDef="estado">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.estado.descripcion"
      matTooltipPosition="above"
      class="color-estado"
    >
      <b>{{ element.estado.descripcionCorta }}</b>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Acciones</th>
    <td mat-cell *matCellDef="let row">
      <button
        mat-icon-button
        color="primary"
        [matMenuTriggerFor]="menu"
        aria-label="Acciones"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          *ngFor="let item of row.acciones"
          mat-menu-item
          (click)="ejecutar(item)"
        >
          <mat-icon [ngClass]="item.cssColor">{{ item.icono }}</mat-icon>
          <span>{{ item.titulo }}</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
</table>

<div *ngIf="resultsLength === 0" style="color: red; text-align: center">
  <h4>No se encontraron datos...</h4>
</div>

<mat-paginator [length]="resultsLength" [pageSize]="25"></mat-paginator>
