import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Sectores } from '../model/juzgado.model';
import { environment } from 'src/environments/environment';
import {
  Beneficiario,
  Pais,
  Provincias,
  Localidades,
  EstadoCivil,
} from '../model/beneficiario.model';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BeneficiarioService {
  constructor(private http: HttpClient) {}

  getBeneficiario(documento) {
    const body = {
      documento,
    };
    return this.http.get<Beneficiario>(
      `${environment.apiServiceBaseUri}/CuentasJudiciales/GetBeneficiario/${documento}/`
    );
  }

  getPais() {
    return this.http.get<Pais[]>(`${environment.apiServiceBaseUri}/Paises/`);
  }

  getProvincia(idPais) {
    return this.http.get<Provincias[]>(
      `${environment.apiServiceBaseUri}/Provincias/${idPais}`
    );
  }

  getLocalidades(idProvincia, idPais) {
    return this.http.get<Localidades[]>(
      `${environment.apiServiceBaseUri}/Localidades/${idProvincia}/${idPais}`
    );
  }

  getCodigoPostal(idPais, idProvincia, idLocalidad) {
    return this.http.get<any>(
      `${environment.apiServiceBaseUri}/CodigosPostales/${idPais}/${idProvincia}/${idLocalidad}`
    );
  }

  buscarCuitAfip(cuit) {
    return this.http.get<any>(
      `${environment.apiServiceBaseUri}/Afip/GetPersonaA5/${cuit}`
    );
  }

  // buscarCuitAfip1(cuit: string) {
  //   // Simular un error de AFIP
  //   const simulatedError = true; 
  
  //   if (simulatedError) {
  //     // Simular un error con el código -1, pero sin el mensaje
  //     return of({
  //       codigoError: -1,  // Simulamos solo el código de error de AFIP
  //     });
  //   } else {
  //     // Hacer la llamada real
  //     return this.http.get<any>(`${environment.apiServiceBaseUri}/Afip/GetPersonaA5/${cuit}`)
  //       .pipe(
  //         catchError(err => {
  //           // Manejo de errores reales
  //           return throwError(() => new Error('Error en la consulta de AFIP'));
  //         })
  //       );
  //   }
  // }


  crearBeneficiario(beneficiario) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/CuentasJudiciales/AltaBeneficiario/`,
      beneficiario
    );
  }

  getEstadoCivil() {
    return this.http.get<EstadoCivil[]>(
      `${environment.apiServiceBaseUri}/EstadosCiviles/`
    );
  }
}
