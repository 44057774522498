import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  SolicitudCambioOrganismo,
  CambioOrganismo,
} from '../model/solicitud-cambio-organismo.model';
import { environment } from 'src/environments/environment';
import {
  SolicitudCircunscripcion,
  CambioCircunscripcion,
} from '../model/solicitud-circuscripcion.model';

@Injectable({
  providedIn: 'root',
})
export class CircuscripcionService {
  controller = '/SolicitudesCircunscripciones';
  constructor(private http: HttpClient) {}

  MisSolicitudes() {
    return this.http.get<SolicitudCircunscripcion[]>(
      `${environment.apiServiceBaseUri + this.controller}`
    );
  }

  MisPendientes() {
    return this.http.get<SolicitudCircunscripcion[]>(
      `${environment.apiServiceBaseUri + this.controller}`
    );
  }

  getSolicitud(id: number) {
    return this.http.get<SolicitudCircunscripcion>(
      `${environment.apiServiceBaseUri + this.controller}/${id}`
    );
  }

  editarSolicitud(id: number, modelo: CambioCircunscripcion) {
    return this.http.put(
      `${environment.apiServiceBaseUri + this.controller}/${id}`,
      modelo
    );
  }

  borrarSolicitud(id: number) {
    return this.http.delete<SolicitudCircunscripcion[]>(
      `${environment.apiServiceBaseUri + this.controller}/${id}`
    );
  }

  solicitarCambio(modelo: CambioCircunscripcion) {
    return this.http.post(
      `${environment.apiServiceBaseUri + this.controller}/`,
      modelo
    );
  }
}
