import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { UsuarioJuzgado } from 'src/app/core/model/usuario-juzgado.mode';
import { JuzgadoService } from 'src/app/core/services/juzgado.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from 'src/app/admin/dialog/dialog.component';
import { Acciones } from 'src/app/core/model/cuenta.model';
import { Router } from '@angular/router';
import { AccionesService } from 'src/app/core/services/acciones.service';
import { InfoPerfilUsuario } from 'src/app/core/model/solicitud-perfil.model';
import { PerfilesComponent } from '../perfiles/perfiles.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
})
export class UsuariosComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<UsuarioJuzgado>;

  registros: UsuarioJuzgado[];
  dataSource = new MatTableDataSource(this.registros);
  displayedColumns: string[] = [
    'dni',
    'nombre',
    'email',
    'juzgadoTitular',
    'rol',
    'estado',
    'actions',
  ];

  constructor(
    private juzgadoService: JuzgadoService,
    private matSnackBar: MatSnackBar,
    private accionService: AccionesService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  ngOnInit(): void {
    this.buscarAdministradores();
    this.accionService.badgeResultado$.subscribe((data) => {
      this.buscarAdministradores();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  buscarAdministradores() {
    this.juzgadoService
      .ListarUsuarioJuzgado()
      .subscribe((data: UsuarioJuzgado[]) => {
        this.registros = data;
        this.dataSource = new MatTableDataSource(this.registros);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  Inhabilitar(usuario: UsuarioJuzgado) {
    this.dialog
      .open(DialogComponent, {
        data: `¿Esta seguro de Inhabilitar a ${
          usuario.apellido + `, ` + usuario.nombre
        } (El usuario no podra volver a ingresar) ?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.juzgadoService.Inhabilitar(usuario).subscribe((data) => {
            this.matSnackBar
              .open('Registro modificado correctamente', 'Ok', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
              })
              .afterDismissed()
              .subscribe(() => {
                this.buscarAdministradores();
              });
          });
        }
      });
  }

  ejecutar(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      if (accion.url === 'juzgado/perfiles') {
        const model = JSON.parse(accion.body) as InfoPerfilUsuario;
        const dialogRef = this.dialog
          .open(PerfilesComponent, {
            width: '450px',
            data: model,
          })
          .afterClosed()
          .subscribe((data) => {
            this.buscarAdministradores();
          });
      } else {
        this.router.navigate([accion.url]);
      }
    } else {
      this.accionService.customAccion(accion);
    }
  }
}
