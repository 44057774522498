export class AltaCuentaJudicial {
  id: number;
  idJuzgado: string;
  idTipoExpediente: string;
  expediente: string;
  anio: string;
  caratula: string;
  sucursal: number;
  sector: number;
  cbu: string;
  fecAlta: Date;
  fecModificacion: Date;
}

export class CuentaJudicial {
  acciones: Acciones[];
  cuenta: string;
  cbu: string;
  caratula: string;
  expediente: string;
  saldo: number;
  saldoStr: string;
  sucursal: string;
  nroCuenta: string;
  subCuenta: string;
  isRestringida: boolean;
}

export class ModelCuentasJudicial {
  cuentas: CuentaJudicial[];
  pagina: number;
  cantidadDePaginas: number;
  registrosPorPagina: number;
}

export interface Acciones {
  idEstado: number;
  descripcion: string;
  titulo: string;
  schemaJson: string;
  url: string;
  verbo: string;
  icono: string;
  body: string;
  cssColor: string;
  requiereConfirmacion: boolean;
}

export interface infoCuenta {
  cuenta: string;
  cbu: string;
  caratula: string;
  expediente: string;
  saldo: number;
  saldoStr: string;
  sucursal: string;
  nroCuenta: string;
  subCuenta: string;
  estado: number;
  idJuzgado: string;
  nombreJuzgado: string;
}

export interface destinoCuenta {
  tipoDoc: string;
  documento: string;
  nombre: string;
  apellido: string;
  banco: string;
}

export class ConceptoOpj {
  importe: number;
  despcipcion: string;
  idConcepto: string;
}

export class FilterCuentas {
  expediente: {
    juzgado: string;
    exp: string;
  };
  caratula: {
    juzgado: string;
    carat: string;
  };
  CBU: string;
  OPJE: string;
  juzgado: string;
  operador: string;
  cta: string;
  subCta: string;
}
