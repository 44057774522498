import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../model/user-info.model';
import {
  Organismo,
  GestionModelOrganismo,
  ModeloValidacion,
} from '../model/organismo.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizmoService {
  constructor(private http: HttpClient) {}

  getOrganismo() {
    return this.http.get<GestionModelOrganismo[]>(
      `${environment.apiServiceBaseUri}/Organismos/`
    );
  }

  getOrganismoItem(id) {
    return this.http.get<Organismo>(
      `${environment.apiServiceBaseUri}/Organismos/${id}`
    );
  }

  nuevoOrganizmo(model) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/Organismos/`,
      model
    );
  }

  EditarOrganismo(id: number, model: Organismo) {
    return this.http.put(
      `${environment.apiServiceBaseUri}/Organismos/${id}`,
      model
    );
  }

  validarCambioOrganismo(id: number, model) {
    return this.http.post<ModeloValidacion[]>(
      `${environment.apiServiceBaseUri}/Organismos/Validar/${id}`,
      model
    );
  }

  AsignarJuzgado(idOrganizmo: number, idJuzgado: string) {
    return this.http.post(
      `${environment.apiServiceBaseUri}/organizmo/AsignarJuzgado`,
      { idOrganizmo, idJuzgado }
    );
  }
}
