import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UsuarioJuzgado } from '../model/usuario-juzgado.mode';
import { Juzgado } from '../model/organismo.model';

@Injectable({
  providedIn: 'root',
})
export class JuzgadoService {
  constructor(private http: HttpClient) {}

  ListarAdministradores() {
    // Este perfil es para el supervisor de organizmo o administrador
    return this.http.get<UsuarioJuzgado[]>(
      `${environment.apiServiceBaseUri}/AdminOrganismos/usuarios`
    );
  }

  ListarUsuarioJuzgado() {
    return this.http.get<UsuarioJuzgado[]>(
      `${environment.apiServiceBaseUri}/AdminSuperintendente/usuarios`
    );
  }

  ListarUsuarioAdmin() {
    return this.http.get<UsuarioJuzgado[]>(
      `${environment.apiServiceBaseUri}/adminSegOficial/usuarios`
    );
  }

  ListarOperadores() {
    return this.http.get<UsuarioJuzgado[]>(
      `${environment.apiServiceBaseUri}/AdminJuzgados/operadores`
    );
  }

  Inhabilitar(usuario: UsuarioJuzgado) {
    return this.http.delete(
      `${environment.apiServiceBaseUri}/Usuarios/${usuario.idUsuario}`
    );
  }

  getJuzgados() {
    return this.http.get<Juzgado[]>(
      `${environment.apiServiceBaseUri}/Juzgados/Juzgados`
    );
  }

  getMisJuzgados() {
    return this.http.get<Juzgado[]>(
      `${environment.apiServiceBaseUri}/Juzgados/MisJuzgados`
    );
  }

  getJuzgadosUsuario() {
    return this.http.get<Juzgado[]>(
      `${environment.apiServiceBaseUri}/Juzgados/Juzgados`
    );
  }
}
