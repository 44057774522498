import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/core/services/account.service';
import { TokenService } from 'src/app/core/services/token.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenModel } from 'src/app/core/model/token.model';
import { Roles } from 'src/app/core/model/tipo-usuario.model';

@Component({
  selector: 'app-solicitud-cambio',
  templateUrl: './solicitud-cambio.component.html',
  styleUrls: ['./solicitud-cambio.component.css'],
})
export class SolicitudCambioComponent implements OnInit {
  form: FormGroup;
  registroError: string;
  hide = true;
  perfiles: Roles[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private accountService: AccountService,
    private tokenService: TokenService,
    private matSnackBar: MatSnackBar
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    const modelUser: TokenModel = this.tokenService.getToken();
    const user = {
      documento: modelUser.documento.descripcion + ' ' + modelUser.nroDocumento,
      perfil: modelUser.roles[0].descripcion,
    };
    this.form.patchValue(user);

    // buscamos los perfiles
    this.accountService.getMisRolesCompatibles().subscribe((data: Roles[]) => {
      this.perfiles = data;
    });
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      /*Validamos que completo los datos de abogado o juzgado*/
      const solicitud = {
        idPerfil: this.form.value.idRol,
        estado: 1,
        detalle: this.form.value.observacion,
      };

      this.accountService.solicitarPerfil(solicitud).subscribe(
        (newRegistro) => {
          console.log(newRegistro);
          this.matSnackBar
            .open('Solicitud enviada correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            })
            .afterDismissed()
            .subscribe(() => {
              this.router.navigate(['/perfil/solicitudes']);
            });
        },
        (err) => {
          console.log(err);
          this.registroError = err.error.detalle;
        }
      );
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      documento: [''],
      perfil: [''],
      observacion: ['', [Validators.required]],
      idRol: [null, [Validators.required]],
    });
  }
}
