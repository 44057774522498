import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SolicitudCambioPerfil } from '../model/solicitud-perfil.model';
import {
  SolicitudCambioCuenta,
  CambioCuenta,
} from '../model/solicitud-cambio-cuenta.model';

@Injectable({
  providedIn: 'root',
})
export class CambioCuentasService {
  controller = '/SolicitudesCambioDeCuentas';
  constructor(private http: HttpClient) {}

  MisCambiosDeCuenta() {
    return this.http.get<SolicitudCambioCuenta[]>(
      `${environment.apiServiceBaseUri + this.controller}/MisCambiosDeCuenta`
    );
  }

  MisPendientes() {
    return this.http.get<SolicitudCambioCuenta[]>(
      `${environment.apiServiceBaseUri + this.controller}/MisPendientes`
    );
  }

  getSolicitud(id: number) {
    return this.http.get<SolicitudCambioCuenta>(
      `${environment.apiServiceBaseUri + this.controller}/${id}`
    );
  }

  editarSolicitud(id: number, modelo: CambioCuenta) {
    return this.http.put(
      `${environment.apiServiceBaseUri + this.controller}/${id}`,
      modelo
    );
  }

  borrarSolicitud(id: number) {
    return this.http.delete(
      `${environment.apiServiceBaseUri + this.controller}/${id}`
    );
  }

  cambiarCuenta(modelo: CambioCuenta) {
    return this.http.post(
      `${environment.apiServiceBaseUri + this.controller}/`,
      modelo
    );
  }
}
