import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService } from '../core/services/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserInfo } from '../core/model/user-info.model';
import { TokenService } from '../core/services/token.service';
import { TokenModel } from '../core/model/token.model';
import { UsuarioService } from '../core/services/usuario.service';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
})
export class AccountComponent implements OnInit {
  form: FormGroup;
  registroError: string;
  hide = true;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private tokenService: TokenService,
    private usuarioService: UsuarioService,
    private matSnackBar: MatSnackBar
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    const modelUser: TokenModel = this.tokenService.getToken();
    const user = {
      nombre: modelUser.nombre,
      apellido: modelUser.apellido,
      dni: modelUser.nroDocumento,
      cuit: modelUser.cuit,
      email: modelUser.email,
    };
    this.form.patchValue(user);
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      /*Validamos que completo los datos de abogado o juzgado*/
      const registro = this.form.value;
      console.log(registro);
      this.usuarioService.EditUsuarioSession(registro).subscribe(
        (datamsn: any) => {
          console.log(datamsn.mensaje);
          this.matSnackBar
            .open(datamsn.mensaje, 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            })
            .afterDismissed()
            .subscribe(() => {
              this.authService.RefreshUsuario().subscribe((ok) => {
                window.location.reload();
              });
            });
        },
        (err) => {
          console.log(err);
          this.registroError = err.error.detalle;
        }
      );
    }
  }

  cancelarRegistro() {
    this.router.navigate(['/']);
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      apellido: ['', [Validators.required]],
      dni: ['', [Validators.required]],
      cuit: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
    });
  }
}
