<div class="row col-xs-offset-5 col-xs-5">
  <div class="col">
    <button
      mat-raised-button
      color="primary"
      [routerLink]="['/admin/registro', 3]"
    >
      <span class="material-icons">person_add</span> Auditor
    </button>
  </div>
  <div class="col" style="margin-left: 10px">
    <button
      mat-raised-button
      color="primary"
      [routerLink]="['/admin/registro', 1]"
    >
      <span class="material-icons">person_add</span> Administrador Colegio
    </button>
  </div>
  <div class="col-xs-2">
    <button
      mat-raised-button
      color="primary"
      [routerLink]="['/admin/registro', 2]"
    >
      <span class="material-icons">person_add</span> SuperIntendernte Juzgado
    </button>
  </div>
</div>
<div>
  <mat-form-field>
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
  </mat-form-field>
</div>

<table
  mat-table
  [dataSource]="dataSource"
  class="full-width-table"
  matSort
  aria-label="Elements"
>
  <!-- nroDocumento Column -->
  <ng-container matColumnDef="dni">
    <th mat-header-cell *matHeaderCellDef>Nro Documento</th>
    <td mat-cell *matCellDef="let element">
      <b>{{ element.nroDocumento }}</b>
    </td>
  </ng-container>

  <!-- nombre Column -->
  <ng-container matColumnDef="nombre">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Completo</th>
    <td mat-cell *matCellDef="let element">
      {{ element.nombre + ", " + element.apellido }}
    </td>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>

  <!-- Perfil Column -->
  <ng-container matColumnDef="rol">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Perfil</th>
    <td mat-cell *matCellDef="let element">
      <b> {{ element.rolDefault.descripcion }} </b>
    </td>
  </ng-container>

  <!-- estado Column -->
  <ng-container matColumnDef="estado">
    <th mat-header-cell *matHeaderCellDef>Estado</th>
    <td mat-cell *matCellDef="let element">{{ element.estado.descripcion }}</td>
  </ng-container>

  <!-- <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Acciones </th>
    <td mat-cell *matCellDef="let row">
    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" aria-label="Acciones">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="Inhabilitar(row)" >
        <mat-icon color="warn" >block</mat-icon>
        <span>Inhabilitar</span>
      </button>
      </mat-menu>
    </td>
  </ng-container> -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Acciones</th>
    <td mat-cell *matCellDef="let row">
      <button
        mat-icon-button
        color="primary"
        [matMenuTriggerFor]="menu"
        aria-label="Acciones"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          *ngFor="let item of row.acciones"
          mat-menu-item
          (click)="ejecutar(item)"
        >
          <mat-icon [ngClass]="item.cssColor">{{ item.icono }}</mat-icon>
          <span>{{ item.titulo }}</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
</table>

<mat-paginator
  #paginator
  [length]="dataSource?.data.length"
  [pageIndex]="0"
  [pageSize]="25"
  [pageSizeOptions]="[25, 50, 100, 250]"
>
</mat-paginator>
