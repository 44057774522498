import { Component, OnInit } from '@angular/core';
import { SolicitudCambioCuenta } from 'src/app/core/model/solicitud-cambio-cuenta.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/admin/dialog/dialog.component';
import { CambioCuentasService } from 'src/app/core/services/cambio-cuentas.service';
import { TokenService } from 'src/app/core/services/token.service';
import { TokenModel } from 'src/app/core/model/token.model';

@Component({
  selector: 'app-mis-solicitudes-cambio-cuenta',
  templateUrl: './mis-solicitudes-cambio-cuenta.component.html',
  styleUrls: ['./mis-solicitudes-cambio-cuenta.component.css'],
})
export class MisSolicitudesCambioCuentaComponent implements OnInit {
  registros: SolicitudCambioCuenta[];
  registroError: string;
  dataSource = new MatTableDataSource(this.registros);
  displayedColumns = [
    'id',
    'rolDefault',
    'perfil',
    'fechaSolicitud',
    'estado',
    'actions',
  ];
  regNuevo = true;
  modelUser: TokenModel;

  constructor(
    private cambioCuenta: CambioCuentasService,
    private tokenService: TokenService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    //Buscamos en el toquen si el usuario es del tsj
    this.modelUser = this.tokenService.getToken();
    this.buidGrid();
  }

  cancelar(id) {
    this.dialog
      .open(DialogComponent, {
        data: `¿Esta seguro de cancelar la solicitud #${id} ?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.cambioCuenta.borrarSolicitud(id).subscribe(
            (reg) => {
              this.matSnackBar
                .open('Solicitud cancelada correctamente', 'Ok', {
                  duration: 3000,
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                })
                .afterDismissed()
                .subscribe(() => {
                  this.buidGrid();
                });
            },
            (err) => {
              console.log(err);
              this.registroError = err.error.ExceptionMessage;
            }
          );
        }
      });
  }

  buidGrid() {
    this.cambioCuenta.MisCambiosDeCuenta().subscribe((reg) => {
      this.registros = reg;
      if (reg.find((f) => f.estado.descripcion === 'Iniciada')) {
        this.regNuevo = false;
      } else {
        this.regNuevo = true;
      }
      this.dataSource = new MatTableDataSource(this.registros);
    });
  }
}
