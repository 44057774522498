import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MyErrorStateMatcher } from './../../core/util/validators';
import { AccountService } from '../../core/services/account.service';
import { UsuarioService } from '../../core/services/usuario.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TipoPerfil } from 'src/app/core/model/tipo-usuario.model';

@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.css'],
})
export class RegistrarComponent implements OnInit {
  form: FormGroup;
  registroError: string;
  hide = true;
  matcher = new MyErrorStateMatcher();
  descPerfil: string;
  idPerfil: string;
  listCircuns: any[];
  panelAbogado: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private accountService: AccountService,
    private usuarioService: UsuarioService,
    private matSnackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.idPerfil = params.id;
      if (params.id === '1') {
        this.descPerfil = 'Administrador Colegio de Abogados';
        this.panelAbogado = true;
      }
      if (params.id === '2') {
        this.panelAbogado = false;
        this.descPerfil = 'Supervisor de Superintendencia';
      }

      if (params.id === '3') {
        this.panelAbogado = false;
        this.descPerfil = 'Auditor';
      }
    });

    // buscamos los juzgados
    this.accountService.getCircuscripcion().subscribe((data: any[]) => {
      this.listCircuns = data;
      console.log(this.listCircuns);
    });
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      /*Validamos que completo los datos de abogado o juzgado*/
      const registro = this.form.value;
      // Valida que el perfil sea abogado o colegio
      console.log(registro.perfil);
      if (this.idPerfil === '1') {
        if (registro.idCircunscripcion === '') {
          this.registroError = 'debe seleccionar una circunscripcion';
          return;
        }
        registro.perfil = TipoPerfil.Admin_Colegio;
      }
      if (this.idPerfil === '2') {
        registro.perfil = TipoPerfil.SuperIntendente;
        registro.idCircunscripcion = null;
      }

      if (this.idPerfil === '3') {
        registro.perfil = TipoPerfil.Auditor;
        registro.idCircunscripcion = null;
      }

      this.usuarioService.createUsuarioRoot(registro).subscribe(
        (newRegistro) => {
          console.log(newRegistro);
          this.matSnackBar
            .open('Registro generado correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            })
            .afterDismissed()
            .subscribe(() => {
              this.router.navigate(['./admin']);
            });
        },
        (err) => {
          console.log(err);
          this.registroError = err.error.ExceptionMessage;
        }
      );
    }
  }

  cancelarRegistro() {
    this.router.navigate(['admin/']);
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      tipoDoc: 1,
      nombre: ['', [Validators.required]],
      apellido: ['', [Validators.required]],
      nroDoc: ['', [Validators.required]],
      descPerfil: '',
      perfil: 0,
      email: ['', [Validators.email]],
      idCircunscripcion: '',
    });
  }
}
