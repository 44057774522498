import { Estado, GetionOpj } from './gestion-opj.model';
import { Acciones } from './cuenta.model';

export interface Concepto {
  codigoConcepto: number;
  descripcion: string;
  gravado: boolean;
  taseable: boolean;
}

export class TipoOpj {
  idTipoOpj: number;
  descripcion: string;
  cbuDestino: string;
  cuitcuil: string;
  editable: boolean;
  conceptos: Concepto[];
  tipoTransferenciasPermitidas: TipoTransferencias[];
}

export interface TipoTransferencias {
  id: number;
  descripcion: string;
}

export class ResumenOPJ {
  CtaOrigen: string;
  TipoOPJ: string;
  TipoTransf: string;
  cbu: string;
  titular: string;
  importe: number;
}

export interface Beneficiario {
  tipoDocumento: number;
  numeroDocumento: string;
}

export interface TitularCBU {
  tipoDocumento: number;
  numeroDocumento: string;
}

export interface Facultado {
  tipoDocumento: number;
  numeroDocumento: string;
}

export interface Concepto {
  idConcepto: number;
  importe: number;
}

export interface OpjModel {
  idOpj: number;
  tipoOPJ: number;
  tipoTransferncia: number;
  cbuOrigen: string;
  comentarios: string;
  cuenta: string;
  cbuDestino: string;
  beneficiario: Beneficiario;
  titularCBU: TitularCBU;
  facultado: Facultado;
  conceptos: Concepto[];
}

export enum FormaPagoOPJ {
  transferenciaBPN = 1,
  cobroCaja = 2,
  otrosBancos = 3,
}

export enum estadoOpj {
  Autorizar = 2,
  Rechazar = 3,
  Anular = 6,
}

export class Coordenadas {
  coord1: string;
  valorCoord1: number;
  coord2: string;
  valorCoord2: number;
}

export class NuevaTarjCoord {
  nroTarjeta: string;
  coord1: string;
  coord2: string;
  valor1: number;
  valor2: number;
}

export interface RespOpjs {
  idOpj: number;
  estado: Estado;
  detalle: string;
  acciones: Acciones[];
}

export class ModelOpjs {
  opjs: GetionOpj[];
  pagina: number;
  regXPag: number;
  maxAutorizarPermitido: number;
  totalRegistros: number;
}
