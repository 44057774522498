import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MovimientoCuentas } from 'src/app/core/model/movimientos.model';
import { CuentaJudicialService } from 'src/app/core/services/cuentaJudicial.service';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { merge, of as observableOf } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class MovimientosComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<MovimientoCuentas>;

  resultsLength = 0;
  formFiltro: FormGroup;
  isLoadingResults = true;
  isRateLimitReached = false;
  cuenta: string;
  subcta: string;
  fechaDesde: string;
  fechaHasta: string;

  registros: MovimientoCuentas;
  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'fecha',
    'concepto',
    'referencia',
    'importe',
    'saldo',
  ];

  constructor(
    private cuentasService: CuentaJudicialService,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.buildForm();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.cuentasService.getMovimientos(
            this.cuenta,
            this.subcta,
            this.fechaDesde,
            this.fechaHasta,
            this.paginator.pageIndex
          );
        }),
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength =
            data.paginacion.cant_Paginas * data.paginacion.regXPagina;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((cuentas: MovimientoCuentas) => {
        this.dataSource = new MatTableDataSource(cuentas.movimientos);
      });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.cuenta = params.cta;
      this.subcta = params.subcta;
      this.fechaDesde = params.fechaDesde;
      this.fechaHasta = params.fechaHasta;
    });
  }

  imprimirReporte() {
    // this.fechaDesde = this.formFiltro.value.desde.format('yyyy-MM-DD');
    // this.fechaHasta = this.formFiltro.value.hasta.format('yyyy-MM-DD');

    this.cuentasService
      .getImprimirMovimientos(
        this.cuenta,
        this.subcta,
        this.fechaDesde,
        this.fechaHasta,
        this.resultsLength
      )
      .subscribe(
        (datos) => {
          const blob: any = new Blob([datos], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          //this.ngAfterViewInit();
        },
        (err) => {
          this.matSnackBar.open(
            'No se encontraron datos para el periodo seleccionado',
            'Ok',
            {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            }
          );
        }
      );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  refrescarGrilla() {
    this.fechaDesde = this.formFiltro.value.desde.format('yyyy-MM-DD');
    this.fechaHasta = this.formFiltro.value.hasta.format('yyyy-MM-DD');
    this.ngAfterViewInit();
  }

  private buildForm() {
    this.formFiltro = this.formBuilder.group({
      desde: ['', [Validators.required]],
      hasta: ['', [Validators.required]],
    });
  }
}
