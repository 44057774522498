<h2 mat-dialog-title>
  <mat-icon class="notify">notification_important</mat-icon> Notificacion
  Importante
</h2>
<mat-dialog-content>
  <h3>
    Sr/a <b>{{ this.userInfo.apellido + ", " + this.userInfo.nombre }}</b> usted
    tiene novedades importantes !
  </h3>
  <div class="margenBoton" *ngFor="let item of this.userInfo.novedadesCriticas">
    <mat-divider></mat-divider>
    <div class="boxAgenda">
      <div class="margenBoton">
        <h3 *ngIf="item.vistaPorUsuario" style="padding-top: 10px">
          <mat-icon class="notify-read">email</mat-icon>
          {{ item.titulo }}
          <button
            class="margen"
            *ngIf="item.acciones.length > 0"
            mat-icon-button
            color="primary"
            [matMenuTriggerFor]="menu"
            aria-label="Acciones"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </h3>
        <h3 *ngIf="!item.vistaPorUsuario" style="padding-top: 10px">
          <mat-icon class="notify-unread">mark_email_unread</mat-icon>
          <b>{{ item.titulo }}</b>
          <button
            class="margen"
            *ngIf="item.acciones.length > 0"
            mat-icon-button
            color="primary"
            [matMenuTriggerFor]="menu"
            aria-label="Acciones"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </h3>
        <p style="color: rgb(139, 139, 139); margin-top: -10px">
          {{ item.descripcion }}
        </p>

        <mat-menu #menu="matMenu">
          <button
            *ngFor="let a of item.acciones"
            mat-menu-item
            (click)="ejecutar(a)"
          >
            <mat-icon [ngClass]="a.cssColor">{{ a.icono }}</mat-icon>
            <span>{{ a.descripcion }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
