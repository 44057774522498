<div class="row col-xs-offset-9 col-xs-5">
  <div class="col">
    <button
      mat-raised-button
      color="primary"
      [routerLink]="['/organismo/nuevo-organismo']"
    >
      <span class="material-icons">library_books</span> Nuevo organismo
    </button>
  </div>
</div>
<br />

<table
  mat-table
  #outerSort="matSort"
  [dataSource]="dataSource"
  multiTemplateDataRows
  class="mat-elevation-z8"
  matSort
>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>#Nro Organismo</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button color="primary" (click)="toggleRow(element)">
        <mat-icon>menu_open</mat-icon></button
      >{{ element.id }}
    </td>
  </ng-container>

  <ng-container matColumnDef="descripcion">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Organismo</th>
    <td mat-cell *matCellDef="let element">
      <b>{{ element.descripcion }}</b>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Acciones</th>
    <td mat-cell *matCellDef="let row">
      <button
        mat-icon-button
        color="primary"
        [matMenuTriggerFor]="menu"
        aria-label="Acciones"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          *ngFor="let item of row.acciones"
          mat-menu-item
          (click)="ejecutar(item)"
        >
          <mat-icon [ngClass]="item.cssColor">{{ item.icono }}</mat-icon>
          <span>{{ item.titulo }}</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="columnsToDisplay.length"
    >
      <div
        class="example-element-detail"
        *ngIf="element.juzgados?.data.length"
        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
      >
        <div
          class="inner-table mat-elevation-z8"
          *ngIf="element.juzgados?.data.length"
        >
          <mat-form-field>
            <input
              matInput
              (keyup)="applyFilter($event.target.value)"
              placeholder="Filtrar"
            />
          </mat-form-field>
          <table
            #innerTables
            mat-table
            #innerSort="matSort"
            [dataSource]="element.juzgados"
            matSort
          >
            <ng-container
              matColumnDef="{{ innerColumn }}"
              *ngFor="let innerColumn of innerDisplayedColumns"
            >
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ innerColumn }}
              </th>
              <td class="sub-grid-style" mat-cell *matCellDef="let element">
                {{ element[innerColumn] }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: innerDisplayedColumns"
            ></tr>
          </table>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: columnsToDisplay"
    [class.example-element-row]="element.juzgados?.data.length"
    [class.example-expanded-row]="expandedElement === element"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    class="example-detail-row"
  ></tr>
</table>
