import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { SolicitudRegistro } from '../../core/model/solicitudes.model';
import { AbogadoService } from '../../core/services/abogado.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../admin/dialog/dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbogadosCircunscripcion } from 'src/app/core/model/Circunscripcion.model';
import { Router } from '@angular/router';
import { AccionesService } from 'src/app/core/services/acciones.service';
import { Acciones } from 'src/app/core/model/cuenta.model';

@Component({
  selector: 'app-circunscripcion',
  templateUrl: './circunscripcion.component.html',
  styleUrls: ['./circunscripcion.component.css'],
})
export class CircunscripcionComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<AbogadosCircunscripcion>;
  registros: AbogadosCircunscripcion[];
  dataSource = new MatTableDataSource(this.registros);

  constructor(
    private abService: AbogadoService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private accionService: AccionesService,
    private dialog: MatDialog
  ) {}

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'dni',
    'nombre',
    'email',
    'estado',
    'matricula',
    'actions',
  ];

  ngOnInit() {
    this.builData();
    this.accionService.badgeResultado$.subscribe((data) => {
      this.builData();
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  builData() {
    this.abService.getAbogadosCircunscripcion().subscribe((data: any[]) => {
      this.registros = data;
      this.dataSource = new MatTableDataSource(this.registros);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ejecutar(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      this.router.navigate([accion.url]);
    } else {
      this.accionService.customAccion(accion);
    }
  }
}
