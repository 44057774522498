import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Circunscripcion, TokenModel } from 'src/app/core/model/token.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService } from 'src/app/core/services/account.service';
import { CircuscripcionService } from 'src/app/core/services/circuscripcion.service';
import { TokenService } from 'src/app/core/services/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SolicitudCircunscripcion } from 'src/app/core/model/solicitud-circuscripcion.model';

@Component({
  selector: 'app-editar-solicitud-circuscripcion',
  templateUrl: './editar-solicitud-circuscripcion.component.html',
  styleUrls: ['./editar-solicitud-circuscripcion.component.css'],
})
export class EditarSolicitudCircuscripcionComponent implements OnInit {
  form: FormGroup;
  registroError: string;
  hide = true;
  circunscripcion: Circunscripcion[];
  id: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private accountService: AccountService,
    private circService: CircuscripcionService,
    private tokenService: TokenService,
    private matSnackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params.id;
      const modelUser: TokenModel = this.tokenService.getToken();
      // buscamos los perfiles
      this.accountService
        .getCircuscripcion()
        .subscribe((data: Circunscripcion[]) => {
          this.circunscripcion = data;
          this.circService
            .getSolicitud(this.id)
            .subscribe((sol: SolicitudCircunscripcion) => {
              const user = {
                documento:
                  modelUser.documento.descripcion +
                  ' ' +
                  modelUser.nroDocumento,
                circunscripcion: modelUser.circunscripcion.descripcion,
                matricula: modelUser.matricula,
                idCircunscripcion: sol.circunscripcion.id,
                matriculaNro: sol.matriculaNro,
                detalle: sol.detalle,
              };

              this.form.patchValue(user);
            });
        });
    });
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      /*Validamos que completo los datos de abogado o juzgado*/
      const solicitud = {
        circunscripcion: this.form.value.idCircunscripcion,
        matriculaNro: this.form.value.matriculaNro,
        detalle: this.form.value.observacion,
      };

      this.circService.editarSolicitud(this.id, solicitud).subscribe(
        (newRegistro) => {
          console.log(newRegistro);
          this.matSnackBar
            .open('Solicitud editada correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            })
            .afterDismissed()
            .subscribe(() => {
              this.router.navigate(['./circunscripcion/mis-solicitudes']);
            });
        },
        (err) => {
          console.log(err);
          this.registroError = err.error.Detalle;
        }
      );
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      documento: [''],
      circunscripcion: [''],
      matricula: [''],
      observacion: ['', [Validators.required]],
      idCircunscripcion: [null, [Validators.required]],
      matriculaNro: [null],
    });
  }
}
