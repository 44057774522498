<div class="row">
  <div class="col-lg-6">
    <form [formGroup]="form" (ngSubmit)="saveRegister($event)">
      <mat-card>
        <mat-card-header>
          <mat-card-title> Editar Solicitud Cambio de Perfil </mat-card-title>
          <mat-error *ngIf="this.registroError != ''">
            <br />
            {{ this.registroError }}
          </mat-error>
        </mat-card-header>
        <mat-card-content style="text-align: center">
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Nro Documento</mat-label>
            <input matInput readonly type="text" formControlName="documento" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Perfil Actual</mat-label>
            <input matInput readonly formControlName="perfil" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Solicitar Perfil</mat-label>
            <mat-select formControlName="idRol">
              <mat-option
                *ngFor="let item of this.perfiles"
                [value]="item.idRol"
                >{{ item.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Observacion</mat-label>
            <textarea
              matInput
              placeholder="ingrese alguna observacion"
              formControlName="observacion"
            ></textarea>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions style="text-align: right">
          <button
            mat-stroked-button
            color="warn"
            type="button"
            [routerLink]="['/']"
          >
            Cancelar
          </button>
          <button
            [disabled]="form.invalid"
            mat-raised-button
            color="primary"
            type="submit"
          >
            Editar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</div>
