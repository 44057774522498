import { Component, OnInit } from '@angular/core';
import { CambioCuentasService } from 'src/app/core/services/cambio-cuentas.service';
import { AccountService } from 'src/app/core/services/account.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Roles } from 'src/app/core/model/tipo-usuario.model';
import { Juzgado, Organismo } from 'src/app/core/model/organismo.model';
import { Circunscripcion, TokenModel } from 'src/app/core/model/token.model';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/core/services/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CambioCuenta } from 'src/app/core/model/solicitud-cambio-cuenta.model';

@Component({
  selector: 'app-solicitud-cambio-cuenta',
  templateUrl: './solicitud-cambio-cuenta.component.html',
  styleUrls: ['./solicitud-cambio-cuenta.component.css'],
})
export class SolicitudCambioCuentaComponent implements OnInit {
  form: FormGroup;
  registroError: string;
  hide = true;
  tipoRoles: Roles[];
  listJuzgado: Juzgado[];
  listOrganizmo: Organismo[];
  listCircuns: Circunscripcion[];
  panelAbogado: boolean;
  panelJuzgado: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private cambioService: CambioCuentasService,
    private accountService: AccountService,
    private tokenService: TokenService,
    private matSnackBar: MatSnackBar
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    const modelUser: TokenModel = this.tokenService.getToken();
    const user = {
      documento: modelUser.documento.descripcion + ' ' + modelUser.nroDocumento,
      perfil: modelUser.roles[0].descripcion,
    };
    this.form.patchValue(user);

    // buscamos los perfiles
    this.accountService.getTipoUsuario().subscribe((data: Roles[]) => {
      this.tipoRoles = data;
    });

    //esto es un demo

    // buscamos los organizmos
    this.accountService.getOrganizmo().subscribe((data: Organismo[]) => {
      this.listOrganizmo = data;
      console.log(this.listOrganizmo);
    });

    // buscamos las circuscripciones
    this.accountService.getCircuscripcion().subscribe((data: any[]) => {
      this.listCircuns = data;
      console.log(this.listCircuns);
    });
  }

  selectPerfil(idRol: number) {
    const perfil = this.tipoRoles.find((f) => f.idRol === idRol);

    if (perfil.tsj) {
      this.panelAbogado = false;
      this.panelJuzgado = true;
    } else {
      this.panelAbogado = true;
      this.panelJuzgado = false;
    }
  }

  selectJuzgado(item) {
    this.listJuzgado = this.listOrganizmo.find((f) => f.id === item).juzgados;
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      /*Validamos que completo los datos de abogado o juzgado*/
      const solicitud: CambioCuenta = {
        perfilSolicitado: this.form.value.idRol,
        estadoSolicitud: 1,
        juzgadoTitular: this.form.value.juzgadoTitular,
        circunscripcion: this.form.value.idCircunscripcion,
        organismo: this.form.value.idOrganismo,
        matriculaNro: this.form.value.matricula,
        cuit: this.form.value.cuit,
        detalle: this.form.value.observacion,
      };

      this.cambioService.cambiarCuenta(solicitud).subscribe(
        (newRegistro) => {
          console.log(newRegistro);
          this.matSnackBar
            .open('Solicitud enviada correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            })
            .afterDismissed()
            .subscribe(() => {
              this.router.navigate(['./']);
            });
        },
        (err) => {
          console.log(err);
          this.registroError = err.error.detalle;
        }
      );
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      documento: [''],
      perfil: [''],
      observacion: ['', [Validators.required]],
      idRol: [null, [Validators.required]],
      idCircunscripcion: null,
      matricula: '',
      cuit: '',
      juzgadoTitular: '',
      idOrganismo: null,
    });
  }
}
