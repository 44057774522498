import { Injectable } from '@angular/core';
import { Menu } from '../../core/model/menu.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private http: HttpClient) {}

  getMenuUsuario(juzgado) {
    return this.http.get<Menu[]>(
      `${environment.apiServiceBaseUri}/Menues/xRol?juzgado=${juzgado}`
    );
  }
}
