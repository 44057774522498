import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Roles } from 'src/app/core/model/tipo-usuario.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService } from 'src/app/core/services/account.service';
import { TokenService } from 'src/app/core/services/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenModel } from 'src/app/core/model/token.model';

@Component({
  selector: 'app-editar-solicitud',
  templateUrl: './editar-solicitud.component.html',
  styleUrls: ['./editar-solicitud.component.css'],
})
export class EditarSolicitudComponent implements OnInit {
  form: FormGroup;
  registroError: string;
  hide = true;
  perfiles: Roles[];
  id: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private accountService: AccountService,
    private tokenService: TokenService,
    private matSnackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params.id;
      const modelUser: TokenModel = this.tokenService.getToken();
      // buscamos los perfiles
      this.accountService
        .getMisRolesCompatibles()
        .subscribe((data: Roles[]) => {
          this.perfiles = data;
          this.accountService.getSolicitud(this.id).subscribe((sol) => {
            const user = {
              documento:
                modelUser.documento.descripcion + ' ' + modelUser.nroDocumento,
              perfil: modelUser.roles[0].descripcion,
              idRol: sol.perfil.idRol,
              observacion: sol.detalle,
            };

            this.form.patchValue(user);
          });
        });
    });
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      /*Validamos que completo los datos de abogado o juzgado*/
      const solicitud = {
        idPerfil: this.form.value.idRol,
        estado: 1,
        detalle: this.form.value.observacion,
      };

      this.accountService.editarSolicitud(this.id, solicitud).subscribe(
        (newRegistro) => {
          console.log(newRegistro);
          this.matSnackBar
            .open('Solicitud editada correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            })
            .afterDismissed()
            .subscribe(() => {
              this.router.navigate(['./']);
            });
        },
        (err) => {
          console.log(err);
          this.registroError = err.error.ExceptionMessage;
        }
      );
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      documento: [''],
      perfil: [''],
      observacion: [''],
      idRol: [null, [Validators.required]],
    });
  }
}
