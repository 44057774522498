import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuService } from '../../core/services/menu.service';
import { Menu, Submenu } from '../../core/model/menu.model';
import { Router } from '@angular/router';
import { SolicitudRegistro } from '../../core/model/solicitudes.model';
import { Subscription, Observable, timer } from 'rxjs';
import { TokenService } from 'src/app/core/services/token.service';
import { TokenModel } from 'src/app/core/model/token.model';
import { TipoPerfil } from 'src/app/core/model/tipo-usuario.model';
import { SolicitudesService } from 'src/app/core/services/solicitudes.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionComponent } from 'src/app/auth/session/session.component';
import { NovedadesCriticasComponent } from '../novedades-criticas/novedades-criticas.component';
import { CacheService } from 'src/app/core/services/cache.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuUser: Menu[];
  userInfo: TokenModel;
  intentos = 0;
  totalSolicitud: number;

  subscription: Subscription;
  timerBadge: Observable<number> = timer(0, 10000);

  constructor(
    private menuService: MenuService,
    public dialog: MatDialog,
    private tokenService: TokenService,
    private cacheService: CacheService,
    private solicitudService: SolicitudesService,
    private router: Router
  ) {
    this.solicitudService.solicitudes$.subscribe((registros) => {
      this.totalSolicitud = registros.length;
    });
  }

  get juzgadoDefault(): string {
    if (
      this.userInfo.roles.find((f) => f.idRol === TipoPerfil.Admin_Colegio) ||
      this.userInfo.roles.find((f) => f.idRol === TipoPerfil.Abogado) ||
      this.userInfo.roles.find((f) => f.idRol === TipoPerfil.SuperIntendente)
    ) {
      return '';
    }

    const juz = localStorage.getItem(`juzgado${this.userInfo.nroDocumento}`);
    if (juz) {
      return juz;
    } else {
      if (this.userInfo.juzgadoDefault) {
        return this.userInfo.juzgadoDefault.id;
      } else {
        return '';
      }
    }
  }

  ngOnInit() {
    // Buscamos la info del usuario
    this.userInfo = this.tokenService.getToken();
    if (this.userInfo.novedadesCriticas.length > 0) {
      const dialogRef = this.dialog.open(NovedadesCriticasComponent, {
        width: '450px',
        data: this.userInfo,
      });
    }

    this.subscription = this.timerBadge.subscribe(() => {
      this.badge();
    });

    //validamos si hay ya cargado por cache el juzgado
    let cache = this.cacheService.getCache('menu');
    if (cache) {
      console.log('el cache',cache);
      this.menuUser = cache;
    } else {
      this.menuService
        .getMenuUsuario(this.juzgadoDefault)
        .subscribe((data: Menu[]) => {
          this.menuUser = data;
          console.log('fue el servicio',cache);
          this.cacheService.saveCache('menu', data);
        });
    }
  }

  evaluarUri(menu: Menu) {
    let retorno = false;
    menu.submenu.forEach((sub) => {
      if (sub.url === this.router.url) {
        retorno = true;
      } else {
        retorno = false;
      }
    });

    return retorno;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  badge() {
    this.menuService.getMenuUsuario(this.juzgadoDefault).subscribe(
      (data: Menu[]) => {
        data.forEach((element) => {
          element.submenu.forEach((s) => {
            this.menuUser.forEach((men) => {
              men.submenu.forEach((m) => {
                if (s.idSubmenu === m.idSubmenu) {
                  if (s.badge !== m.badge) {
                    m.badge = s.badge;
                  }
                }
              });
            });
          });
        });
      },
      (err) => {
        if (err.status === 401) {
          this.intentos += 1;
          if (this.intentos > 2) {
            this.intentos = 0;
            this.subscription.unsubscribe();
            this.dialog
              .open(SessionComponent, {})
              .afterClosed()
              .subscribe((renueva) => {
                if (renueva) {
                  this.subscription = this.timerBadge.subscribe(() => {
                    this.badge();
                  });
                }
              });
          }
        }
      }
    );
  }
}
