import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import {
  SolicitudRegistro,
  EstadoUsuario,
} from '../core/model/solicitudes.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../admin/dialog/dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SolicitudesService } from '../core/services/solicitudes.service';

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.css'],
})
export class SolicitudesComponent implements AfterViewInit, OnInit {
  @ViewChild(MatTable) table: MatTable<SolicitudRegistro>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  registros: SolicitudRegistro[];
  dataSource = new MatTableDataSource(this.registros);

  constructor(
    private solicitudService: SolicitudesService,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'nroDocumento',
    'nombre',
    'rol',
    'organismo',
    'email',
    'fechaSol',
    'actions',
  ];

  ngOnInit() {
    this.builData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource = this.dataSource;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  habilitar(item: SolicitudRegistro) {
    this.dialog
      .open(DialogComponent, {
        data:
          `¿Esta seguro de habilitar como  ` +
          item.rol.descripcion +
          ` a ${item.apellido + `, ` + item.nombre}  ?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          item.estado.id = EstadoUsuario.Habilitado;
          this.solicitudService.putSolicitud(item).subscribe((data) => {
            this.matSnackBar.open('Registro actualizado correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            });
            this.builData();
          });
        }
      });
  }

  bloquear(item) {
    this.dialog
      .open(DialogComponent, {
        data:
          `¿Esta seguro de bloquear el acceso como  ` +
          item.rol.descripcion +
          ` a ${item.apellido + `, ` + item.nombre}  ?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          item.estado.id = EstadoUsuario.Rechazado;
          this.solicitudService.putSolicitud(item).subscribe((data) => {
            this.matSnackBar.open('Registro actualizado correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            });
            this.builData();
          });
        }
      });
  }

  builData() {
    this.solicitudService
      .ListSolicitud()
      .subscribe((data: SolicitudRegistro[]) => {
        this.registros = data;
        this.dataSource = new MatTableDataSource(this.registros);
        console.log(data);
      });
  }
}
