<h2 mat-dialog-title>{{ data.accion.titulo }}</h2>
<mat-dialog-content class="mat-typography">
  <json-schema-form
    framework="material-design"
    [schema]="data.JsonSchema"
    loadExternalAssets="true"
    [layout]="data.JsonSchema.schemaForm"
    (onSubmit)="ejecutar()"
    [(ngModel)]="data.jsonModel"
  >
  </json-schema-form>
</mat-dialog-content>
