<form class="formulario">
  <mat-card>
    <mat-card-header>
      <mat-card-title> Reporte de Ingresos </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <br />

      <mat-form-field appearance="fill">
        <mat-label>Periodo Mes/Año</mat-label>
        <input matInput readonly [matDatepicker]="dp" [formControl]="periodo" />
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker
          #dp
          startView="multi-year"
          (monthSelected)="chosenMonthHandler($event, dp)"
          panelClass="periodo-month-picker"
        >
        </mat-datepicker>
      </mat-form-field>

      <button
        mat-raised-button
        class="margen"
        color="primary"
        (click)="buildData()"
      >
        Buscar <mat-icon>search</mat-icon>
      </button>

      <button
        *ngIf="this.sinDatos === 2"
        mat-raised-button
        class="margen"
        color="primary"
        (click)="printData()"
      >
        Descargar <mat-icon>file_download</mat-icon>
      </button>

      <!-- Listamos los datos de la tabla  -->

      <div *ngIf="isGrillaVisible">
        <mat-form-field appearance="standard">
          <mat-label>Buscar</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Buscar Abogado"
            #input
          />
        </mat-form-field>

        <table
          mat-table
          [dataSource]="dataSource"
          class="full-width-table"
          matSort
          aria-label="Elements"
        >
          <!-- cuenta Column -->
          <ng-container matColumnDef="tipoDocDesc">
            <th mat-header-cell *matHeaderCellDef>Tipo Documento</th>
            <td mat-cell *matCellDef="let element">
              <b>{{ element.tipoDocDesc }}</b>
            </td>
          </ng-container>

          <!-- cbu Column -->
          <ng-container matColumnDef="nroDoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Nro Documento
            </th>
            <td mat-cell *matCellDef="let element">{{ element.nroDoc }}</td>
          </ng-container>

          <!-- caratula Column -->
          <ng-container matColumnDef="apellidoYNombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <td mat-cell *matCellDef="let element">
              {{ element.apellidoYNombre }}
            </td>
          </ng-container>

          <!-- expediente Column -->
          <ng-container matColumnDef="matriculaNro">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Expediente
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.matriculaNro }}
            </td>
          </ng-container>

          <!-- saldoStr Column -->
          <ng-container matColumnDef="ingreso">
            <th mat-header-cell *matHeaderCellDef>Fecha Ingreso</th>
            <td mat-cell *matCellDef="let element">
              <b color="warn"> {{ element.ingreso }} </b>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            [ngClass]="{ warn: element.isRestringida }"
            *matRowDef="let element; columns: displayedColumns"
          ></tr>
        </table>
      </div>

      <div *ngIf="this.sinDatos === 1" style="color: red; text-align: center">
        <h4>No se encontraron datos...</h4>
      </div>
    </mat-card-content>
  </mat-card>
</form>
