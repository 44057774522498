import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  jsonModel: any;
  JsonSchema: any;
  accion: any;
}

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.css'],
})
export class CustomDialogComponent implements OnInit {
  resultado: Observable<any>;

  constructor(
    public dialogRef: MatDialogRef<CustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private http: HttpClient,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  ejecutar() {
    switch (this.data.accion.verbo) {
      case 'POST':
        this.resultado = this.http.post(
          `${environment.apiServiceBaseUri}/` + this.data.accion.url,
          this.data.jsonModel
        );
        break;
      case 'PUT':
        this.resultado = this.http.put(
          `${environment.apiServiceBaseUri}/` + this.data.accion.url,
          this.data.jsonModel
        );
        break;
      case 'GET':
        this.resultado = this.http.get(
          `${environment.apiServiceBaseUri}/` + this.data.accion.url
        );
        break;
      case 'DELETE':
        this.resultado = this.http.delete(
          `${environment.apiServiceBaseUri}/` + this.data.accion.url
        );
        break;
    }

    this.resultado.subscribe(
      (data) => {
        this.matSnackBar
          .open('Solicitud enviada correctamente', 'Ok', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          })
          .afterDismissed()
          .subscribe(() => {});

        this.dialogRef.close();
      },
      (err) => {
        console.log(err);
        this.matSnackBar
          .open(err.error.detalle, 'Ok', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          })
          .afterDismissed()
          .subscribe(() => {});
      }
    );
  }
}
