import { Component, OnInit } from '@angular/core';
import { CambioOrganismoService } from 'src/app/core/services/cambio-organismo.service';
import { TokenModel } from 'src/app/core/model/token.model';
import { Juzgado, Organismo } from 'src/app/core/model/organismo.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/core/services/account.service';
import { TokenService } from 'src/app/core/services/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Roles } from 'src/app/core/model/tipo-usuario.model';

@Component({
  selector: 'app-solicitud-cambio-organismo',
  templateUrl: './solicitud-cambio-organismo.component.html',
  styleUrls: ['./solicitud-cambio-organismo.component.css'],
})
export class SolicitudCambioOrganismoComponent implements OnInit {
  form: FormGroup;
  registroError: string;
  hide = true;
  organismo: Organismo[];
  juzgado: Juzgado[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private accountService: AccountService,
    private orgService: CambioOrganismoService,
    private tokenService: TokenService,
    private matSnackBar: MatSnackBar
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    const modelUser: TokenModel = this.tokenService.getToken();
    const user = {
      documento: modelUser.documento.descripcion + ' ' + modelUser.nroDocumento,
      organismo: modelUser.organismo.descripcion,
      juzgado: '',
    };

    if (modelUser.juzgadoDefault) {
      user.juzgado = modelUser.juzgadoDefault.descripcion;
    }

    this.form.patchValue(user);

    // buscamos los perfiles
    this.accountService.getOrganizmo().subscribe((data: Organismo[]) => {
      this.organismo = data;
    });
  }

  selectJuzgado(item) {
    this.juzgado = this.organismo.find((f) => f.id === item).juzgados;
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      /*Validamos que completo los datos de abogado o juzgado*/
      const solicitud = {
        idOrganismo: this.form.value.idOrganismo,
        juzgadoTitular: this.form.value.idJuzgado,
        estado: 1,
        detalle: this.form.value.observacion,
      };

      this.orgService.solicitarCambio(solicitud).subscribe(
        (newRegistro) => {
          console.log(newRegistro);
          this.matSnackBar
            .open('Solicitud enviada correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            })
            .afterDismissed()
            .subscribe(() => {
              this.router.navigate(['./organismo/mis-solicitudes']);
            });
        },
        (err) => {
          console.log(err);
          this.registroError = err.error.detalle;
        }
      );
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      documento: [''],
      organismo: [''],
      juzgado: [''],
      observacion: ['', [Validators.required]],
      idOrganismo: [null, [Validators.required]],
      idJuzgado: [null],
    });
  }
}
