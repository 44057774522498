<h1 class="mat-h1">Autorizar de OPJE (Juzgado {{ this.juzgadoDefault }})</h1>

<div class="fixed-opj" *ngIf="this.selection.selected.length > 0">
  <div class="progressAuto" *ngIf="this.isAutorizando">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="this.isItemsVisible">
    <div class="row">
      <div class="col-sm-6">
        <h2>Ordenes seleccionadas</h2>
      </div>
      <div class="col-sm-6" style="text-align: end">
        <button
          [disabled]="this.isAutorizando"
          mat-icon-button
          color="warn"
          (click)="this.ocultarPanelItems()"
          style="margin-right: 30px"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <!-- Le agregamos el detalle del error -->
    <mat-error *ngIf="this.registroError != ''">
      <h3>{{ this.registroError }}</h3>
    </mat-error>

    <div class="row">
      <div
        class="col-sm-3"
        *ngFor="let item of this.selection.selected"
        class="open-close-container"
      >
        <div class="main-box-concepto">
          <div class="box-type-concepto">
            <p class="saldo">Importe</p>
            <h3>
              {{ item.montoOPJ | currency }}
            </h3>
          </div>
          <!-- Icono para cerrar -->
          <p><b>Nro:</b> {{ item.idOpj }}</p>
          <h3
            [matTooltip]="item.caratula"
            matTooltipPosition="above"
            style="margin-top: -15px; font-size: 0.8rem"
          >
            <b>{{
              item.caratula.length > 18
                ? item.caratula.substring(0, 18) + "..."
                : item.caratula
            }}</b>
            <div *ngIf="item.process" style="margin-left: 60px">
              <p
                *ngIf="item.process.error"
                #tooltip
                matTooltipClass="tooltip-error"
                matTooltipShowDelay="2000"
                [matTooltip]="item.process.error"
                matTooltipPosition="above"
              >
                ***
                <mat-icon class="color_red" aria-hidden="false">{{
                  item.process.icon
                }}</mat-icon>
                <a
                  class="imprimirComprobante"
                  (click)="imprimirReport(item.process.accion)"
                  >Comprobante</a
                >
              </p>

              <p>
                <mat-icon
                  *ngIf="!item.process.error"
                  class="color_green"
                  aria-hidden="false"
                  >{{ item.process.icon }}
                </mat-icon>
                <a
                  *ngIf="!item.process.error && this.mostrarComprobante"
                  class="imprimirComprobante"
                  (click)="imprimirReport(item.process.accion)"
                  >Comprobante</a
                >
              </p>
            </div>
          </h3>
        </div>
      </div>
      <div class="col-sm-3" *ngIf="this.isBtnVisible">
        <h2>
          Importe Total: <b>{{ getImporteTotal | currency }}</b>
        </h2>
        <button
          mat-raised-button
          color="primary"
          class="margenBoton"
          (click)="autorizarOpjs()"
        >
          Autorizar
        </button>
        <button
          mat-raised-button
          color="warn"
          (click)="
            isRechazarOpj = true;
            this.isItemsVisible = false;
            this.isPanelActivo = true
          "
        >
          Rechazar
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="autorizarOpj" style="margin: 2rem">
    <form [formGroup]="formAutorizar" autocomplete="off">
      <h2>Para confirmar la operacion ingrese las siguientes coordenadas</h2>
      <mat-error *ngIf="isInvalidCoordenada">
        <h3>{{ isInvalidCoordenada }}</h3>
      </mat-error>
      <mat-form-field class="input-coord" appearance="fill">
        <mat-label>{{ this.coordenadas.coord1 }}</mat-label>
        <input
          matInput
          mask="00"
          class="ocultarCoordenadas"
          #inputA
          autocomplete="off"
          (ngModelChange)="nextItemCoord($event)"
          autofocus
          formControlName="coordA"
        />
      </mat-form-field>
      <mat-form-field class="input-coord" appearance="fill">
        <mat-label>{{ this.coordenadas.coord2 }}</mat-label>
        <input
          matInput
          mask="00"
          #inputB
          class="ocultarCoordenadas"
          autocomplete="off"
          (ngModelChange)="validarCoordenadas($event)"
          formControlName="coordB"
        />
      </mat-form-field>
      <div>
        <button
          mat-raised-button
          (click)="this.autorizarOpj = false; this.isItemsVisible = true"
        >
          Cancelar
        </button>
        <button
          mat-raised-button
          class="margenBoton"
          color="primary"
          [disabled]="this.formAutorizar.invalid"
          (click)="AutorizarItems($event)"
        >
          Autorizar
        </button>
        <!--<button mat-raised-button class="margenBoton" color="primary" (click)="AutorizarItems($event)">Autorizar</button>-->
      </div>
    </form>
  </div>

  <div *ngIf="isRechazarOpj">
    <form [formGroup]="formRechazar" autocomplete="off">
      <h2>Por favor ingrese una observacion del rechazo</h2>
      <mat-form-field>
        <mat-label>Motivo</mat-label>
        <textarea matInput formControlName="motivo"></textarea>
      </mat-form-field>
      <div>
        <button
          mat-raised-button
          (click)="this.isRechazarOpj = false; this.isItemsVisible = true"
        >
          Cancelar
        </button>
        <!-- <button mat-raised-button class="margenBoton" color="primary" [disabled]="this.formAutorizar.invalid" (click)="AutorizarItems($event)">Autorizar</button> -->
        <button
          mat-raised-button
          class="margenBoton"
          color="warn"
          (click)="RechazarItems($event)"
        >
          Rechazar
        </button>
      </div>
    </form>
  </div>
</div>

<mat-accordion class="filter-headers-align">
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> Filtro de Busqueda </mat-panel-title>
      <mat-panel-description>
        Buscar cuentas judiciales
        <mat-icon>filter_alt</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <form [formGroup]="formFiltro">
        <mat-radio-group
          formControlName="tipoAsociacion"
          class="radio-group"
          aria-label="Ingrese Cbu o Alias"
        >
          <mat-radio-button class="item-radio" value="opje"
            >Nro OPJE
          </mat-radio-button>
          <mat-radio-button value="cuenta" checked>Cuenta</mat-radio-button>
          <mat-radio-button class="item-radio" value="cbu"
            >CBU</mat-radio-button
          >
          <mat-radio-button class="item-radio" value="expediente"
            >Expendiente</mat-radio-button
          >
          <mat-radio-button class="item-radio" value="caratula"
            >Caratula</mat-radio-button
          >
        </mat-radio-group>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'cuenta'">
          <br />
          <div class="row">
            <div class="col-sm-2">
              <mat-form-field class="input-width-cuenta" appearance="fill">
                <mat-label>Cuenta</mat-label>
                <input matInput autofocus formControlName="cuenta" />
              </mat-form-field>
            </div>
            <div>
              <h3><b>/</b></h3>
            </div>
            <div class="col-sm-1">
              <mat-form-field class="input-width-cuenta" appearance="fill">
                <mat-label>Sub Cuenta</mat-label>
                <input matInput autofocus formControlName="subcuenta" />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'cbu'">
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>CBU</mat-label>
            <input matInput autofocus formControlName="cbu" />
          </mat-form-field>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'opje'">
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Nro OPJE</mat-label>
            <input matInput autofocus formControlName="opje" />
          </mat-form-field>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'expediente'">
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Expediente</mat-label>
            <input matInput autofocus formControlName="expediente" />
          </mat-form-field>
        </div>

        <div *ngIf="formFiltro.value.tipoAsociacion === 'caratula'">
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Caratula</mat-label>
            <input matInput autofocus formControlName="caratula" />
          </mat-form-field>
        </div>
        <mat-action-row>
          <button mat-raised-button color="primary" (click)="buildData()">
            Buscar <mat-icon>search</mat-icon>
          </button>
          <button mat-raised-button color="primary" (click)="limpiarBusqueda()">
            Limpiar <mat-icon>cleaning_services</mat-icon>
          </button>
        </mat-action-row>
      </form>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Filtrar Registros </mat-panel-title>
      <mat-panel-description>
        Filtra los datos de la grilla
        <mat-icon>list</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
    </mat-form-field>
  </mat-expansion-panel>
</mat-accordion>

<div>
  <table
    mat-table
    [dataSource]="dataSource"
    class="full-width-table"
    matSort
    aria-label="Elements"
  >
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>Seleccion</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          [disabled]="this.isPanelActivo"
          *ngIf="element.autorizable"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(element) : null"
          [checked]="selection.isSelected(element)"
          [aria-label]="checkboxLabel(element)"
        >
        </mat-checkbox>
        <mat-icon
          *ngIf="!element.autorizable"
          matTooltip="No disponible para autorizar"
          matTooltipPosition="above"
          style="color: red"
          >lock</mat-icon
        >
      </td>
    </ng-container>

    <!-- idOpj Column -->
    <ng-container matColumnDef="idOpj">
      <th mat-header-cell *matHeaderCellDef>#Nro Orden</th>
      <td mat-cell *matCellDef="let element">
        <b>{{ element.idOpj }}</b>
      </td>
    </ng-container>

    <!-- caratula Column -->
    <ng-container matColumnDef="caratula">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Caratula</th>
      <td
        mat-cell
        *matCellDef="let element"
        [matTooltip]="element.caratula"
        matTooltipPosition="above"
      >
        {{ element.caratula.substring(0, 5) }}...
      </td>
    </ng-container>

    <!-- expediente Column -->
    <ng-container matColumnDef="expediente">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Expediente</th>
      <td
        mat-cell
        *matCellDef="let element"
        [matTooltip]="element.expediente"
        matTooltipPosition="above"
      >
        {{ element.expediente }}
      </td>
    </ng-container>

    <!-- bene Column -->
    <ng-container matColumnDef="cuitBeneficiario">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CUIT/CUIL Benef</th>
      <td mat-cell *matCellDef="let element">{{ element.cuitBeneficiario }}</td>
    </ng-container>

    <!-- cbu dest Column -->
    <ng-container matColumnDef="cbuDestino">
      <th mat-header-cell *matHeaderCellDef>CBU Dest.</th>
      <td
        mat-cell
        *matCellDef="let element"
        [matTooltip]="element.cbuDestino"
        matTooltipPosition="above"
      >
        <b color="warn">
          ...{{ element.cbuDestino ? element.cbuDestino.substring(0, 5) : "" }}
        </b>
      </td>
    </ng-container>

    <!-- operador Column -->
    <ng-container matColumnDef="operador">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Operador</th>
      <td
        mat-cell
        *matCellDef="let element"
        [matTooltip]="
          element.operador.apellido + ', ' + element.operador.nombre
        "
        matTooltipPosition="above"
      >
        {{ element.operador.apellido.substring(0, 4) }}...
      </td>
    </ng-container>

    <!-- Monto Column -->
    <ng-container matColumnDef="montoOPJStr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Monto($)</th>
      <td mat-cell *matCellDef="let element">
        <b color="warn"> {{ element.montoOPJStr }} </b>
      </td>
    </ng-container>

    <!-- tipo Column -->
    <ng-container matColumnDef="tipo">
      <th mat-header-cell *matHeaderCellDef>Tipo</th>
      <td mat-cell *matCellDef="let element">{{ element.tipo }}</td>
    </ng-container>

    <!-- Fecha Alta Column -->
    <ng-container matColumnDef="fechaAlta">
      <th mat-header-cell *matHeaderCellDef>Fecha Alta</th>
      <td mat-cell *matCellDef="let element">
        {{ element.fechaAlta.substring(0, 10) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Acciones</th>
      <td mat-cell *matCellDef="let row">
        <button
          mat-icon-button
          color="primary"
          [matMenuTriggerFor]="menu"
          aria-label="Acciones"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            *ngFor="let item of row.acciones"
            mat-menu-item
            (click)="ejecutar(item)"
          >
            <mat-icon [ngClass]="item.cssColor">{{ item.icono }}</mat-icon>
            <span>{{ item.titulo }}</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
  </table>
</div>

<mat-paginator [length]="resultsLength" [pageSize]="25"></mat-paginator>
