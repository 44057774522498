import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TokenModel } from 'src/app/core/model/token.model';
import { Juzgado, Organismo } from 'src/app/core/model/organismo.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService } from 'src/app/core/services/account.service';
import { CambioOrganismoService } from 'src/app/core/services/cambio-organismo.service';
import { TokenService } from 'src/app/core/services/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Roles } from 'src/app/core/model/tipo-usuario.model';
import { SolicitudCambioOrganismo } from 'src/app/core/model/solicitud-cambio-organismo.model';

@Component({
  selector: 'app-editar-solicitud-organismo',
  templateUrl: './editar-solicitud-organismo.component.html',
  styleUrls: ['./editar-solicitud-organismo.component.css'],
})
export class EditarSolicitudOrganismoComponent implements OnInit {
  form: FormGroup;
  registroError: string;
  hide = true;
  organismo: Organismo[];
  juzgado: Juzgado[];
  id: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private accountService: AccountService,
    private orgService: CambioOrganismoService,
    private tokenService: TokenService,
    private matSnackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params.id;
      const modelUser: TokenModel = this.tokenService.getToken();
      // buscamos los perfiles
      this.accountService.getOrganizmo().subscribe((data: Organismo[]) => {
        this.organismo = data;
        this.orgService
          .getSolicitud(this.id)
          .subscribe((sol: SolicitudCambioOrganismo) => {
            const user = {
              documento:
                modelUser.documento.descripcion + ' ' + modelUser.nroDocumento,
              organismo: modelUser.organismo.descripcion,
              juzgado: '',
              idOrganismo: sol.organismo.id,
              juzgadoTitular: '',
              detalle: this.form.value.observacion,
            };

            if (modelUser.juzgadoDefault) {
              user.juzgado = modelUser.juzgadoDefault.descripcion;
            }

            if (sol.juzgadoTitular) {
              user.juzgadoTitular = sol.juzgadoTitular;
            }

            this.form.patchValue(user);
          });
      });
    });
  }

  selectJuzgado(item) {
    this.juzgado = this.organismo.find((f) => f.id === item).juzgados;
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      /*Validamos que completo los datos de abogado o juzgado*/
      const solicitud = {
        idOrganismo: this.form.value.idOrganismo,
        juzgadoTitular: this.form.value.idJuzgado,
        estado: 1,
        detalle: this.form.value.observacion,
      };

      this.orgService.editarSolicitud(this.id, solicitud).subscribe(
        (newRegistro) => {
          console.log(newRegistro);
          this.matSnackBar
            .open('Solicitud editada correctamente', 'Ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            })
            .afterDismissed()
            .subscribe(() => {
              this.router.navigate(['./organismo/mis-solicitudes']);
            });
        },
        (err) => {
          console.log(err);
          this.registroError = err.error.detalle;
        }
      );
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      documento: [''],
      organismo: [''],
      juzgado: [''],
      observacion: ['', [Validators.required]],
      idOrganismo: [null, [Validators.required]],
      idJuzgado: [null],
    });
  }
}
