import { Component, OnInit } from '@angular/core';
import { SolicitudCambioPerfil } from 'src/app/core/model/solicitud-perfil.model';
import { MatTableDataSource } from '@angular/material/table';
import { AccountService } from 'src/app/core/services/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AccionesService } from 'src/app/core/services/acciones.service';
import { TipoSchema } from 'src/app/core/model/schema-model';

@Component({
  selector: 'app-solicitud-pendientes',
  templateUrl: './solicitud-pendientes.component.html',
  styleUrls: ['./solicitud-pendientes.component.css'],
})
export class SolicitudPendientesComponent implements OnInit {
  registros: SolicitudCambioPerfil[];
  registroError: string;
  dataSource = new MatTableDataSource(this.registros);
  displayedColumns = [
    'documento',
    'nombre',
    'rolDefault',
    'perfil',
    'fechaSolicitud',
    'estado',
    'actions',
  ];

  constructor(
    private account: AccountService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private accionService: AccionesService
  ) {
    this.accionService.badgeResultado$.subscribe((data) => {
      this.buidGrid();
    });
  }

  ngOnInit(): void {
    this.buidGrid();
  }

  buidGrid() {
    this.account.MisPendientes().subscribe((reg) => {
      this.registros = reg;
      this.dataSource = new MatTableDataSource(this.registros);
    });
  }

  ejecutar(accion) {
    this.accionService.customAccion(accion);
  }
}
