export class Persona {
  tipoDocumentoBT: number;
  nroDocumento: string;
  apellido: string;
  nombre: string;
  fecNacimiento: Date;
  sexo: string;
  estadoCivil: string;
  paisNacimiento: number;
  email: string;
  telefono: string;
}

export class Domicilio {
  calle: string;
  nro: string;
  piso: string;
  dpto: string;
  barrio: string;
  idPais: number;
  idProvincia: number;
  idLocalidad: number;
  codPostal: string;
}

export class Beneficiario {
  cbu: string;
  persona: Persona;
  domicilio: Domicilio;
}

export class Pais {
  id: number;
  descripcion: string;
}

export class EstadoCivil {
  id: string;
  descripcion: string;
}

export class Provincias {
  id: number;
  descripcion: string;
}

export class Localidades {
  id: number;
  descripcion: string;
}
