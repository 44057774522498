import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { JuzgadoService } from 'src/app/core/services/juzgado.service';
import { UsuarioJuzgado } from 'src/app/core/model/usuario-juzgado.mode';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from 'src/app/admin/dialog/dialog.component';
import { Acciones } from 'src/app/core/model/cuenta.model';
import { Router } from '@angular/router';
import { AccionesService } from 'src/app/core/services/acciones.service';
import { PerfilesComponent } from '../perfiles/perfiles.component';

@Component({
  selector: 'app-operadores',
  templateUrl: './operadores.component.html',
  styleUrls: ['./operadores.component.css'],
})
export class OperadoresComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  registros: UsuarioJuzgado[];
  dataSource = new MatTableDataSource(this.registros);
  displayedColumns: string[] = [
    'dni',
    'nombre',
    'email',
    'juzgadoTitular',
    'rol',
    'estado',
    'actions',
  ];

  constructor(
    private juzgadoService: JuzgadoService,
    private router: Router,
    private accionService: AccionesService,
    private matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogPerfil: MatDialog
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this.table.dataSource = this.dataSource;
  }

  ngOnInit(): void {
    this.accionService.badgeResultado$.subscribe((data) => {
      this.buscarOperadores();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  buscarOperadores() {
    this.juzgadoService
      .ListarOperadores()
      .subscribe((data: UsuarioJuzgado[]) => {
        this.registros = data;
        this.dataSource = new MatTableDataSource(this.registros);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  ejecutar(accion: Acciones) {
    if (accion.schemaJson === 'custom') {
      // this.router.navigate([accion.url]);
      this.dialogPerfil
        .open(PerfilesComponent, {
          data: JSON.parse(accion.body),
        })
        .afterClosed()
        .subscribe((confirmado: boolean) => {});
    } else {
      this.accionService.customAccion(accion);
    }
  }
}
