import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Observable, timer, Subscription } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.css'],
})
export class SessionComponent implements OnInit, OnDestroy {
  count = 60;
  subscription: Subscription;
  timersession: Observable<number> = timer(0, 1000);

  constructor(
    private auth: AuthService,
    private router: Router,
    public dialogo: MatDialogRef<SessionComponent>,
    @Inject(MAT_DIALOG_DATA) public mensaje: string
  ) {}

  ngOnInit(): void {
    this.count = 60;
    this.subscription = this.timersession.subscribe(() => {
      this.session();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  session() {
    this.count -= 1;
    if (this.count < 1) {
      this.auth.logout();
      this.router.navigate(['/auth/session-expirada']);
      this.dialogo.close(false);
    }
  }

  cerrarSession() {
    this.auth.logout();
    this.router.navigate(['/auth/login']);
    this.dialogo.close(false);
  }

  renovarToken() {
    this.auth.refreshToken().subscribe(
      (sub) => {
        this.dialogo.close(true);
      },
      (err) => {
        this.dialogo.close(false);
      }
    );
  }
}
