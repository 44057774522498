import { Component, OnInit } from '@angular/core';
import { SolicitudCambioCuenta } from 'src/app/core/model/solicitud-cambio-cuenta.model';
import { MatTableDataSource } from '@angular/material/table';
import { AccountService } from 'src/app/core/services/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AccionesService } from 'src/app/core/services/acciones.service';
import { CambioCuentasService } from 'src/app/core/services/cambio-cuentas.service';

@Component({
  selector: 'app-pendiente-cambio-cuenta',
  templateUrl: './pendiente-cambio-cuenta.component.html',
  styleUrls: ['./pendiente-cambio-cuenta.component.css'],
})
export class PendienteCambioCuentaComponent implements OnInit {
  registros: SolicitudCambioCuenta[];
  registroError: string;
  dataSource = new MatTableDataSource(this.registros);
  displayedColumns = [
    'documento',
    'nombre',
    'rolDefault',
    'perfil',
    'fechaSolicitud',
    'estado',
    'actions',
  ];

  constructor(
    private cambioCuenta: CambioCuentasService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private accionService: AccionesService
  ) {
    this.accionService.badgeResultado$.subscribe((data) => {
      this.buidGrid();
    });
  }

  ngOnInit(): void {
    this.buidGrid();
  }

  buidGrid() {
    this.cambioCuenta.MisPendientes().subscribe((reg) => {
      this.registros = reg;
      this.dataSource = new MatTableDataSource(this.registros);
    });
  }

  ejecutar(accion) {
    this.accionService.customAccion(accion);
  }
}
