<div class="row">
  <div class="col-lg-6">
    <form [formGroup]="form" (ngSubmit)="saveCambioClave($event)">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <!-- Seguridad de la contraseña:                 -->
          </mat-card-title>
          <br />
          <p>
            La longitud de la contraseña debe ser de 8 caracteres, contener al
            menos un caracter especial y uno numérico
          </p>
        </mat-card-header>
        <mat-card-content style="text-align: center">
          <mat-error *ngIf="this.registroError != ''">
            <br />
            {{ this.registroError }}
          </mat-error>
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Nro Documento</mat-label>
            <input matInput readonly type="text" formControlName="dni" />
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Contraseña nueva</mat-label>
            <input
              matInput
              autofocus
              (ngModelChange)="resetError()"
              formControlName="claveJudicial"
              [type]="hide ? 'password' : 'text'"
            />
            <mat-error *ngIf="form.hasError('required', 'clave')">
              Por favor ingrese un nuevo password
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field class="input-width" appearance="fill">
            <mat-label>Confirma la contraseña nueva</mat-label>
            <input
              matInput
              formControlName="confirmarClave"
              (ngModelChange)="resetError()"
              [type]="hide ? 'password' : 'text'"
              [errorStateMatcher]="matcher"
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
              <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
            </button>
            <mat-error *ngIf="form.hasError('notSame', 'confirmarClave')">
              Por favor ingrese un nuevo password
            </mat-error>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions style="text-align: right">
          <button
            mat-stroked-button
            color="warn"
            type="button"
            [routerLink]="['/']"
          >
            Cancelar
          </button>
          <button
            [disabled]="form.invalid"
            mat-raised-button
            color="primary"
            type="submit"
          >
            Cambiar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</div>
