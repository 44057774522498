import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Documento } from 'src/app/core/model/solicitud-perfil.model';
import {
  Pais,
  Provincias,
  Localidades,
  Beneficiario,
  EstadoCivil,
} from 'src/app/core/model/beneficiario.model';
import { BeneficiarioService } from 'src/app/core/services/beneficiario.service';
import { CuentaJudicialService } from 'src/app/core/services/cuentaJudicial.service';
import { Sectores } from 'src/app/core/model/juzgado.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/custom-dialog/custom-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-alta-beneficiario',
  templateUrl: './alta-beneficiario.component.html',
  styleUrls: ['./alta-beneficiario.component.css'],
})
export class AltaBeneficiarioComponent implements OnInit {
  formPersona: FormGroup;
  formDomicilio: FormGroup;
  formContacto: FormGroup;
  listTipodocumento: Documento[];
  listPais: Pais[];
  listProvincias: Provincias[];
  listLocalidades: Localidades[];
  listEstadoCivil: EstadoCivil[];
  persParam: any;

  constructor(
    private formBuilder: FormBuilder,
    private benefService: BeneficiarioService,
    private cuentaService: CuentaJudicialService,
    private matSnackBar: MatSnackBar,
    public dialogo: MatDialogRef<AltaBeneficiarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.persParam = {
      tipoDocumentoBT: data.cuit,
      nroDocumento: data.beneficiario,
    };
    console.log(data);
  }

  ngOnInit(): void {
    this.buildForm();

    // buscamos los juzgados
    this.benefService.getPais().subscribe((data: Pais[]) => {
      this.listPais = data;
    });

    this.cuentaService.getTipoDocumento().subscribe((data: Sectores[]) => {
      this.listTipodocumento = data;
      this.formPersona.patchValue(this.persParam);
    });

    this.benefService.getEstadoCivil().subscribe((data: EstadoCivil[]) => {
      this.listEstadoCivil = data;
    });
  }

  /// Cambio en sector la descpcion del sector ejemplo Publico (leyenda aclaracion)
  /// Alta de beneficiario ver error popup
  /// sucursales no definido cambiar no definido

  buscarProvincias(idPais) {
    this.benefService.getProvincia(idPais).subscribe((data: Provincias[]) => {
      this.listProvincias = data;
    });
  }

  buscarLocalidad(idProvincia) {
    this.benefService
      .getLocalidades(idProvincia, this.formDomicilio.value.idPais)
      .subscribe((data: Localidades[]) => {
        this.listLocalidades = data;
      });
  }

  buscarCodigoPostal(idLocalidad) {
    this.benefService
      .getCodigoPostal(
        this.formDomicilio.value.idPais,
        this.formDomicilio.value.idProvincia,
        idLocalidad
      )
      .subscribe(
        (data) => {
          console.log(data);
          const cp = {
            codPostal: data.descripcion,
          };
          this.formDomicilio.patchValue(cp);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  buscarBeneficiario(item) {
    if (item) {
      if (item.length === 11) {
        this.benefService.buscarCuitAfip(item).subscribe((data) => {
          const benef = {
            apellido: data.datosGenerales.apellido,
            nombre: data.datosGenerales.nombre,
          };

          this.formPersona.patchValue(benef);

          if (data.datosGenerales.domicilio) {
            const regex = /(\d+)/g;
            const domicilio = data.datosGenerales.domicilio.direccion;
            if (domicilio) {
              const Dom = {
                calle: domicilio.replace(domicilio.match(regex)[0], ''),
                nro: data.datosGenerales.domicilio.direccion.match(regex)[0],
                codPostal: data.datosGenerales.domicilio.codigoPostal,
              };
              this.formDomicilio.patchValue(Dom);
            }
          }
        });
      }
    }
  }

  saveRegister(event: Event) {
    event.preventDefault();
    if (this.formPersona.valid) {
      const modelo = new Beneficiario();
      modelo.persona = this.formPersona.value;
      modelo.persona.email = this.formContacto.value.email;
      modelo.persona.telefono = this.formContacto.value.telefono;
      modelo.domicilio = this.formDomicilio.value;
      console.log(modelo);
      this.dialogo.close(modelo);

      /* this.benefService.crearBeneficiario(modelo)
      .subscribe((data) => {
        this.matSnackBar.open('Beneficiario creado correctamente',
        'Ok',
          {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          }).afterDismissed().subscribe(() => {
             this.dialogo.close(data);
          });

      });*/
    }
  }

  private buildForm() {
    this.formPersona = this.formBuilder.group({
      tipoDocumentoBT: [2, [Validators.required]],
      nroDocumento: ['', [Validators.required]],
      apellido: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      fecNacimiento: [null, [Validators.required]],
      sexo: ['M', [Validators.required]],
      estadoCivil: ['', [Validators.required]],
      paisNacimiento: ['', [Validators.required]],
    });

    this.formContacto = this.formBuilder.group({
      email: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
    });

    this.formDomicilio = this.formBuilder.group({
      calle: ['', [Validators.required]],
      nro: ['', [Validators.required]],
      piso: [''],
      dpto: [''],
      barrio: [''],
      idPais: [null, [Validators.required]],
      idProvincia: [null, [Validators.required]],
      idLocalidad: [null, [Validators.required]],
      codPostal: ['', [Validators.required]],
    });
  }
}
